import SimpleSchema from "./simple";

const schema = {
  admissionDate: {
    type: Date,
    label: "Erhebungsdatum",
    defaultValue: function () {
      return new Date();
    },
    input: "DatePicker",
    group: "Allgemeine Erhebungsdaten",
  },
  firstName: {
    type: String,
    label: "Vorname",
    max: 150,
    group: "Allgemeine Erhebungsdaten",
  },
  lastName: {
    type: String,
    label: "Nachname",
    max: 150,
    group: "Allgemeine Erhebungsdaten",
  },
  birthday: {
    type: Date,
    label: "Geburtsdatum",
    defaultValue: function () {
      return new Date();
    },
    input: "DatePicker",
    group: "Allgemeine Erhebungsdaten",
  },
  address: {
    type: String,
    label: "Adresse",
    max: 500,
    group: "Allgemeine Erhebungsdaten",
  },
  zipcode: {
    type: String,
    label: "PLZ",
    max: 150,
    group: "Allgemeine Erhebungsdaten",
  },
  residence: {
    type: String,
    label: "Ort",
    max: 150,
    group: "Allgemeine Erhebungsdaten",
  },
  phone: {
    type: String,
    label: "Telefon",
    max: 150,
    optional: true,
    group: "Allgemeine Erhebungsdaten",
  },
  email: {
    type: String,
    label: "E-Mail",
    max: 150,
    optional: true,
    group: "Allgemeine Erhebungsdaten",
  },
  insurer: {
    type: String,
    label: "Versicherer",
    max: 150,
    optional: true,
    group: "Allgemeine Erhebungsdaten",
  },
  insuranceNumber: {
    type: String,
    label: "Versicherungsnummer",
    max: 100,
    optional: true,
    group: "Allgemeine Erhebungsdaten",
  },
  insuranceState: {
    type: String,
    label: "Versicherungsart",
    optional: true,
    options: [
      {
        label: "Pflichtversichert",
        value: "compulsorilyInsured",
      },
      {
        label: "Privatversichert",
        value: "privateInsured",
      },
    ],
    input: "Select",
    group: "Allgemeine Erhebungsdaten",
  },
  AHVNumber: {
    type: String,
    label: "AHV Nummer",
    max: 100,
    optional: true,
    group: "Allgemeine Erhebungsdaten",
  },
  oldData: {
    type: Boolean,
    label: "Alte Daten vorhanden",
    defaultValue: function () {
      return false;
    },
    optional: true,
    input: "Toggle",
    group: "Allgemeine Erhebungsdaten",
  },
  prescriptionCharge: {
    type: Boolean,
    label: "Rezeptgebührenbefreit",
    optional: true,
    input: "Toggle",
    group: "Allgemeine Erhebungsdaten",
  },
  archived: {
    type: Boolean,
    label: "Archiviert",
    defaultValue: function () {
      return false;
    },
    input: "Toggle",
    group: "Allgemeine Erhebungsdaten",
  },
  contactIDs: {
    type: Array,
    label: "Kontakte",
    input: "MaterialList",
    optional: true,
    group: "Kontakte",
    defaultValue: function () {
      return [];
    },
    inputOptions: {
      hidelabel: true,
    },
    options: { allowSearch: true, allowCreate: true, allowEdit: false },
  },
  "contactIDs.$": {
    type: String,
    listed: false,
  },
  sharedCompanyIds: {
    type: [String],
    label: "sharedCompanyIds",
    optional: true,
    listed: false,
  },
  archivedReason: {
    type: String,
    label: "Grund für Archivierung",
    options: [
      {
        label: "Verstorben",
        value: "deceased",
      },
      {
        label: "Geheilt",
        value: "cured",
      },
      {
        label: "Entlassen",
        value: "released",
      },
    ],
    optional: true,
    input: "Select",
    group: "Allgemeine Erhebungsdaten",
  },
  note: {
    type: String,
    label: "Bemerkung",
    max: 1000,
    optional: true,
    input: "TextArea",
    group: "Allgemeine Erhebungsdaten",
  },
  informedConsentImage: {
    type: String,
    label: "Unterschrift",
    group: "Einverständnisserklärung",
    listed: true,
    input: "Signature",
    optional: true,
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  sharedCompanyIds: {
    type: Array,
    label: "companyIds",
    optional: true,
    listed: false,
  },
  "sharedCompanyIds.$": {
    type: String,
    listed: false,
  },
  myPatient: {
    type: Array,
    label: "myPatientsIds",
    optional: true,
    listed: false,
    autoValue: function () {
      if (this.isInsert && this.userId) {
        return [this.userId];
      } else if (this.isUpsert && this.userId) {
        return [this.userId];
      }
    },
  },
  "myPatient.$": {
    type: String,
    listed: false,
  },
  sharedCompanys: {
    type: Array,
    label: "sharedCompanysId",
    optional: true,
    listed: false,
  },
  "sharedCompanys.$": {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Patients = new Mongo.Collection("patients");
  Patients.schema = simpleSchema;
  Patients.attachSchema(simpleSchema);
  Patients._ensureIndex({ companyId: 1 });
}

export { simpleSchema as PatientSchema };

export default schema;
