import React from "react";
import { Flex, Text, Progress } from "unikit";

export default ({ error }) => {
  return (
    <Flex flex={1} flexCenter>
      <Progress size={30} trackWidth={5} progressWidth={3} loading />
      {error ? (
        <Text
          level={5}
          style={{ textAlign: "center", maxWidth: 500, marginTop: 30 }}
        >
          Es ist ein Fehler aufgetreten, bitte versuchen Sie es in Kürze erneut.
        </Text>
      ) : null}
    </Flex>
  );
};
