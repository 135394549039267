import * as React from "react";
import { isWeb, useTheme } from "unikit";

import { Page, Form } from "../../components";
import { useAppContext } from "../../AppContext";

export default function Screen({ route, navigation }) {
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const [doc, setDoc] = React.useState({});
  const { user, refetchUser, changeDoc } = useAppContext();

  const getWarehouses = (user, filter) => {
    const array = [];
    array.push({
      label: `Zentrallager`,
      value: "warehouse",
    });
    array.push({
      label: `${user.lastName}, ${user.firstName}`,
      value: user._id,
    });
    if (doc?.type === "warehouse_in") {
      return [
        {
          label: `Zentrallager`,
          value: "warehouse",
        },
      ];
    }
    if (user.team) {
      user.team.map((member) => {
        if (member.banned !== true) {
          array.push({
            label: `${member.lastName}, ${member.firstName}`,
            value: member._id,
          });
        }
      });
    }
    if (doc && doc.warehouseFromId && filter) {
      return array.filter((a) => a.value !== doc.warehouseFromId);
    } else {
      return array;
    }
  };

  const getCountByWarehouse = ({ warehouseId, item, seperator = "" }) => {
    if (warehouseId === "warehouse") {
      return `Bestand Zentrallager: ${item.inStock || 0}${seperator}`;
    } else {
      const memberWarehouse = [user, ...(user.team || [])].find(
        (t) => t._id === warehouseId
      );
      if (!memberWarehouse) return "";
      return `Bestand ${memberWarehouse.lastName}, ${
        memberWarehouse.firstName
      }: ${memberWarehouse?.warehouseMaterials?.[item._id] || 0}${seperator}`;
    }
  };

  const orderId = route.params?.orderId;

  return (
    <Page title="Warenausgang/umbuchung" scrollable={true}>
      <Form
        loading={loading}
        navigation={navigation}
        buttonText="Buchen"
        defaultDoc={route.params?.defaultDoc}
        onChange={(doc) => {
          setDoc({ ...doc });
        }}
        onSave={(doc, setDoc) => {
          setLoading(true);
          if (!doc.materials || doc.materials.length === 0) {
            theme.alert({ type: "error", message: "error.select_materials" });
          } else {
            if (doc.type === "warehouse_in") {
              delete doc["warehouseFromId"];
            }
            if (doc.type === "warehouse_out") {
              delete doc["warehouseToId"];
            }
            changeDoc({
              doc: { ...doc, orderId },
              query: ["orders"],
              type: "Action",
              mode: "insert",
              onSuccess: () => {
                if (route.params && route.params.onSuccess) {
                  route.params.onSuccess(navigation);
                  setLoading(false);
                } else {
                  navigation.goBack();
                  setLoading(false);
                }
                if (refetchUser) refetchUser();
              },
            });
          }
        }}
        schema={[
          ...(isWeb || orderId
            ? []
            : [
                {
                  key: "code",
                  label: "Barcode scannen um Material hinzuzufügen",
                  input: "BarCode",
                  group: "Allgemein",
                  optional: true,
                },
              ]),
          {
            key: "type",
            label: "Type",
            placeholder: "Type",
            input: "Select",
            options: orderId
              ? [{ label: "Eingang", value: "warehouse_in" }]
              : [
                  { label: "Eingang", value: "warehouse_in" },
                  { label: "Entnahme", value: "warehouse_out" },
                  { label: "Umbuchen", value: "warehouse_change" },
                ],
            defaultValue: orderId ? "warehouse_in" : "warehouse_out",
            group: "Allgemein",
          },
          ...(orderId || doc.type === "warehouse_in"
            ? []
            : [
                {
                  key: "warehouseFromId",
                  label: "Von",
                  group: "Allgemein",
                  input: "Select",
                  defaultValue: "warehouse",
                  options: getWarehouses(user),
                  inputOptions: {
                    needsDoc: true,
                  },
                },
              ]),
          ...(doc.type === "warehouse_out"
            ? []
            : [
                {
                  key: "warehouseToId",
                  label: "Nach",
                  group: "Allgemein",
                  input: "Select",
                  options: getWarehouses(user, true),
                  defaultValue:
                    orderId || doc.type === "warehouse_in"
                      ? "warehouse_in"
                      : "warehouse_out",
                  inputOptions: {
                    needsDoc: true,
                  },
                },
              ]),
          {
            key: "materials",
            input: "DocListWithCount",
            label: "Materialien",
            group: "Allgemein",
            defaultValue: [],
            inputOptions: {
              desc: (item) => {
                return `Größe: ${item.size}, Verpackungseinheit: ${item.packagingUnit}`;
              },
              subdesc: (item) => {
                return `${getCountByWarehouse({
                  warehouseId: doc.warehouseFromId,
                  item,
                  seperator: " - ",
                })}${getCountByWarehouse({
                  warehouseId: doc.warehouseToId,
                  item,
                })}`;
              },
            },
          },
        ]}
      />
    </Page>
  );
}
