import * as React from "react";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { Button, Avatar } from "unikit";

import { ListViewQuery, Icon } from "../../components";
import { useNavigation } from "../../AppContext";

const EVIDENCE_QUERY = gql`
  query evidences($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    evidences(filter: $filter, sort: $sort, page: $page, limit: $limit) {
      _id
      surveyDate
      bandagedChange
      bandagedDuration
      medicalRound
      woundImage
      therapyChange
      inPatient
      recipe
      cashSale
      laser
      note
      patientId
      updatedAt
      patient {
        firstName
        lastName
      }
      user {
        _id
        firstName
        lastName
        userPictureFileId
        color
        image {
          _id
          url
          name
        }
      }
    }
  }
`;

export default function Screen({ filter }) {
  const { navigate } = useNavigation();
  return (
    <ListViewQuery
      query={EVIDENCE_QUERY}
      filter={filter}
      sort={{ surveyDate: -1 }}
      name="evidences"
      groupBy="surveyDate"
      groupByFormat={({ key, value }) => {
        return dayjs(value).format("MMMM YYYY");
      }}
      table
      sectionAction={(section) => (
        <Button
          onPress={() =>
            navigate("PDF", {
              type: "Evidence",
              id: section.data[0]._id,
            })
          }
          size={30}
        >
          <Icon name="printer" color="#FFF" size={18} />
        </Button>
      )}
      tableSchema={[
        {
          label: "Von",
          key: "user",
          getValue: (item) => {
            var user = item.user;
            if (user) {
              return (
                <Avatar
                  size={38}
                  fontSize={14}
                  borderColor="#FFF"
                  bg={user.color}
                  source={user.image ? { uri: user.image.url } : undefined}
                  char={`${user.firstName ? user.firstName.charAt(0) : ""}${
                    user.lastName ? user.lastName.charAt(0) : ""
                  }`}
                />
              );
            }
            return null;
          },
          width: 60,
        },
        {
          label: "Erhebungsdatum",
          key: "surveyDate",
          getValue: (item) => {
            return dayjs(item.surveyDate).format("DD.MM.YYYY");
          },
          width: 150,
        },
        {
          label: "Patient",
          key: "patient",
          getValue: (item) => {
            return item.patient
              ? `${item.patient.lastName}, ${item.patient.firstName}`
              : "-";
          },
          width: 220,
        },
        {
          label: "Verbandswechsel",
          key: "bandagedChange",
          defaultValue: false,
          width: 150,
        },
        {
          label: "dauer in min.",
          key: "bandagedDuration",
          defaultValue: 0,
          width: 150,
        },
        {
          label: "Therapieänderung",
          key: "medicalRound",
          defaultValue: false,
          width: 150,
        },
        {
          label: "Wundbild",
          key: "woundImage",
          defaultValue: false,
          width: 150,
        },
        {
          label: "Therapieänderung",
          key: "therapyChange",
          defaultValue: false,
          width: 150,
        },
        {
          label: "Stationär",
          key: "inPatient",
          defaultValue: false,
          width: 150,
        },
        {
          label: "Rezept",
          key: "recipe",
          defaultValue: false,
          width: 150,
        },
        {
          label: "Barverkauf",
          key: "cashSale",
          defaultValue: false,
          width: 150,
        },
        {
          label: "Lasertherapie",
          key: "laser",
          defaultValue: false,
          width: 150,
        },
      ]}
    />
  );
}
