import * as React from "react";
import { Button } from "unikit";
import dayjs from "dayjs";

import { Page, ListItem, ListViewQuery, Icon } from "../../components";
import { useAppContext } from "../../AppContext";
import { ORDERS_QUERY } from "../../api/queries";

export default function Screen({ navigation }) {
  const { call, changeDoc } = useAppContext();

  const onChangeDoc = ({ doc }) => {
    console.log({ doc });
    if (doc.contactId && doc.materials && doc.materials.length > 0) {
      return {
        ...doc,
        materials: doc.materials.filter((m) => m.contactId === doc.contactId),
      };
    } else {
      return doc;
    }
  };

  const renderItem = ({ item }) => {
    const totalCount = item.materials.reduce((acc, material) => {
      return acc + parseInt(material.count);
    }, 0);

    return (
      <ListItem
        title={item.name}
        desc={`Bei ${item.contact?.name || ""} vom ${dayjs(
          item.surveyDate
        ).format("DD. MMMM YYYY")}`}
        icon="shoppingCart"
        iconColor={item.booked ? "success" : "primary"}
        iconPill={`${item.bookedCount || 0}/${totalCount}`}
        pills={[
          ...(item.canceled ? [{ color: "error", label: "cancled" }] : []),
        ]}
        onPress={() => {
          navigation.push("Form", {
            title: item.name,
            type: "Order",
            mode: "update",
            id: item._id,
            data: item,
            onChangeDoc,
            query: {
              query: ORDERS_QUERY,
              variables: {
                filter: { send: false },
                sort: { surveyDate: -1 },
              },
            },
          });
        }}
        card
        actions={[
          ...(item.send && !item.booked
            ? [
                {
                  icon: "refreshCCW",
                  label: item.bookedCount > 0 ? "Rest buchen" : "Buchen",
                  onPress: () => {
                    navigation.navigate("Change", {
                      title: "Wareneingang",
                      orderId: item._id,
                      defaultDoc: {
                        materials: item.materials.reduce((acc, m) => {
                          const count = m.count - (m.bookedCount || 0);
                          if (count > 0) {
                            acc.push({
                              ...m,
                              count,
                            });
                          }
                          return acc;
                        }, []),
                        warehouseToId: "warehouse",
                      },
                    });
                  },
                },
              ]
            : []),
          {
            icon: "fileText",
            label: "PDF erstellen",
            onPress: () => {
              navigation.navigate("PDF", {
                type: item.__typename,
                id: item._id,
                options: undefined,
              });
            },
          },
          {
            icon: "send",
            label: `Bestellung ${item.send ? "erneut " : ""}per E-Mail senden`,
            onPress: () => {
              call({
                name: "sendOrder",
                successMessage: "Bestellung gesendet",
                refetchQueries: ["orders"],
                config: {
                  orderId: item._id,
                },
              });
            },
          },
          {
            icon: "x",
            label: `Bestellung stonieren`,
            onPress: () => {
              changeDoc({
                id: item._id,
                doc: {
                  canceled: true,
                },
                type: "Order",
                mode: "update",
              });
            },
          },
        ]}
      />
    );
  };
  return (
    <Page
      title="Wareneingang/Bestellmanagement"
      headerRight={() => (
        <Icon
          name="search"
          color="#FFF"
          onPress={() =>
            navigation.navigate("Search", {
              type: "orders",
            })
          }
        />
      )}
      scrollView
    >
      <Button
        onPress={() => {
          navigation.navigate("Change", {
            title: "Wareneingang",
            defaultDoc: {
              type: "warehouse_in",
              warehouseToId: "warehouse",
            },
          });
        }}
        light
        mx={10}
        mt={15}
      >
        Wareneingang ohne Bestellung buchen
      </Button>
      <ListViewQuery
        typename="Order"
        query={ORDERS_QUERY}
        title="Wareneingang aus Bestellung"
        sort={{ surveyDate: -1 }}
        filter={{ send: true, booked: { $ne: true }, canceled: { $ne: true } }}
        limit={20}
        name="orders"
        horizontal
        defaultSections={["In Behandlung", "Geheilt"]}
        renderItem={renderItem}
      />
      <ListViewQuery
        typename="Order"
        query={ORDERS_QUERY}
        title="Lieferschein/Rechnung fehlt oder unvollständig"
        sort={{ surveyDate: -1 }}
        limit={20}
        filter={{
          send: true,
          canceled: { $ne: true },
          $or: [
            { incomplete: true },
            {
              deliveryNoteImageIds: null,
              booked: true,
              canceled: { $ne: true },
            },
            { deliveryNoteImageIds: [], booked: true },
            { invoiceImageIds: null, booked: true },
            { invoiceImageIds: [], booked: true },
          ],
        }}
        name="orders"
        horizontal
        defaultSections={["In Behandlung", "Geheilt"]}
        renderItem={renderItem}
      />
      <ListViewQuery
        typename="Order"
        query={ORDERS_QUERY}
        title="Abgeschlossene Bestellungen"
        sort={{ surveyDate: -1 }}
        limit={20}
        filter={{
          $or: [
            {
              send: true,
              deliveryNoteImageIds: { $exists: true, $ne: [] },
              invoiceImageIds: { $exists: true, $ne: [] },
              incomplete: { $ne: true },
              booked: true,
            },
            { canceled: true },
          ],
        }}
        name="orders"
        horizontal
        defaultSections={["In Behandlung", "Geheilt"]}
        renderItem={renderItem}
      />
    </Page>
  );
}
