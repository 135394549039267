import gql from "graphql-tag";
import { useMutation } from "react-apollo";

export { useMutation };

export const RESET_PASSWORD = gql`
  mutation resetPassword($token: String, $password: String!, $_id: String) {
    resetPassword(token: $token, password: $password, _id: $_id)
  }
`;

export const CALL_METEOR = gql`
  mutation callMeteor($name: String!, $config: Object!) {
    callMeteor(name: $name, config: $config)
  }
`;
