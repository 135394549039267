import * as React from "react";
import { Platform } from "react-native";
import gql from "graphql-tag";
import { CSVLink } from "react-csv";
import { Tabs, Flex, Form, Input, Text } from "unikit";

import { Page, ListViewQuery, ListItem, Icon, Search } from "../../components";
import getSchemaByType from "../../clouddoku/lib/schemas";
import { useAppContext } from "../../AppContext";

const QUERY = gql`
  query users($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    users(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "users", filter: ["filter"]) {
      ${getSchemaByType("User").typeDef}
      team(filter: "employee") {
        _id
        emails
        banned
        roles
      }
    }
  }
`;

export default function Screen({ navigation: { push, navigate } }) {
  const { user, call } = useAppContext();
  const [type, setType] = React.useState("client");
  const [showBanned, setShowBanned] = React.useState(false);
  const [searchData, setSearchData] = React.useState(null);
  const [data, setData] = React.useState([]);
  console.log({ type });
  return (
    <Page
      title="Admin"
      headerRight={() => (
        <Icon
          name="plus"
          color="#FFF"
          onPress={() =>
            navigate("Form", {
              title: "Neuer Kunde",
              type: "User",
              mode: "insertClient",
              defaultValues: {},
              roles: ["admin"],
              refetch: {
                query: QUERY,
                variables: {
                  filter: {
                    roles: {
                      $in: ["client"],
                    },
                  },
                  sort: { emails: 1 },
                },
              },
            })
          }
        />
      )}
    >
      <Tabs
        options={[
          { value: "client", label: "Kunden" },
          { value: "employee", label: "Mitarbeiter" },
          { value: "reader", label: "Beobachter" },
          { value: "settings", label: "Settings" },
        ]}
        onChange={(value) => setType(value)}
      />

      {type === "settings" ? null : (
        <>
          <Flex w="100%" row relative>
            <Search
              query={QUERY}
              searchKey={"emails.address"}
              onChange={({ data }) => setSearchData(data["users"])}
              placeholder="Suche"
              filter={{
                roles: {
                  $in: [type],
                },
              }}
            />
            <Tabs
              value={showBanned === true ? "Gesperrt" : "Aktiv"}
              options={["Aktiv", "Gesperrt"]}
              onChange={(v) => setShowBanned(v === "Aktiv" ? false : true)}
              absolute
              bg="transparent"
              inactiveColor="text"
              activeColor="text"
              t={5}
              r={10}
              size={50}
              width={200}
            />
          </Flex>
          {Platform.OS === "web" ? (
            <CSVLink
              data={data.map((client) => ({
                email: client.emails[0]?.address,
                firma: client.company,
                team: client.team ? client.team.length : 0,
              }))}
            >
              CSV erstellen
            </CSVLink>
          ) : null}
        </>
      )}

      {type === "settings" ? (
        <Flex p={10}>
          <Text level={4}>Globaler Info-Banner</Text>
          <Form
            onSubmit={(doc, reset) => {
              call({
                name: "setGlobalInfo",
                config: {
                  ...doc,
                },
              });
            }}
            defaultDoc={{ globalInfo: user.globalInfo || "" }}
            buttonProps={{ mt: 10 }}
            buttonLabel="Speichern"
          >
            <Input
              type="text"
              label="Nachricht"
              placeholder="Info-Banner Inhalt"
              field="globalInfo"
              clean
            />
          </Form>
          <Text level={4}>Nachricht Senden</Text>
          <Form
            onSubmit={(doc, reset) => {
              call({
                name: "sendToAll",
                config: {
                  ...doc,
                  customFilter:
                    doc.customFilter !== "all"
                      ? { roles: { $all: [doc.customFilter] } }
                      : {},
                },
              });
            }}
            buttonProps={{ mt: 10 }}
            buttonLabel="Senden"
          >
            <Input
              type="select"
              label="An"
              field="customFilter"
              defaultValue={"all"}
              options={[
                { value: "all", label: "Alle" },
                { value: "client", label: "Kunden" },
                {
                  value: "employee",
                  label: "Mitarbeiter",
                },
                {
                  value: "reader",
                  label: "Beobachter",
                },
              ]}
              clean
            />
            <Input
              type="text"
              label="Titel"
              placeholder="Titel der Push/Email"
              field="title"
              clean
            />
            <Input
              type="textarea"
              my={10}
              label="Nachricht"
              placeholder="Nachricht der Push/Email"
              field="message"
              clean
            />
          </Form>
        </Flex>
      ) : (
        <ListViewQuery
          query={QUERY}
          searchData={searchData}
          onLoad={({ name, data }) => {
            setData(data);
          }}
          subFilter={{
            roles: {
              $in: ["employee"],
            },
          }}
          filter={{
            banned: showBanned ? true : false,
            roles: {
              $in: [type],
            },
          }}
          sort={{ emails: 1 }}
          name="users"
          groupBy="emails.0.address"
          groupByFormat={({ key, value }) =>
            value ? value.toUpperCase().charAt(0) : "..."
          }
          renderItem={({ item, index }) => (
            <ListItem
              title={`${item.emails[0]?.address} - ${item.company}`}
              desc={`${item.lastName}, ${item.firstName}, ${
                type === "client"
                  ? `Mitarbeiter: ${
                      item.team
                        ? item.team.filter((t) => t.banned !== true).length
                        : 0
                    }/${item.employeeCount}`
                  : null
              }`}
              onPress={() => {
                if (type === "client") {
                  navigate("Client", {
                    title: item.company,
                    user: item,
                  });
                } else if (type === "employee") {
                  navigate("Form", {
                    title: "Mitarbeiter bearbeiten",
                    data: item,
                    id: item._id,
                    type: "User",
                    mode: "updateEmployee",
                    roles: ["employee"],
                  });
                } else if (type === "reader") {
                  navigate("Form", {
                    title: "Reader bearbeiten",
                    data: item,
                    id: item._id,
                    type: "User",
                    mode: "updateReader",
                    roles: ["reader"],
                  });
                }

                // navigate("Form", {
                //   title: "Kunden bearbeiten",
                //   data: Object.assign({}, item, item.profile),
                //   id: item._id,
                //   type: "User",
                //   mode: "update",
                //   roles: ["admin"]
                // });
              }}
              pills={[
                ...(item.banned === true
                  ? [{ color: "error", label: "gesperrt" }]
                  : []),
              ]}
              actions={[
                {
                  icon: "edit",
                  label: "Passwort zurücksetzen",
                  onPress: () => {
                    call({
                      name: "enrollAccount",
                      config: {
                        mail: item.emails[0]?.address,
                        title: "Passwort zurücksetzen",
                      },
                    });
                  },
                },
                {
                  icon: "edit",
                  label: "Passwort manuell setzen",
                  onPress: () => {
                    push("Reset", {
                      _id: item._id,
                      backOnSuccess: true,
                    });
                  },
                },
              ]}
            />
          )}
        />
      )}
    </Page>
  );
}
