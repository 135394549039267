import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";

import { Page, PdfReader } from "../components";

const PDF_QUERY = gql`
  query getPdf($type: String!, $id: String!, $options: String) {
    getPdf(type: $type, id: $id, options: $options)
  }
`;

export default function Screen({ route, navigation }) {
  const { type, id, options, url } = route.params;
  if (url) {
    return (
      <Page title="PDF">
        <PdfReader
          source={{
            uri: url,
          }}
        />
      </Page>
    );
  }
  const { loading, error, data } = useQuery(PDF_QUERY, {
    variables: {
      type,
      id,
      options,
    },
  });
  return (
    <Page title="PDF" loading={loading}>
      <PdfReader
        source={{
          uri: data?.getPdf,
        }}
      />
    </Page>
  );
}

Screen.navigationOptions = ({ navigation, screenProps }) => ({
  title: "PDF",
});
