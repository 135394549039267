import React, { useState, Fragment, useEffect } from "react";
import { Alert, Platform } from "react-native";
import { Button, Group, styled } from "unikit";
import Prompt from "react-native-prompt-crossplatform";

import Listitem from "../ListItem";
import ListViewQuery from "../ListViewQuery";
import { MATERIALS_QUERY, CONTACTS_QUERY } from "../../api/queries";
import i18n from "../../I18n";

const QUERIES = {
  Contact: CONTACTS_QUERY,
  Material: MATERIALS_QUERY,
};

const Wrapper = styled.View({});

export default ({
  value = [],
  inputKey,
  placeholder,
  onChange,
  navigation,
  doc,
  filter,
  title = () => "title",
  desc = () => "desc",
  type,
  multiple = true,
  actions,
  withCount = false,
  allowSearch,
  allowCreate,
  allowEdit,
  allowRemove,
}) => {
  if (typeof value === "string" && !multiple) value = [value];
  console.log({ value });
  const [list, setList] = useState(value || []);
  const [adprompt, showPrompt] = useState(false);
  const [promptValue, setPromptValue] = useState(undefined);
  const [item, setItem] = useState(undefined);

  useEffect(() => {
    // let newValue = value;
    // if (typeof newValue === "string" && !multiple) newValue = [newValue];
    // setList(newValue);
  }, [value]);

  const setValueWithCount = (count, item) => {
    if (count !== null) {
      const newValue = value || [];
      const found = newValue.find((v) => v._id === item._id);
      if (found) {
        found.count = count;
      } else {
        newValue.push(
          Object.assign({}, item, {
            count: parseInt(count),
            id: item._id,
          })
        );
      }

      onChange(newValue);
      setList(newValue);
    }
  };

  return (
    <Wrapper f={Platform.OS !== "web" ? 0 : undefined} w="100%">
      <ListViewQuery
        query={QUERIES[type]}
        filter={filter || { _id: { $in: value || [] } }}
        name={type.toLowerCase() + "s"}
        renderItem={({ item, index }) => {
          return (
            <Listitem
              title={title(item)}
              desc={desc(item)}
              style={{
                width: "100%",
                borderRadius: 10,
                marginBottom: 10,
              }}
              actions={[
                ...(actions ? actions(item) : []),
                ...(withCount
                  ? [
                      {
                        label: "Anzahl ändern",
                        onPress: () => {
                          if (Platform.OS === "web") {
                            var count = prompt(
                              "Wie viele möchten Sie hinzufügen?"
                            );
                            setValueWithCount(count, item);
                          } else if (Platform.OS === "ios") {
                            Alert.prompt(
                              "Wie viele möchten Sie hinzufügen?",
                              null,
                              (count) => {
                                setValueWithCount(count, item);
                              }
                            );
                          } else if (Platform.OS === "android") {
                            showPrompt(true);
                            setItem(item);
                          }
                        },
                      },
                    ]
                  : []),
                ...(allowRemove
                  ? [
                      {
                        icon: "trash",
                        label: "Löschen",
                        onPress: () => {
                          var newList = list;
                          newList = newList.filter(
                            (e) => e && e._id !== item._id
                          );
                          setList(newList);
                          if (inputKey === "materials") {
                            onChange(newList);
                          } else {
                            var newValue = value || [];
                            newValue = newValue.filter(
                              (e) => e && e !== item._id
                            );
                            onChange(newValue);
                            if (inputKey === "materialIds") {
                              onChange(newList, "materials");
                            }
                          }
                        },
                      },
                    ]
                  : []),
              ]}
            />
          );
        }}
      />
      <Group style={{ marginBottom: 8 }}>
        {allowSearch && (
          <Button
            width="100%"
            mt={10}
            light
            onPress={() => {
              navigation.push("Search", {
                type: type.toLowerCase() + "s",
                onSelect: (item, goBack) => {
                  console.log({ list, item });
                  if (typeof list === "object" && list !== null) {
                    var found = list.filter((e) => e && e._id === item._id);
                    if (found.length > 0) {
                      alert("Schon in Liste vorhanden");
                      return;
                    }
                  }
                  if (!withCount) {
                    if (!multiple) {
                      onChange(item._id);
                    } else {
                      var newValue = value || [];
                      newValue.push(item._id);
                      newValue = newValue.filter((v) => v !== null);
                      onChange(newValue);
                    }
                    setList([...list, item._id]);
                  } else {
                    if (Platform.OS === "web") {
                      var count = prompt("Wie viele möchten Sie hinzufügen?");
                      setValueWithCount(count, item);
                    } else if (Platform.OS === "ios") {
                      Alert.prompt(
                        "Wie viele möchten Sie hinzufügen?",
                        null,
                        (count) => {
                          setValueWithCount(count, item);
                        }
                      );
                    } else if (Platform.OS === "android") {
                      showPrompt(true);
                      setItem(item);
                    }
                  }

                  goBack();
                },
              });
            }}
          >
            {!multiple
              ? `${i18n.t(`types.${type.toLowerCase()}`)} wählen`
              : `${i18n.t(`types.${type.toLowerCase()}_plural`)} hinzufügen`}
          </Button>
        )}
      </Group>
      {Platform.OS === "android" ? (
        <Prompt
          title="Wie viele möchten Sie hinzufügen?"
          placeholder="Anzahl"
          isVisible={adprompt}
          onChangeText={(text) => {
            setPromptValue(text);
          }}
          onCancel={() => {
            console.log("cancled");
            showPrompt(false);
          }}
          onSubmit={() => {
            setValueWithCount(promptValue, item);
            showPrompt(false);
          }}
        />
      ) : null}
    </Wrapper>
  );
};
