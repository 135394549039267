import React from "react";
import { Flex, Text, Icon } from "unikit";
import dayjs from "dayjs";
import { useQuery } from "react-apollo";

import { getObjValue } from "../../helper";
import { ACTIONS_QUERY } from "../../api/queries";
import { useAppContext } from "../../AppContext";

const groupBy = (array, prop, format) => {
  return array.reduce((groups, item) => {
    const val = format
      ? format(getObjValue(item, prop))
      : getObjValue(item, prop);
    if (groups.find((a) => a.title === val)) {
      groups.find((a) => a.title === val).data.push(item);
    } else {
      groups.push({
        title: val,
        value: getObjValue(item, prop),
        data: [item],
      });
    }

    return groups;
  }, []);
};

export const ActionItem = ({
  warehouseToId,
  warehouseFromId,
  createdAt,
  materials = [],
  team = [],
}) => {
  const getUser = (id) => {
    const user = team.find((user) => user._id === id);
    return user ? `${user.lastName}, ${user.firstName}` : "";
  };
  const to =
    warehouseToId === "warehouse"
      ? "Zentrallager"
      : getUser(warehouseToId) || "Ausgang";
  const from =
    warehouseFromId === "warehouse"
      ? "Zentrallager"
      : getUser(warehouseFromId) || "Eingang";
  return (
    <Flex bg="surface" shadow={3} borderRadius={10} mb={5} p={15}>
      <Flex row>
        <Text font="h5">{from}</Text>
        <Icon name="arrowRight" size={20} mx={5} />
        <Text font="h5">{to}</Text>
      </Flex>
      <Flex py={10}>
        {materials.map((mat, index) => {
          return (
            <Flex key={index}>
              <Text numberOfLines={1}>
                {`${mat.count}x ${mat.name}, ${mat.packagingUnit} - ${mat.size}`}{" "}
              </Text>
            </Flex>
          );
        })}
      </Flex>
      <Text opacity={0.5} font="label">{`${dayjs(createdAt).format(
        "DD.MM.YYYY"
      )}`}</Text>
    </Flex>
  );
};

export default ({ filter }) => {
  const { user } = useAppContext();
  const { loading, error, data = {}, fetchMore, refetch } = useQuery(
    ACTIONS_QUERY,
    {
      variables: {
        filter: filter,
        sort: { createdAt: -1 },
        page: 0,
        limit: 15,
      },
    }
  );
  const { actions } = data || {};
  if (loading) {
    return (
      <Flex>
        <Text font="label">loading...</Text>
      </Flex>
    );
  }
  const groupedActions = groupBy(actions, "createdAt", (value) => {
    return dayjs(value).format("DD.MMM");
  });

  return (
    <Flex py={5}>
      {groupedActions.length === 0 ? (
        <Flex w="100%" flexCenter py={30}>
          <Text font="label">Keine Aktionen vorhanden</Text>
        </Flex>
      ) : null}
      {groupedActions.map((group, i) => {
        return (
          <Flex mb={10} row key={`${i}-action-group`}>
            <Flex flex={1} shadow={4}>
              {group.data.map((item, index) => {
                return (
                  <ActionItem
                    key={index}
                    team={[...[user], ...user.team]}
                    {...item}
                  />
                );
              })}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
