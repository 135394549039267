import AsyncStorage from "@react-native-community/async-storage";
import { Platform } from "react-native";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { CachePersistor } from "apollo-cache-persist";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import ExpoFileSystemStorage from "redux-persist-expo-filesystem";

import OfflineLink from "./OfflineLink";

const SCHEMA_VERSION = "5"; // Must be a string.
const SCHEMA_VERSION_KEY = "apollo-schema-version";

export default async function getClient() {
  if (__DEV__) {
    var apiUri = "http://192.168.2.122:4000/graphql"; //"http://192.168.2.102:5000/graphql";
  } else {
    var apiUri = "https://api.clouddoku.de/graphql";
  }

  const httpLink = new HttpLink({
    uri: apiUri,
  });

  const offlineLink = new OfflineLink({
    storage: AsyncStorage,
  });

  const errorLink = onError(({ response, graphQLErrors, networkError }) => {
    console.log({ response, graphQLErrors, networkError });
    if (graphQLErrors) {
      console.log(graphQLErrors);
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
    console.log("response", response);
    if (response) {
      response.errors = null;
    }
  });

  const authLink = setContext(async (req, { headers }) => {
    const token = await AsyncStorage.getItem("token");
    return {
      ...headers,
      headers: { authorization: token ? token : null },
    };
  });

  const cache = new InMemoryCache({
    dataIdFromObject: (object) => object._id,
  });

  const client = new ApolloClient({
    link: ApolloLink.from([offlineLink, authLink, httpLink, errorLink]),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
      },
      query: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  });

  const persistor = new CachePersistor({
    cache,
    storage: Platform.OS === "android" ? ExpoFileSystemStorage : AsyncStorage,
    debug: __DEV__ ? true : false,
  });

  const currentVersion = await AsyncStorage.getItem(SCHEMA_VERSION_KEY);

  if (currentVersion === SCHEMA_VERSION) {
    // If the current version matches the latest version,
    // we're good to go and can restore the cache.
    try {
      // Might throw Couldn't read row 0, col 0 from CursorWindow
      await persistor.restore();
    } catch (err) {
      // CachePersistor failed - purge
      await persistor.purge();
    }
  } else {
    // Otherwise, we'll want to purge the outdated persisted cache
    // and mark ourselves as having updated to the latest version.
    await persistor.purge();
    await AsyncStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
  }

  offlineLink.setup(client);
  return client;
}
