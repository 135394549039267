import React, { Fragment } from "react";
import gql from "graphql-tag";
import { View } from "react-native";
import moment from "moment";
import { Tabs } from "unikit";

import { Page, ListViewQuery, ListItem, ListView } from "../../components";
import { SHARES_QUERY } from "../../api/queries";
import { useAppContext } from "../../AppContext";

export default function ShareListScreen({ navigation, route }) {
  const [tab, setTab] = React.useState("Von uns geteilt");
  const { user, inRole } = useAppContext();

  const patient = route?.params?.patient;
  const reader = inRole("reader", user ? user.roles : []);

  var filter = patient
    ? { patientId: patient._id, companyId: user.companyId }
    : { companyId: user.companyId };

  if (tab === "Mit uns geteilt") {
    filter = { shareCompanyId: user.companyId };
  }

  if (reader) {
    filter = { shareCompanyId: user.companyId };
  }
  return (
    <Page title="Geteilte Patienten">
      {patient || reader ? null : (
        <Tabs
          value={tab}
          options={["Von uns geteilt", "Mit uns geteilt"]}
          onChange={(v) => setTab(v)}
          backgroundColor="primary"
          color="#FFF"
          activeColor="#FFF"
          indicatorSize={3}
          indicatorColor="#FFF"
          shadow={35}
        />
      )}
      <ListViewQuery
        query={SHARES_QUERY}
        filter={filter}
        sort={{ createdAt: -1 }}
        name="shares"
        groupBy="createdAt"
        groupByFormat={({ key, value }) => {
          return moment(value).format("DD.MM.YYYY");
        }}
        renderItem={({ item, index }) => (
          <ListItem
            title={`${
              inRole(["client", "employee"])
                ? `${patient ? "Geteil mit" : "Geteilt von"}: ${
                    item.shareUserEmail
                  }, `
                : ""
            }${
              route.params?.showName || !route.params
                ? `${item.patientName}`
                : ""
            }, Dokumente: ${item.documents.length || 0}, ${
              item.therapyName || ""
            }`}
            desc={`Geteilt am ${moment(item.createdAt).format(
              "DD. MMMM YYYY"
            )}`}
            onPress={() => {
              navigation.navigate("ShareDetails", {
                title: `${item.patientName}, ${item.therapyName}`,
                share: item,
              });
            }}
            actions={
              user && inRole(["client", "employee"])
                ? [
                    {
                      icon: "delete",
                      label: "Nicht mehr teilen",
                      onPress: () => {
                        changeDoc({
                          id: item._id,
                          doc: item,
                          type: "Share",
                          mode: "delete",
                        });
                      },
                    },
                  ]
                : undefined
            }
          />
        )}
      />
    </Page>
  );
}
