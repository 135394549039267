import React from "react";
import { Image } from "react-native";
import { Svg, Defs, LinearGradient, Stop, Rect } from "react-native-svg";

import { Text, Flex, Slider } from "unikit";

const Gradient = React.memo(
  ({ gradientSteps, maximumValue, getStepColor, id = "gradient", ...rest }) => {
    return (
      <Svg height="100%" width="100%">
        <Defs>
          <LinearGradient id={id} x1="0" y1="0" x2="1" y2="0">
            <Stop offset="0" stopColor="#F70500" stopOpacity="1" />
            <Stop offset="0.5" stopColor="#FEFB00" stopOpacity="1" />
            <Stop offset="1" stopColor="#0DFE02" stopOpacity="1" />
          </LinearGradient>
        </Defs>

        <Rect x="0" y="0" width="100%" height="100%" fill={`url(#${id})`} />
      </Svg>
    );
  },
  () => {
    return true;
  }
);

var imgWidth = { width: 20, height: 20 };

export default ({ value = {}, options, onChange }) => (
  <Flex w="100%" p={15}>
    {options.map((option, index) => {
      return (
        <Flex w="100%" key={`option-${index}`}>
          <Text font="label" mt={5}>
            {option.label}
          </Text>
          <Flex flexCenter row>
            <Image
              style={imgWidth}
              source={{ uri: "https://api.clouddoku.de/images/smile10.png" }}
            />
            <Slider
              flex={1}
              renderTrack={<Gradient gradientSteps={25} />}
              value={value && value[option.value] ? value[option.value] : 1}
              min={1}
              max={10}
              steps={1}
              ticks={1}
              onChange={(v) => onChange({ ...value, [option.value]: v })}
              showTicks
              showValue
              hideProgressTrack
            />
            <Image
              style={imgWidth}
              source={{ uri: "https://api.clouddoku.de/images/smile1.png" }}
            />
          </Flex>
        </Flex>
      );
    })}
  </Flex>
);
