import * as React from "react";
import { Grid } from "unikit";

import { Page, ListItem } from "../../components";

export default function Stats({ navigation }) {
  const items = [
    {
      title: "Bestellungen",
      icon: "shoppingCart",
      onPress: () => {
        navigation.navigate("Orders", {});
      },
    },
    {
      title: "Wareneingang/Bestellmanagement",
      icon: "package",
      onPress: () => {
        navigation.navigate("Receipt", {});
      },
    },
    {
      title: "Warenaus-/Umbuchungen",
      icon: "refreshCCW",
      onPress: () => {
        navigation.navigate("Change", {});
      },
    },
    {
      title: "Mein Lager verwalten",
      icon: "user",
      onPress: () => {
        navigation.navigate("Manage", {});
      },
    },
    {
      title: "Warenbestand",
      icon: "barChart",
      onPress: () => {
        navigation.navigate("WarehouseStats", {});
      },
    },
  ];
  return (
    <Page title="Warenwirtschaft" scrollView>
      <Grid gap={15} min={300} mt={15} maxCols={2}>
        {items.map((item, index) => (
          <ListItem
            key={index}
            title={item.title}
            icon={item.icon}
            iconColor="primary"
            card
            style={{ padding: 0, width: "100%" }}
            onPress={item.onPress}
            disabled={!item.onPress}
          />
        ))}
      </Grid>
    </Page>
  );
}
