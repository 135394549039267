import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useTheme, Flex } from "unikit";
import "react-quill/dist/quill.bubble.css";

import { Page, Icon } from "../../components";

export default function Screen({ navigation, route }) {
  const theme = useTheme();
  const [value, setValue] = useState(route?.params?.value);

  return (
    <Page
      title="Editor"
      headerRight={() => (
        <Icon
          name="check"
          color="#FFF"
          onPress={() => route.params.onSave(value, navigation.goBack)}
        />
      )}
    >
      <Flex flex={1} bg="#FFF">
        <ReactQuill
          theme="bubble"
          value={value}
          onChange={(html) => setValue(html)}
          modules={{
            toolbar: [
              ["bold", "italic", "underline"],
              [{ header: 1 }, { header: 2 }],
            ],
          }}
        />
      </Flex>
    </Page>
  );
}
