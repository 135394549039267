import SimpleSchema from "simpl-schema";

SimpleSchema.extendOptions([
  "typeName",
  "listed",
  "listedPDF",
  "input",
  "group",
  "visible",
  "listed",
  "steps",
  "options",
  "readonly",
  "multiple",
  "multi",
  "placeholder",
  "roles",
  "min",
  "max",
  "ticks",
  "inputOptions",
  "subKey",
]);

export default SimpleSchema;
