export const ifIE = () => {
  var result = false;
  if (/MSIE 10/i.test(navigator.userAgent)) {
    result = true;
  }

  if (
    /MSIE 9/i.test(navigator.userAgent) ||
    /rv:11.0/i.test(navigator.userAgent)
  ) {
    result = true;
  }

  if (/Edge\/\d./i.test(navigator.userAgent)) {
    result = true;
  }
  return result;
};

export const getObjValue = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];

    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const logger = log => {
  if (__DEV__) {
    console.log(log);
  }
};

export const getTherapyName = therapy => {
  var name = "";
  if (therapy.ulcusCruris) {
    name += "UlcusCruris";
    if (therapy.venosum) {
      name += " Venosum";
    }
    if (therapy.arteriosum) {
      name += " Arteriosum";
    }
    if (therapy.mixtum) {
      name += " Mixtum";
    }
  }
  if (therapy.decubitus) {
    name += "Decubitus";
  }
  if (therapy.secondaryWoundHealing) {
    name += "Sekundäre Wundheilung";
  }
  if (therapy.diabeticFoot) {
    name += "Diabetischer Fuß";
  }
  if (therapy.traumatic) {
    name += "Traumatisch";
  }
  if (therapy.TUWound) {
    name += "TU Wunde";
  }
  if (therapy.burn) {
    name += "Verbrennung";
  }
  if (therapy.woundTypeOther) {
    if (therapy.woundTypeOtherName) {
      name += therapy.woundTypeOtherName;
    } else {
      name += "Andere";
    }
  }
  if (therapy.woundLocalisation) {
    name += " - " + therapy.woundLocalisation;
  }
  return name;
};

export const WoundImageUtil = {
  calculateRelativeDistance: function(pointA, pointB) {
    return Math.sqrt(
      Math.pow(pointA.posX - pointB.posX, 2) +
        Math.pow(pointA.posY - pointB.posY, 2)
    );
  },

  calculateAbsoluteDistance: function(distRel, refRel, refAbs) {
    var mult = refAbs / refRel;
    return distRel * mult;
  },

  calculateArea: function(points, refRel, refAbs) {
    var multi = refAbs / refRel;
    var area = 0;
    // Accumulates area in the loop
    var i;
    var j = points.length - 1; // The last vertex is the 'previous' one to the first

    for (i = 0; i < points.length; i++) {
      area =
        area +
        (points[j].posX * multi + points[i].posX * multi) *
          (points[j].posY * multi - points[i].posY * multi);
      j = i; //j is previous vertex to i
    }
    return Math.abs(area / 2);
  },

  makeRelativeToScope: function(point, scopeWidth, scopeHeight) {
    if (!point || !point.posX || !point.posY || !scopeHeight || !scopeWidth) {
      return { posX: 0, posY: 0 };
    }
    return {
      posX: point.posX / scopeWidth,
      posY: point.posY / scopeWidth
    };
  }
};

export const scorePassword = pass => {
  var score = 0;
  if (!pass) return score;

  // award every unique letter until 5 repetitions
  var letters = new Object();
  for (var i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  var variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  };

  var variationCount = 0;
  for (var check in variations) {
    variationCount += variations[check] == true ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  return parseInt(score) > 100 ? 100 : parseInt(score);
};
