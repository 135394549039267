import React from "react";
import { View } from "react-native";
import { Progress, Flex, Text } from "unikit";

import { scorePassword } from "../helper";

const scores = [
  { min: 0, max: 33.33, label: "schwach", color: "#f44336" },
  { min: 33.34, max: 66.66, label: "mittel", color: "#ffbb33" },
  { min: 66.67, max: 100, label: "stark", color: "#8bc34a" },
];

export default ({ password, ...rest }) => {
  const score = scorePassword(password || 0);
  const scoreInfo = scores.find((s) => s.min <= score && s.max >= score);
  console.log({ score });
  return (
    <Flex
      m={15}
      p={15}
      borderRadius={10}
      shadow={3}
      alignItems="center"
      bg="surface"
      row
      {...rest}
    >
      <Progress
        size={36}
        trackWidth={5}
        progressWidth={5}
        progressColor={scoreInfo.color}
        value={score}
      />
      <View style={{ marginLeft: 12 }}>
        <Text font="label">{`Passwort stärke:`}</Text>
        <Text color={scoreInfo.color}>{`${scoreInfo.label}`}</Text>
      </View>
    </Flex>
  );
};
