import * as Localization from "expo-localization";
import i18n from "i18n-js";
import dayjs from "dayjs";
import "dayjs/locale/de";

i18n.fallbacks = true;
i18n.defaultLocale = "de";
i18n.locale = Localization.locale;
dayjs.locale(Localization.locale);
i18n.translations = {
  de: {
    success: "Erledigt",
    add: "hinzufügen",
    anamnese: "Erhebungsanalysen",
    anamnese_single: "Erhebungsanalysen",
    deliverynote: "Lieferscheine",
    advice: "Therapieempfehlungen",
    doctorsletter: "Arztbrief",
    document: "Dokumente",
    woundimage: "Wundbilder",
    patients: "Patienten",
    appointments: "Termine",
    materials: "Materialien",
    my: "Meine",
    all: "Alle",
    archiv: "Archiv",
    documents: "Documents",
    search: "Suche",
    saved: "Erfolgreich gespeichert",
    send: "Erfolgreich gesendet",
    woundImages: "Woundimages",
    therapyStats: "Wundverlauf",
    transactions: "Transaktionen",
    form: {
      email: "E-Mail",
    },
    error: {
      doc_canceled: "Dokument gesperrt",
      wrong_password: "User & Passwort stimmen nicht überein",
      user_banned: "Account gesperrt",
    },
    types: {
      therapy: "Wunde",
      therapy_plural: "Wunden",
      anamnese: "Erhebungsanalyse",
      anamnese_plural: "Erhebungsanalysen",
      deliverynote: "Lieferschein",
      deliverynote_plural: "Lieferscheine",
      advice: "Therapieempfehlung",
      advice_plural: "Therapieempfehlungen",
      doctorsletter: "Arztbrief",
      doctorsletter_plural: "Arztbrief",
      document: "Dokument",
      document_plural: "Dokumente",
      woundimage: "Wundbild",
      woundimage_plural: "Wundbilder",
      appointment: "Termin",
      appointment_plural: "Termine",
      appointments_plural: "Termine",
      evidence: "Nachweis",
      material: "Material",
      material_plural: "Materialien",
      materials_plural: "Materialien",
      materialSingle_plural: "Materialien",
      contacts_plural: "Kontakte",
      patient: "Patient",
      patients: "Patient",
      patients_plural: "Patienten",
      orders: "Bestellung",
      orders_plural: "Bestellungen",
      contact: "Kontakt",
      email: "Kontakt",
      email_plural: "Kontakte",
      contact_plural: "Kontakte",
      share: "Teilen",
    },
    actions: {
      insert_doc: "erstellt",
      update_doc: "aktualisiert",
      delete_doc: "gelöscht",
      order: "Order",
      warehouse_in: "eingang",
      warehouse_out: "ausgang",
      warehouse_change: "umbuchung",
    },
  },
};

export default i18n;
