import * as React from "react";
import dayjs from "dayjs";
import { Flex } from "unikit";

import { Page, ListViewQuery, ListItem, Icon } from "../../components";
import { MATERIALSGROUP_QUERY } from "../../api/queries";
import { useAppContext } from "../../AppContext";

export default function MaterialsScreen({ navigation }) {
  const { user } = useAppContext();
  return (
    <Page
      title="Material"
      headerRight={() => (
        <Flex row alignItems="center">
          <Icon
            name="search"
            color="#FFF"
            size={23}
            onPress={() =>
              navigation.navigate("Search", {
                type: "materials",
              })
            }
          />
          <Icon
            name="plus"
            color="#FFF"
            onPress={() =>
              navigation.navigate("Form", {
                title: "Neues Material",
                type: "MaterialGroup",
                mode: "insert",
                defaultValues: {
                  materials: [],
                },
                query: {
                  query: MATERIALSGROUP_QUERY,
                  variables: {
                    filter: { companyId: user.companyId },
                    sort: { category: 1 },
                  },
                },
              })
            }
          />
        </Flex>
      )}
    >
      <ListViewQuery
        query={MATERIALSGROUP_QUERY}
        filter={{ companyId: user.companyId }}
        sort={{ category: 1 }}
        name="materialgroups"
        groupBy="category"
        renderItem={({ item, index }) => {
          return (
            <ListItem
              title={`${item.producer}, ${item.name}`}
              desc={`Aktualisiert am: ${dayjs(item.updatedAt).format(
                "DD.MM.YYYY"
              )}`}
              pills={[
                ...(item.copyId ? [{ color: "primary", label: "Sync" }] : []),
                ...(item.companyId === "global"
                  ? [{ color: "primary", label: "Global" }]
                  : []),
              ]}
              onPress={() => {
                navigation.navigate("Form", {
                  title: "Material bearbeiten",
                  data: item,
                  id: item._id,
                  type: "MaterialGroup",
                  mode: "update",
                });
              }}
            />
          );
        }}
      />
    </Page>
  );
}
