import React from "react";
import { ImageBackground, ScrollView, Dimensions } from "react-native";
import { styled, Flex, Progress } from "unikit";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useNavigation } from "@react-navigation/native";

const Page = styled.View({
  flex: 1,
  backgroundColor: "background",
  position: "relative",
});

const Background = styled(ImageBackground)({
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  opacity: 0.8,
});

export default ({
  children,
  scrollView,
  keyboardAware = false,
  scrollable = false,
  style,
  background,
  loading = false,
  title,
  headerLeft,
  headerRight,
  bounces = true,
  ...rest
}) => {
  const { setOptions } = useNavigation();
  React.useEffect(() => {
    const options = {};
    if (title) {
      options["title"] = `${title}`;
    }
    if (headerLeft) {
      options["headerLeft"] = headerLeft;
    }
    if (headerRight) {
      options["headerRight"] = headerRight;
    }
    setOptions(options);
  }, [title, headerLeft, headerRight]);

  return (
    <Page
      as={
        scrollView || scrollable
          ? keyboardAware
            ? KeyboardAwareScrollView
            : ScrollView
          : undefined
      }
      keyboardShouldPersistTaps={"handled"}
      bounces={bounces}
      style={style}
      {...rest}
    >
      {background ? <Background source={background} /> : null}
      {loading ? (
        <Flex w="100%" h={Dimensions.get("window").height - 200} flexCenter>
          <Progress size={30} trackWidth={5} progressWidth={3} loading />
        </Flex>
      ) : (
        children
      )}
    </Page>
  );
};
