import React, { useState, useEffect } from "react";
import { Flex, Icon, Touchable } from "unikit";
import dayjs from "dayjs";

import { Page, Calendar } from "../components";
import { APPOINTMENTS_QUERY, useQuery } from "../api/queries";
import { useAppContext } from "../AppContext";

export default function Screen({ navigation }) {
  const { user, getUser } = useAppContext();
  const [date, setDate] = useState(new Date());
  const [activeGroups, setGroups] = useState([user._id]);
  const [filter, setFilter] = useState({
    ...(user ? { groupId: user._id } : {}),
    from: dayjs(date).startOf("week").toDate(),
    to: dayjs(date).endOf("week").toDate(),
  });

  const variables = {
    filter,
    sort: { datum: 1 },
    limit: 1000,
  };

  useEffect(() => {
    if (activeGroups.length > 0) {
      setFilter({ ...filter, groupId: { $in: activeGroups } });
    } else {
      const newFilter = Object.assign({}, filter);
      delete newFilter["groupId"];
      setFilter(newFilter);
    }
  }, [activeGroups]);

  useEffect(() => {
    setFilter({
      ...filter,
      from: dayjs(date).startOf("week").toDate(),
      to: dayjs(date).endOf("week").toDate(),
    });
  }, [date]);

  const team = [...[user], ...user.team];

  const {
    data = {},
    error,
    loading,
  } = useQuery(APPOINTMENTS_QUERY, {
    variables,
  });

  const { appointments = [] } = data;

  return (
    <Page
      style={{ flex: 1 }}
      title="Termine"
      headerLeft={() => (
        <Touchable
          onPress={() =>
            navigation.navigate("Search", {
              type: "appointments",
            })
          }
          w={44}
          h={44}
          flexCenter
        >
          <Icon name="search" color="#FFF" size={24} />
        </Touchable>
      )}
      headerRight={() => (
        <Touchable
          onPress={() =>
            navigation.navigate("Form", {
              title: "Appointment",
              type: "Appointment",
              mode: "insert",
              defaultValues: {
                datum: new Date(),
                groupId: activeGroups.length === 1 ? activeGroups[0] : user._id,
                time: 30,
                user:
                  activeGroups.length === 1 ? getUser(activeGroups[0]) : user,
              },
              query: {
                query: APPOINTMENTS_QUERY,
                variables: variables,
              },
              onSuccess: (navigation, item) => {
                navigation.goBack();
              },
            })
          }
          w={44}
          h={44}
          flexCenter
        >
          <Icon name="plus" color="#FFF" size={24} />
        </Touchable>
      )}
    >
      <Calendar
        date={date}
        timeSteps={0.25}
        endTime={20}
        onChangeDate={(d) => setDate(d)}
        items={
          appointments
            ? appointments
                .filter((a) => a.deleted !== true)
                .map((item) => ({
                  id: item._id,
                  title: item.patient,
                  desc: item.reason,
                  date: item.datum,
                  done: item.done,
                  duration: item.time,
                  groupId: item.groupId,
                  allDay: item.daily,
                  color: getUser(item.groupId)
                    ? getUser(item.groupId).color
                    : "primary",
                  data: item,
                }))
            : []
        }
        filter={activeGroups}
        onChangeFilter={(filter) => setGroups(filter)}
        groups={team
          .filter((t) => t.banned !== true)
          .map((t) => ({
            id: t._id,
            name: `${t.firstName} ${t.lastName}`,
            color: t.color,
          }))}
        onSlotPress={(date) => {
          navigation.navigate("Form", {
            title: "Appointment",
            type: "Appointment",
            mode: "insert",
            defaultValues: {
              datum: date,
              groupId: activeGroups.length === 1 ? activeGroups[0] : user._id,
              time: 30,
              user: activeGroups.length === 1 ? getUser(activeGroups[0]) : user,
            },
            query: {
              query: APPOINTMENTS_QUERY,
              variables: variables,
            },
            onSuccess: (navigation, item) => {
              navigation.goBack();
            },
          });
        }}
        onItemPress={(event) => {
          console.log({ event: event.data });
          navigation.navigate("Form", {
            title: event?.title || null,
            data: event.data,
            id: event.data?._id,
            type: "Appointment",
            mode: "update",

            query: {
              query: APPOINTMENTS_QUERY,
              variables: variables,
            },
            onSuccess: (navigation, item) => {
              navigation.goBack();
            },
          });
        }}
      />
    </Page>
  );
}
