import React from "react";
import { useTheme, Flex, P } from "unikit";
import dayjs from "dayjs";
import { useQuery, gql } from "react-apollo";

import ListItem from "./ListItem";
import { getObjValue } from "../helper";
import i18n from "../I18n";
import { ACTIONS_QUERY } from "../api/queries";

const groupBy = (array, prop, format) => {
  return array.reduce((groups, item) => {
    const val = format
      ? format(getObjValue(item, prop))
      : getObjValue(item, prop);
    if (groups.find((a) => a.title === val)) {
      groups.find((a) => a.title === val).data.push(item);
    } else {
      groups.push({
        title: val,
        value: getObjValue(item, prop),
        data: [item],
      });
    }

    return groups;
  }, []);
};

export default ({ patientId }) => {
  const { loading, error, data = {}, fetchMore, refetch } = useQuery(
    ACTIONS_QUERY,
    {
      variables: {
        filter: { patientId },
        sort: { createdAt: -1 },
        page: 0,
        limit: 15,
      },
    }
  );
  const { actions } = data || {};
  const theme = useTheme();
  if (loading) {
    return (
      <Flex>
        <P font="label">loading...</P>
      </Flex>
    );
  }
  const groupedActions = groupBy(actions, "createdAt", (value) => {
    return dayjs(value).format("DD.MMM");
  });

  return (
    <Flex p={15}>
      {groupedActions.map((group, i) => {
        return (
          <Flex mb={10} row key={`${i}-action-group`}>
            <Flex w={50} alignItems="center">
              <P font="h4">{group.title.split(".")[0]}</P>
              <P font="label">{group.title.split(".")[1]}</P>
            </Flex>
            <Flex
              bg="surface"
              borderRadius={theme.globals.roundness}
              p={5}
              flex={1}
              shadow={4}
            >
              {group.data.map((item, index) => {
                return (
                  <ListItem
                    key={`${index}-action-item`}
                    style={{
                      width: "100%",
                      borderBottomWidth: index < group.data.length - 1 ? 1 : 0,
                      borderColor:
                        theme.mode === "dark"
                          ? "rgba(255,255,255,0.05)"
                          : "rgba(0,0,0,0.05)",
                    }}
                    title={`${i18n.t(
                      `types.${item.typeName.toLowerCase()}`
                    )} ${i18n.t(`actions.${item.type}`)}`}
                    users={item.user ? [item.user] : undefined}
                    desc={dayjs(item.createdAt).format(
                      "HH:MM [Uhr] DD MMMM YYYY"
                    )}
                  />
                );
              })}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
