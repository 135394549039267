import SimpleSchema from "./simple";

const schema = {
  typ: {
    type: String,
    label: "Typ",
    options: [
      { label: "Pflegedienst", value: "Pflegedienst" },
      { label: "Hausarzt", value: "Hausarzt" },
      { label: "Behandelnder Arzt", value: "Behandelnder Arzt" },
      { label: "Zuweisender Arzt", value: "Zuweisender Arzt" },
      { label: "Zuweisende Klinik", value: "Zuweisende Klinik" },
      { label: "Podologe", value: "Podologe" },
      { label: "Versicherung", value: "Versicherung" },
      { label: "Wundmanager", value: "Wundmanager" },
      { label: "Pflegeheim", value: "Altenheim" },
      { label: "Betreuungsperson", value: "Betreuungsperson" },
      { label: "Physiotherapeut", value: "Physiotherapeut" },
      { label: "Lieferant", value: "Lieferant" },
    ],
    input: "Select",
    group: "Allgemein",
  },
  name: {
    type: String,
    label: "Name",
    group: "Allgemein",
  },
  address: {
    type: String,
    label: "Adresse",
    optional: true,
    group: "Allgemein",
  },
  zipCode: {
    type: String,
    label: "Postleitzahl",
    optional: true,
    group: "Allgemein",
  },
  residence: {
    type: String,
    label: "Wohnort",
    optional: true,
    group: "Allgemein",
  },
  phone: {
    type: String,
    label: "Telefon",
    optional: true,
    group: "Allgemein",
  },
  mobile: {
    type: String,
    label: "Mobil",
    optional: true,
    group: "Allgemein",
  },
  fax: {
    type: String,
    label: "Fax",
    optional: true,
    group: "Allgemein",
  },
  email: {
    type: String,
    label: "E-Mail",
    optional: true,
    group: "Allgemein",
  },
  discount: {
    type: Number,
    label: "Rabatt in %",
    optional: true,
    group: "Allgemein",
    input: "Number",
    visible: ({ doc }) => doc.typ === "Lieferant",
  },
  customerNumber: {
    type: Number,
    label: "Kundennummer",
    optional: true,
    group: "Allgemein",
    visible: ({ doc }) => doc.typ === "Lieferant",
  },
  note: {
    type: String,
    label: "Bemerkung",
    optional: true,
    input: "TextArea",
    group: "Allgemein",
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Contacts = new Mongo.Collection("contacts");
  Contacts.schema = simpleSchema;
  Contacts.attachSchema(simpleSchema);
  Contacts._ensureIndex({ companyId: 1 });
}

export { simpleSchema as ContactSchema };

export default schema;
