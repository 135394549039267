import * as React from "react";
import { Platform } from "react-native";
import { Icon, useTheme, Flex, isWeb } from "unikit";
import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import AsyncStorage from "@react-native-community/async-storage";
import * as Linking from "expo-linking";

import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";

import {
  Login,
  Patients,
  Patient,
  PDF,
  Search,
  Form,
  Evidence,
  Team,
  Documents,
  Contacts,
  Therapy,
  Sharing,
  TextEditor,
  ShareList,
  ShareDetails,
  Overview,
  Change,
  TherapyStats,
  Stats,
  File,
  Clients,
  Client,
  Auth,
  Materials,
  Settings,
  Signature,
  Manage,
  Orders,
  Order,
  Calandar,
  Receipt,
  WarehouseStats,
} from "./screens";
import i18n from "./I18n";
import { useAppContext } from "./AppContext";

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const screenOptions = {
  safeAreaInset: "never",
};

const stackOptions = { animationEnabled: true, headerTitleAlign: "center" };

const globalScreens = {
  Patient: {
    component: Patient,
    path: "/patient/:_id",
  },
  Search: {
    component: Search,
    path: "/search/:type",
  },
  Form: {
    component: Form,
    path: "/form",
  },
  PDF: {
    component: PDF,
    path: "/pdf",
  },
  Evidence: {
    component: Evidence,
    path: "/evidence",
  },
  Therapy: {
    component: Therapy,
    path: "/therapy",
  },
  Sharing: {
    component: Sharing,
    path: "/sharing",
  },
  TextEditor: {
    component: TextEditor,
    path: "/texteditor",
  },
  ShareList: {
    component: ShareList,
    path: "/sharelist",
  },
  ShareDetails: {
    component: ShareDetails,
    path: "/sharedetails",
  },
  Change: {
    component: Change,
    path: "/change",
  },
  TherapyStats: {
    component: TherapyStats,
    path: "/therapystats",
  },
  File: {
    component: File,
    path: "/file",
  },
  Signature: {
    component: Signature,
    path: "/signature",
  },
};

const ICONS = {
  Patients: "user",
  Calendar: "calendar",
  Documents: "fileText",
  More: "menu",
};

const AppNavigation = ({ authRef }) => {
  const theme = useTheme();
  const navigationRef = React.useRef(null);
  const { user, inRole } = useAppContext();
  const [isReady, setIsReady] = React.useState(false);
  const [initialState, setInitialState] = React.useState();

  React.useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();

        if (Platform.OS !== "web" && initialUrl == null) {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await AsyncStorage.getItem(
            "NAVIGATION_STATE"
          );
          const state = savedStateString
            ? JSON.parse(savedStateString)
            : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  const options = ({ route }) => ({
    ...(Platform.OS === "web" ? TransitionPresets.RevealFromBottomAndroid : {}),
    title: route.params?.title
      ? route.params?.title.toString()
      : i18n.t(route.name.toString().toLowerCase()).toString(),
    headerBackTitle: "Zurück",
    headerBackImage: () => (
      <Flex h={44} flexCenter>
        <Icon name="chevronLeft" color="#FFF" size={isWeb ? 32 : 28} />
      </Flex>
    ),
    gestureEnabled: route.name === "Form" ? false : true,
    headerStyle: {
      backgroundColor: theme.colors.primary,
      borderBottomWidth: 0,
      shadowColor: "transparent",
      borderBottomColor: "transparent",
    },
    headerTintColor: "#fff",
    headerTitleStyle: {
      fontWeight: "normal",
    },
  });

  const screens = React.useMemo(() => {
    return Object.keys(globalScreens).map((key) => (
      <Stack.Screen
        name={key}
        component={globalScreens[key].component}
        options={options}
      />
    ));
  }, []);

  const screensLinking = React.useMemo(() => {
    return Object.keys(globalScreens).reduce((acc, key) => {
      acc[key] = globalScreens[key].path;
      return acc;
    }, {});
  }, []);

  const linking = {
    prefixes: ["https://app.clouddoku.de"],
    config: {
      screens: {
        Login: "",
        Tabs: {
          path: "tab",
          screens: {
            Patients: {
              path: "",
              initialRouteName: "PatientsList",
              screens: {
                PatientsList: "/patients",
                ...screensLinking,
              },
            },
            Calendar: {
              path: "/calendar",
              initialRouteName: "CalendarView",
              screens: {
                CalendarView: "/week",
                ...screensLinking,
              },
            },
            Documents: {
              path: "/documents",
              DocumentsView: "CalendarView",
              screens: {
                DocumentsView: "/all",
                ...screensLinking,
              },
            },
            More: {
              path: "/more",
              initialRouteName: "Settings",
              screens: {
                Settings: "/settings",
                Stats: "/stats",
                Overview: "/overview",
                Manage: "/manage",
                Orders: "/orders",
                Receipt: "/receipt",
                WarehouseStats: "/warehousewtats",
                ...screensLinking,
              },
            },
          },
        },

        NoMatch: "*",
      },
    },
  };

  if (!isReady) {
    return null;
  }

  const tabScreenOptions = ({ route }) => ({
    tabBarIcon: ({ focused }) => {
      return (
        <Icon
          name={ICONS[route.name]}
          size={24}
          color="primary"
          opacity={focused ? 1 : 0.5}
          strokeWidth={focused ? 1.5 : 1}
        />
      );
    },
  });

  const MoreTab = (
    <Tab.Screen
      name="More"
      options={{
        tabBarLabel: "Mehr",
      }}
    >
      {() => (
        <Stack.Navigator screenOptions={stackOptions}>
          <Stack.Screen
            name="Settings"
            component={Settings}
            options={options}
          />
          <Stack.Screen name="Stats" component={Stats} options={options} />
          <Stack.Screen
            name="Materials"
            component={Materials}
            options={options}
          />
          <Stack.Screen
            name="Contacts"
            component={Contacts}
            options={options}
          />
          <Stack.Screen name="Team" component={Team} options={options} />
          <Stack.Screen name="Reset" component={Auth} options={options} />
          <Stack.Screen name="Clients" component={Clients} options={options} />
          <Stack.Screen name="Client" component={Client} options={options} />
          <Stack.Screen
            name="Overview"
            component={Overview}
            options={options}
          />
          <Stack.Screen name="Manage" component={Manage} options={options} />
          <Stack.Screen name="Orders" component={Orders} options={options} />
          <Stack.Screen
            name="WarehouseStats"
            component={WarehouseStats}
            options={options}
          />
          <Stack.Screen name="Receipt" component={Receipt} options={options} />

          {screens}
          {/* {screens} */}
        </Stack.Navigator>
      )}
    </Tab.Screen>
  );

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      initialState={initialState}
      onStateChange={(state) => {
        if (Platform.OS !== "web") {
          AsyncStorage.setItem("NAVIGATION_STATE", JSON.stringify(state));
        }
      }}
    >
      <Stack.Navigator>
        {!user ? (
          <Stack.Screen
            name="Login"
            options={{
              ...screenOptions,
              headerShown: false,
            }}
          >
            {() => <Login authRef={authRef || {}} />}
          </Stack.Screen>
        ) : !inRole(["admin", "client", "employee"]) ? (
          <Stack.Screen
            name="Tabs"
            options={{
              ...screenOptions,
              headerShown: false,
            }}
          >
            {() => (
              <Tab.Navigator
                screenOptions={tabScreenOptions}
                tabBarOptions={{
                  activeTintColor: theme.colors.text,
                  style: {
                    backgroundColor: theme.colors.surface,
                    borderTopRightRadius: 15,
                    borderTopLeftRadius: 15,
                    borderTopColor:
                      theme.mode === "dark"
                        ? "rgba(255,255,255,0.075)"
                        : "rgba(0,0,0,0.075)",
                  },
                }}
                swipeEnabled
              >
                <Tab.Screen
                  name="Patients"
                  options={{
                    tabBarLabel: "Patienten",
                  }}
                >
                  {() => (
                    <Stack.Navigator screenOptions={stackOptions}>
                      <Stack.Screen
                        name="PatientShareList"
                        component={ShareList}
                        options={options}
                      />
                      {screens}
                    </Stack.Navigator>
                  )}
                </Tab.Screen>
                {MoreTab}
              </Tab.Navigator>
            )}
          </Stack.Screen>
        ) : (
          <Stack.Screen
            name="Tabs"
            options={{
              ...screenOptions,
              headerShown: false,
            }}
          >
            {() => (
              <Tab.Navigator
                screenOptions={tabScreenOptions}
                tabBarOptions={{
                  activeTintColor: theme.colors.text,
                  style: {
                    backgroundColor: theme.colors.surface,
                    // borderTopRightRadius: 15,
                    // borderTopLeftRadius: 15,
                    borderTopColor:
                      theme.mode === "dark"
                        ? "rgba(255,255,255,0.075)"
                        : "rgba(0,0,0,0.075)",
                  },
                }}
                swipeEnabled
              >
                <Tab.Screen
                  name="Patients"
                  options={{
                    tabBarLabel: "Patienten",
                  }}
                >
                  {() => (
                    <Stack.Navigator screenOptions={stackOptions}>
                      <Stack.Screen
                        name="PatientsList"
                        component={Patients}
                        options={options}
                      />
                      {screens}
                    </Stack.Navigator>
                  )}
                </Tab.Screen>
                <Tab.Screen
                  name="Calendar"
                  options={{
                    tabBarLabel: "Kalender",
                  }}
                >
                  {() => (
                    <Stack.Navigator screenOptions={stackOptions}>
                      <Stack.Screen
                        name="CalendarView"
                        component={Calandar}
                        options={options}
                      />
                      {screens}
                    </Stack.Navigator>
                  )}
                </Tab.Screen>
                <Tab.Screen
                  name="Documents"
                  options={{
                    tabBarLabel: "Dokumente",
                  }}
                >
                  {() => (
                    <Stack.Navigator screenOptions={stackOptions}>
                      <Stack.Screen
                        name="DocumentsView"
                        component={Documents}
                        options={options}
                      />
                      {screens}
                    </Stack.Navigator>
                  )}
                </Tab.Screen>
                {MoreTab}
              </Tab.Navigator>
            )}
          </Stack.Screen>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default AppNavigation;
