import React, { useState } from "react";
import gql from "graphql-tag";
import { Flex, Text, Overlay, Form, Input } from "unikit";
import { Page, ListViewQuery, ListItem, Icon } from "../../components";
import dayjs from "dayjs";

import getSchemaByType from "../../clouddoku/lib/schemas";
import { useAppContext } from "../../AppContext";

const QUERY = gql`
  query users($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    users(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "users", filter: ["filter"]) {
        ${getSchemaByType("User").typeDef}
    }
  }
`;

export default function Screen({ route, navigation: { push, navigate } }) {
  const { call } = useAppContext();
  const [activeUser, setActiveUser] = useState(0);
  const client = route?.params?.user || {};

  const getProgress = (a, b, v) => {
    return (v - a) / (b - a);
  };

  const diff =
    client && client.joined ? dayjs().diff(client.joined, "days") : 0;

  const progress = getProgress(0, 364, diff);
  const progressWarning = getProgress(0, 364, 270);
  return (
    <Page
      title="Kunden"
      headerRight={() => (
        <Icon
          name="edit"
          color="#FFF"
          onPress={() => {
            navigate("Form", {
              title: "Kunden bearbeiten",
              data: client,
              id: client?._id,
              type: "User",
              mode: "updateClient",
              roles: ["admin"],
            });
          }}
        />
      )}
    >
      <Flex px={10} pb={5} pt={10}>
        <Text level={2} mt={5} ml={5}>
          {client && client.joined
            ? `Vertragslaufzeit ab ${dayjs(client.joined).format("DD.MM.YYYY")}`
            : "Vertragslaufzeit nicht angegeben"}
        </Text>
        <Flex w="100%" mt={5} h={40} br={5} bg="background">
          <Flex w={`${progress * 100}%`} br={5} h={40} bg="primary" />
          <Flex
            w={2}
            h={60}
            l={`${progressWarning * 100}%`}
            t={-10}
            absolute
            bg="primary"
          />
        </Flex>
      </Flex>
      <ListViewQuery
        title="Mitarbeiter"
        query={QUERY}
        onLoad={({ name, data }) => {
          setActiveUser(data.filter((a) => a.banned !== true).length);
        }}
        filter={{
          _id: { $ne: client._id },
          companyId: client.companyId,
          roles: {
            $in: ["employee"],
          },
        }}
        sort={{ emails: 1 }}
        name="users"
        groupBy="banned"
        groupByFormat={({ key, value }) =>
          value === true
            ? "Inaktiv"
            : `Aktiv: ${activeUser}/${client.employeeCount}`
        }
        renderItem={({ item, index }) => (
          <ListItem
            title={`${item.emails[0].address}`}
            desc={`${item.lastName}, ${item.firstName}`}
            onPress={() => {
              navigate("Form", {
                title: "Mitarbeiter bearbeiten",
                data: item,
                id: item._id,
                type: "User",
                mode: "updateEmployee",
                roles: ["employee"],
              });
            }}
            actions={[
              {
                icon: "print",
                label: "Passwort zurücksetzen",
                onPress: () => {
                  call({
                    name: "enrollAccount",
                    config: {
                      mail: item.emails[0].address,
                    },
                  });
                },
              },
              {
                icon: "print",
                label: "Passwort manuell setzen",
                onPress: () => {
                  push("Auth", {
                    _id: item._id,
                    backOnSuccess: true,
                  });
                },
              },
            ]}
          />
        )}
      />
    </Page>
  );
}
