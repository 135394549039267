import React, { memo } from "react";
import { Input, isFunction } from "unikit";

import Signature from "./Signature";
import List from "./List";
import File from "./File";
import ImageMeasure from "./ImageMeasure";
import CutIntensity from "./CutIntensity";
import Dekubitus from "./Dekubitus";
import WoundStatus from "./WoundStatus";
import WoundGraduation from "./WoundGraduation";
import DiabeticFoot from "./DiabeticFoot";
import Localisation from "./Localisation";
import MaterialList from "./MaterialList";
import BarCode from "./BarCode";
import DocList from "./DocList";
import LifeAndTreatment from "./LifeAndTreatment";
import Actions from "./Actions";

import TextEditor from "./TextEditor";
import ShareDocuments from "./ShareDocuments";

const Inputs = {
  String: "text",
  Boolean: "switch",
  Toggle: "switch",
  DatePicker: "date",
  Tags: "tags",
  DateTimePicker: "datetime",
  Date: "date",
  Number: "number",
  Signature: Signature,
  DocList: List,
  Autocomplete: List,
  DocListWithCount: List,
  List: List,
  Range: "range",
  TextArea: "textarea",
  Textarea: "textarea",
  Select: "select",
  UserSelect: "select",
  Color: "color",
  TimeAgo: "timeago",
  NewDocList: DocList,
  File,
  ImageMeasure,
  CutIntensity,
  Dekubitus,
  WoundStatus,
  TextEditor,
  ShareDocuments,
  WoundGraduation,
  DiabeticFoot,
  Localisation,
  MaterialList,
  BarCode,
  LifeAndTreatment,
  Actions,
};

const CustomProps = {
  Boolean: { circleSize: 25 },
  Toggle: { circleSize: 25 },
  DatePicker: { circleSize: 22 },
  Signature: { direction: "column", alignItems: "flex-start", hidelabel: true },
  DocList: {
    direction: "column",
    alignItems: "flex-start",
    height: "auto",
    hidelabel: true,
  },
  DocListWithCount: {
    direction: "column",
    alignItems: "flex-start",
    height: "auto",
    hidelabel: true,
  },
  Localisation: {
    hidelabel: true,
  },
  DiabeticFoot: {
    hidelabel: true,
  },
  Dekubitus: {
    hidelabel: true,
  },
  WoundStatus: {
    hidelabel: true,
  },
  WoundGraduation: {
    hidelabel: true,
  },
  DateTimePicker: { time: true },
};

export default memo(
  ({
    value,
    doc,
    inputKey,
    label,
    desc,
    onChange,
    height,
    backgroundColor,
    placeholder,
    required,
    input,
    navigation,
    options,
    multiple,
    visible,
    user,
    min,
    max,
    ticks,
    editable,
    inputOptions = {},
    onlyVal = false,
    mode,
    ...rest
  }) => {
    const InputType = Inputs[input] || "text";
    const InputProps = CustomProps[input] || {};
    const inputO = isFunction(inputOptions)
      ? inputOptions({ doc, mode, navigation })
      : inputOptions;

    if (typeof InputType !== "string") {
      return (
        <Input
          label={inputO?.hidelabel ? undefined : label}
          required={required}
        >
          <InputType
            type={InputType}
            label={label}
            value={value}
            inputKey={inputKey}
            doc={doc}
            placeholder={placeholder}
            onChange={(value, key, autoSave) => {
              if (key) {
                onChange(key, value, autoSave);
              } else {
                onChange(inputKey, value, autoSave);
              }
            }}
            inputType={input}
            required={required}
            navigation={navigation}
            options={options}
            multiple={multiple}
            user={user}
            min={min}
            max={max}
            ticks={ticks}
            mode={mode}
            style={{
              marginBottom: 1,
            }}
            {...InputProps}
            {...inputO}
            {...rest}
          />
        </Input>
      );
    }

    return (
      <Input
        type={InputType}
        label={label}
        value={value}
        placeholder={placeholder}
        onChange={(value, key, autoSave) => {
          if (onlyVal) {
            onChange(value);
          } else {
            if (key) {
              onChange(key, value, autoSave);
            } else {
              onChange(inputKey, value, autoSave);
            }
          }
        }}
        required={required}
        options={options}
        multiple={multiple}
        min={min}
        max={max}
        ticks={ticks}
        editable={editable}
        mode={mode}
        style={{
          marginBottom: 1,
          marginTop: InputType === "switch" ? 10 : 0,
          paddingLeft: visible ? 25 : 0,
        }}
        {...inputO}
        {...rest}
      />
    );
  },
  arePropsEqual
);

function arePropsEqual(prevProps, nextProps) {
  if (["ImageMeasure"].indexOf(nextProps.input) > -1) {
    return false;
  } else if (
    [
      "refereneLength",
      "images",
      "materials",
      "BMI",
      "materialIds",
      "contactIDs",
    ].indexOf(nextProps.inputKey) > -1
  ) {
    return false;
  } else if (Array.isArray(nextProps.value)) {
    return false;
  } else if (prevProps.visible !== nextProps.visible) {
    return false;
  } else if (prevProps.value !== nextProps.value) {
    return false;
  }
  return true;
}
