import React, { Fragment, useState } from "react";
import { Platform } from "react-native";
import { Flex, useTheme, Input, Text, Form, getObjValue } from "unikit";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import Signature from "./Signature";
import List from "./List";
import File from "./File";
import ImageMeasure from "./ImageMeasure";
import CutIntensity from "./CutIntensity";
import Dekubitus from "./Dekubitus";
import WoundStatus from "./WoundStatus";
import WoundGraduation from "./WoundGraduation";
import DiabeticFoot from "./DiabeticFoot";
import Localisation from "./Localisation";
import MaterialList from "./MaterialList";
import BarCode from "./BarCode";
import DocList from "./DocList";

import TextEditor from "./TextEditor";
import ShareDocuments from "./ShareDocuments";

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

const Inputs = {
  Text: "text",
  Boolean: "switch",
  Toggle: "switch",
  DatePicker: "date",
  Tags: "tags",
  DateTimePicker: "datetime",
  Date: "date",
  Number: "number",
  MultiSelect: "multiselect",
  Signature: Signature,
  DocList: List,
  Autocomplete: List,
  DocListWithCount: List,
  NewDocList: DocList,
  Range: "range",
  TextArea: "textarea",
  Select: "select",
  UserSelect: "select",
  Color: "color",
  TimeAgo: "timeago",
  File: File,
  ImageMeasure: ImageMeasure,
  CutIntensity: CutIntensity,
  Dekubitus: Dekubitus,
  WoundStatus: WoundStatus,
  TextEditor: TextEditor,
  ShareDocuments: ShareDocuments,
  WoundGraduation: WoundGraduation,
  DiabeticFoot: DiabeticFoot,
  Localisation: Localisation,
  MaterialList: MaterialList,
  BarCode: BarCode,
};

export default ({
  schema,
  defaultDoc,
  renderSectionFooter,
  navigation,
  onSave,
  buttonText,
  renderFooter,
  renderHeader,
  loading,
  ...rest
}) => {
  const [doc, setDoc] = useState(defaultDoc || {});
  const theme = useTheme();

  const ScrollComp = Platform.OS !== "web" ? KeyboardAwareScrollView : Fragment;

  return (
    <ScrollComp enableOnAndroid={Platform.OS === "android"}>
      <Flex w="100%" px={15} mt={5}>
        {renderHeader ? renderHeader() : undefined}
        <Form
          defaultDoc={defaultDoc}
          buttonLabel={buttonText || "Teilen"}
          buttonProps={{ m: 10, flex: 1, loading }}
          onSubmit={(doc) => {
            const errors = [];
            schema.map((item) => {
              if (!item.optional && !getObjValue(doc, item.key)) {
                errors.push(`${item.key} is required`);
              }
            });
            if (errors.length > 0) {
              theme.alert({ type: "error", message: errors[0] });
            } else {
              onSave(doc, setDoc);
            }
          }}
          {...rest}
        >
          {schema.map(
            (
              {
                key,
                label,
                visible,
                input,
                listed,
                required,
                inputOptions,
                ...rest
              },
              index
            ) => {
              if (listed === false) return null;
              if (visible) {
                if (isFunction(visible) && visible({ doc }) !== true) {
                  return null;
                } else if (visible && doc[visible] !== true) {
                  return null;
                }
              }
              const InputComp = Inputs[input] || Input;
              if (typeof InputComp === "string") {
                return (
                  <Input
                    key={`form-input-${index}`}
                    type={InputComp}
                    field={key}
                    navigation={navigation}
                    paddingLeft={visible ? 15 : 0}
                    label={label}
                    required={required}
                    mt={1}
                    {...inputOptions}
                    {...rest}
                  />
                );
              }
              return (
                <Input label={`${label}${required ? "*" : ""}`}>
                  <InputComp
                    key={`form-input-${index}`}
                    field={key}
                    inputKey={key}
                    navigation={navigation}
                    paddingLeft={visible ? 15 : 0}
                    {...inputOptions}
                    {...rest}
                  />
                </Input>
              );
            }
          )}
        </Form>
        {renderFooter ? renderFooter() : undefined}
        {renderSectionFooter ? renderSectionFooter : undefined}
      </Flex>
    </ScrollComp>
  );
};
