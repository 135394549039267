import SimpleSchema from "../simple";

const schema = {
  surveyDate: {
    type: Date,
    label: "Orderdatum",
    defaultValue: function () {
      return new Date();
    },
    group: "Allgemein",
    input: "DatePicker",
    listedPDF: false,
  },
  name: {
    type: String,
    label: "Ordername",
    group: "Allgemein",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        const field = this.siblingField("companyId");
        const user = Meteor.users.findOne({ companyId: field.value });
        const warehouseStartNumber = user.warehouseStartNumber || 0;
        var count =
          warehouseStartNumber +
          Orders.find({
            companyId: field.value,
          }).count() +
          1;
        return `Bestellung Nr. ${count}`;
      }
    },
    optional: true,
    listed: false,
    listedPDF: false,
  },
  contactId: {
    type: String,
    label: "Lieferant",
    input: "NewDocList",
    optional: false,
    group: "Lieferant",
    inputOptions: ({ doc }) => ({
      hidelabel: true,
      type: "Contact",
      allowSearch: doc.send ? false : true,
      allowCreate: doc.send ? false : true,
      allowEdit: doc.send ? false : true,
      allowRemove: false,
      full: false,
      type: "Contact",
      multiple: false,
      title: (item) => {
        return `${item.name}, ${item.typ}`;
      },
      desc: (item) => {
        return `Telefon: ${item.phone}, Fax: ${item.fax} - ${item.address}, ${item.zipCode} ${item.residence}`;
      },
    }),
    listedPDF: false,
  },
  note: {
    type: String,
    label: "Bemerkung an Lieferant",
    optional: true,
    max: 1000,
    group: "Lieferant",
    listedPDF: false,
  },
  materials: {
    type: Array,
    optional: true,
    blackbox: true,
    input: "DocListWithCount",
    label: "Materialien",
    group: "Materialien",
    defaultValue: function () {
      return [];
    },
    inputOptions: ({ doc, navigation }) => ({
      disabled: doc.send,
      hidelabel: true,
      showProgress: true,
      previewFilter: {
        inStock: { $ne: null, $lte: 10 },
        contactId: { $ne: null },
      },
      footerActions: [
        ...(doc.send && !doc.booked
          ? [
              {
                icon: "refreshCCW",
                label: doc.bookedCount > 0 ? "Rest buchen" : "Alle Buchen",
                onPress: () => {
                  navigation.navigate("Change", {
                    title: "Wareneingang",
                    orderId: doc._id,
                    onSuccess: (navigation) => {
                      navigation.navigate("Receipt");
                    },
                    defaultDoc: {
                      materials: doc.materials.reduce((acc, m) => {
                        const count = m.count - (m.bookedCount || 0);
                        if (count > 0) {
                          acc.push({
                            ...m,
                            count,
                          });
                        }
                        return acc;
                      }, []),
                      warehouseToId: "warehouse",
                    },
                  });
                },
              },
            ]
          : []),
      ],
      tableFooter: [
        {
          value: `${doc.materials
            .reduce((acc, item) => {
              if (item && item.purchasingPrice) {
                console.log({ item });
                const discount = item.discount || doc.contact?.discount || 0;
                const discountMulti = discount / 100;
                const total = item.count * item.purchasingPrice;
                const totalDiscounted = total - total * discountMulti;
                acc = acc + totalDiscounted;
              }
              console.log({ doc });

              return acc;
            }, 0)
            .toFixed(2)}€`,
          label: "Gesamtbetrag (Netto)",
        },
      ],
      tableSchema: [
        {
          name: "Menge",
          width: 60,
          formatValue: ({ value, item }) => `${value}x`,
          key: "count",
        },
        {
          name: "PZN\nBestellnummer",
          width: "*",
          key: "materialNumber",
          formatValue: ({ value, item }) =>
            `${value}\n${item.orderNumber || ""}`,
        },
        {
          name: "Hersteller\nName",
          width: "*",
          key: "producer",
          formatValue: ({ value, item }) => `${value}\n${item.name || ""}`,
        },
        {
          name: "Größe\nVerpackungseinheit",
          width: "*",
          formatValue: ({ value, item }) =>
            `${value}\n${item.packagingUnit || ""}`,
          key: "size",
        },
        {
          name: "Preis\nRabatt\nGesamt",
          width: 80,
          formatValue: ({ value, item }) => {
            const discount = item.discount || doc.contact?.discount || 0;
            const discountMulti = discount / 100;
            const total = item.count * item.purchasingPrice;
            const totalDiscounted = total - total * discountMulti;
            console.log({ total, discountMulti });
            return `${value}€\n${discount}%\n${totalDiscounted.toFixed(2)}€`;
          },
          key: "purchasingPrice",
        },
      ],
      filter: (doc) => (doc.contactId ? { contactId: doc.contactId } : {}),
      actions: ({ doc, item, navigation }) =>
        doc._id && doc.send && item.count - (item.bookedCount || 0) > 0
          ? [
              {
                label: "Buchen",
                onPress: () => {
                  navigation.navigate("Change", {
                    title: "Wareneingang",
                    orderId: doc._id,
                    onSuccess: (navigation) => {
                      navigation.navigate("Receipt");
                    },
                    defaultDoc: {
                      materials: [
                        {
                          ...item,
                          count: Math.abs(item.count - (item.bookedCount || 0)),
                          bookedCount: null,
                        },
                      ],
                      warehouseToId: "warehouse",
                    },
                  });
                },
              },
            ]
          : [],
    }),
  },
  "materials.$": {
    type: Object,
    listed: false,
    blackbox: true,
  },
  noteBooked: {
    type: String,
    label: "Bemerkung Wareneingang",
    optional: true,
    max: 1000,
    listedPDF: false,
    group: "Weiteres",
  },
  send: {
    type: Boolean,
    label: "Gesendet",
    optional: true,
    input: "Toggle",
    group: "Weiteres",
    defaultValue: function () {
      return false;
    },
    listedPDF: false,
  },
  incomplete: {
    type: Boolean,
    label: "Unvollständig",
    optional: true,
    group: "Weiteres",
    listed: "update",
    listedPDF: false,
    input: "Toggle",
  },
  deliveryNoteImageIds: {
    type: Array,
    label: "Lieferschein",
    input: "File",
    optional: true,
    defaultValue: function () {
      return [];
    },
    multiple: true,
    group: "Weiteres",
    listed: "update",
    listedPDF: false,
  },
  "deliveryNoteImageIds.$": {
    type: String,
  },
  invoiceImageIds: {
    type: Array,
    label: "Rechnung",
    input: "File",
    optional: true,
    defaultValue: function () {
      return [];
    },
    multiple: true,
    group: "Weiteres",
    listed: "update",
    listedPDF: false,
  },
  "invoiceImageIds.$": {
    type: String,
  },
  otherIds: {
    type: Array,
    label: "Sonsitge Dokumente",
    input: "File",
    optional: true,
    defaultValue: function () {
      return [];
    },
    multiple: true,
    group: "Weiteres",
    listed: "update",
    listedPDF: false,
  },
  "otherIds.$": {
    type: String,
  },
  actions: {
    type: String,
    listed: "update",
    label: "Aktionen zur Order",
    optional: true,
    input: "Actions",
    group: "Weiteres",
    inputOptions: ({ doc }) => ({
      filter: {
        orderId: doc._id,
      },
    }),
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  booked: {
    type: Boolean,
    label: "Alles gebucht?",
    optional: true,
    listed: false,
  },
  bookedCount: {
    type: Number,
    label: "Gebuchte Materialien",
    optional: true,
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  canceled: {
    type: Boolean,
    label: "Canceled",
    optional: true,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Orders = new Mongo.Collection("orders");
  Orders.schema = simpleSchema;
  Orders.attachSchema(simpleSchema);
  Orders._ensureIndex({ companyId: 1 });
}

export { simpleSchema as OrderSchema };

export default schema;
