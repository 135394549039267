import * as React from "react";
import { ScrollView } from "react-native";
import { Grid, Chart, Text, Flex } from "unikit";

import { Page } from "../../components";
import { useAppContext } from "../../AppContext";

export default function Stats() {
  const { user } = useAppContext();
  const stats = user.stats || {};
  return (
    <Page title="Statistiken" scrollable>
      <Grid gap={10} min={400} maxCols={2} outerGap={15}>
        <Flex bg="surface" shadow={5} borderRadius={5} p={10}>
          <Text font="h5">{`${stats.patients || 0} Patienten`}</Text>

          <ScrollView showsHorizontalScrollIndicator={false} horizontal>
            <Chart
              width={400}
              height={250}
              data={
                stats.patientsData
                  ? stats.patientsData.map((data) => ({
                      label: data.name,
                      value: data.count,
                    }))
                  : []
              }
              yAxis
              yAxisFont="p"
              yTicks={4}
              xAxis
              useScaleBand
            >
              <Chart.Bar
                dataKey="value"
                valueFont="label"
                valueColor="#FFF"
                showValue
              />
            </Chart>
          </ScrollView>
        </Flex>

        <Flex bg="surface" shadow={5} borderRadius={5} p={10}>
          <Text font="h5">{`${stats.therapys || 0} Wunden`}</Text>

          <ScrollView showsHorizontalScrollIndicator={false} horizontal>
            <Chart
              width={800}
              height={250}
              data={
                stats.therapysData
                  ? stats.therapysData.map((data) => ({
                      label: data.name,
                      value: data.count,
                    }))
                  : []
              }
              yAxis
              yTicks={4}
              xAxis
              useScaleBand
            >
              <Chart.Bar
                dataKey="value"
                valueFont="label"
                valueColor="#FFF"
                showValue
              />
            </Chart>
          </ScrollView>
        </Flex>

        {/* <LineChart
          title={`${stats.therapys || 0} Wunden`}
          data={
            stats.therapysData
              ? stats.therapysData.map(data => ({
                  label: data.name,
                  value: data.count
                }))
              : []
          }
          minBarWidth={80}
          maxBarWidth={80}
          scrollable
        /> */}
      </Grid>
    </Page>
  );
}
