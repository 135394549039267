import React, { useState, Fragment, useEffect } from "react";
import { Button, Group, styled, Text } from "unikit";
import { Platform, Linking } from "react-native";

import { MATERIALS_QUERY, CONTACTS_QUERY } from "../../api/queries";

import Listitem from "../ListItem";
import ListViewQuery from "../ListViewQuery";
import i18n from "../../I18n";

const Wrapper = styled.View({});

const types = {
  materialIds: {
    query: MATERIALS_QUERY,
    typename: "Material",
    searchType: "materialSingle",
    title: (item, options) =>
      `${options.full ? `${item.producer}, ${item.name}, ` : ""}Größe: ${
        item.size
      }, Verpackungseinheit: ${item.packagingUnit}`,
    desc: (item) => `PZN: ${item.materialNumber}`,
    defaultValues: (doc) => ({
      name: doc.name,
      producer: doc.producer,
      category: doc.category,
      note: doc.note,
      groupId: doc._id,
    }),
  },
  contactId: {
    query: CONTACTS_QUERY,
    typename: "Contact",
    searchType: "contacts",
    title: (item) => {
      return `${item.name}, ${item.typ}`;
    },
    desc: (item) => {
      return `Telefon: ${item.phone || "-"}, Fax: ${
        item.fax || "-"
      }, Anschrift: ${item.address || "-"}, ${item.zipCode || "-"} ${
        item.residence || "-"
      }`;
    },
    defaultValues: (doc) => ({}),
  },
  contactIDs: {
    query: CONTACTS_QUERY,
    typename: "Contact",
    searchType: "contacts",
    title: (item) => {
      return `${item.name}, ${item.typ}`;
    },
    desc: (item) => {
      return `Telefon: ${item.phone || "-"}, Fax: ${item.fax || "-"}`;
    },
    subdesc: (item) => {
      return `Anschrift: ${item.address}, ${item.zipCode} ${item.residence}`;
    },
    defaultValues: (doc) => ({}),
    actions: (item) => [
      ...(item.phone
        ? [
            {
              icon: "phone",
              label: "Anrufen...",
              onPress: () => {
                Linking.openURL(`tel:${item.phone}`);
              },
            },
          ]
        : []),
      ...(item.email
        ? [
            {
              icon: "mail",
              label: "E-Mail...",
              onPress: () => {
                Linking.openURL(`mailto:${item.email}`);
              },
            },
          ]
        : []),
    ],
  },
};

export default ({
  value = [],
  inputKey,
  onChange,
  navigation,
  doc,
  multiple = true,
  options = {
    allowSearch: false,
    allowCreate: true,
    allowEdit: true,
    full: false,
    type: undefined,
  },
}) => {
  console.log({ multiple, value, inputKey });
  if (multiple === false && value) {
    value = [value];
  } else {
    value = value === undefined || value === null ? [] : value;
  }

  const type = types[inputKey];

  if (options.type === "MaterialGroup" && !doc._id) {
    return (
      <Wrapper
        flex={Platform.OS !== "web" ? 0 : undefined}
        w="100%"
        flexCenter
        py={10}
      >
        <Text>
          Varienten können erst nach dem speichern hinzugefügt werden.
        </Text>
      </Wrapper>
    );
  }

  console.log({ multiple, value });
  return (
    <Wrapper f={Platform.OS !== "web" ? 0 : undefined} w="100%">
      {multiple === false && value.length === 0 ? null : (
        <ListViewQuery
          query={type.query}
          filter={
            options.type === "MaterialGroup"
              ? { groupId: doc._id }
              : { _id: { $in: value || [] } }
          }
          withDeleted={options.type !== "MaterialGroup"}
          name={type.typename.toLowerCase() + "s"}
          renderItem={({ item, index }) => {
            console.log({ item });
            return (
              <Listitem
                title={type.title(item, options)}
                desc={type.desc(item)}
                subdesc={type?.subdesc?.(item)}
                onPress={() => {
                  if (options.allowEdit) {
                    navigation.push("Form", {
                      title: item.name,
                      type: type.typename,
                      mode: "update",
                      data: item,
                      id: item._id,
                      onSuccess: (navigation, doc, mode) => {
                        if (mode === "delete") {
                          let newValue = value;
                          onChange(newValue.filter((v) => v !== doc._id));
                        }
                        navigation.goBack();
                      },
                    });
                  }
                }}
                pills={
                  item.deleted
                    ? [{ color: "error", label: "gelöscht" }]
                    : undefined
                }
                style={{
                  width: "100%",
                  marginBottom: 10,
                  borderRadius: 10,
                }}
                wrapperStyle={{ width: "100%" }}
                actions={
                  options.allowSearch
                    ? [
                        ...(type.actions ? type.actions(item) : []),
                        {
                          icon: "trash",
                          label: "Aus Liste löschen",
                          onPress: () => {
                            let newValue = value;
                            onChange(newValue.filter((v) => v !== item._id));
                          },
                        },
                      ]
                    : type.actions
                    ? type.actions(item)
                    : undefined
                }
              />
            );
          }}
        />
      )}
      <Group style={{ marginBottom: 8 }}>
        {options.allowSearch ? (
          <Button
            width="100%"
            light
            onPress={() => {
              navigation.push("Search", {
                type: type.searchType,
                onSelect: (item, goBack) => {
                  if (typeof list === "object") {
                    var found = value.filter((e) => e && e._id === item._id);
                    if (found.length > 0) {
                      alert("Schon in Liste vorhanden");
                      return;
                    }
                  }
                  if (multiple === false) {
                    var newValue = doc._id;
                  } else {
                    var newValue = value || [];
                    newValue.push(item._id);
                    newValue = newValue.filter((v) => v !== null);
                  }

                  onChange(newValue);

                  goBack();
                },
              });
            }}
          >
            {`${i18n.t(`types.${type.typename.toLowerCase()}`)} suchen`}
          </Button>
        ) : null}
        {options.allowCreate && !doc.copyId ? (
          <Button
            width="100%"
            light
            onPress={() => {
              navigation.push("Form", {
                title: `${i18n.t(
                  `types.${type.typename.toLowerCase()}`
                )} hinzufügen`,
                type: type.typename,
                mode: "insert",
                defaultValues: type.defaultValues(doc),
                query: {
                  query: type.query,
                  variables: {
                    filter: { groupId: doc._id },
                  },
                },
                onSuccess: (navigation, doc) => {
                  if (multiple === false) {
                    var newValue = doc._id;
                  } else {
                    var newValue = value || [];
                    newValue.push(doc._id);
                  }
                  onChange(newValue);
                  navigation.goBack();
                },
              });
            }}
          >
            {`${i18n.t(`types.${type.typename.toLowerCase()}`)} hinzufügen`}
          </Button>
        ) : null}
      </Group>
    </Wrapper>
  );
};
