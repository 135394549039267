import SimpleSchema from "./simple";

const schema = {
  ulcusCruris: {
    type: Boolean,
    label: "Ulcus Cruris",
    optional: true,
    input: "Toggle",
    group: "Wundart",
  },
  venosum: {
    type: Boolean,
    label: "Venosum",
    optional: true,
    input: "Toggle",
    group: "Wundart",
    visible: "ulcusCruris",
  },
  arteriosum: {
    type: Boolean,
    label: "Arteriosum",
    optional: true,
    input: "Toggle",
    group: "Wundart",
    visible: "ulcusCruris",
  },
  mixtum: {
    type: Boolean,
    label: "Mixtum",
    optional: true,
    input: "Toggle",
    group: "Wundart",
    visible: "ulcusCruris",
  },
  decubitus: {
    type: Boolean,
    label: "Dekubitus",
    optional: true,
    input: "Toggle",
    group: "Wundart",
  },
  secondaryWoundHealing: {
    type: Boolean,
    label: "Sekundäre Wundheilung",
    optional: true,
    input: "Toggle",
    group: "Wundart",
  },
  diabeticFoot: {
    type: Boolean,
    label: "Diabetischer Fuß",
    optional: true,
    input: "Toggle",
    group: "Wundart",
  },
  traumatic: {
    type: Boolean,
    label: "Traumatisch",
    optional: true,
    input: "Toggle",
    group: "Wundart",
  },
  TUWound: {
    type: Boolean,
    label: "TU Wunde",
    optional: true,
    input: "Toggle",
    group: "Wundart",
  },
  burn: {
    type: Boolean,
    label: "Thermische Wunde / Verbrennung",
    optional: true,
    group: "Wundart",
    input: "Toggle",
  },
  woundTypeOther: {
    type: Boolean,
    label: "Andere",
    optional: true,
    group: "Wundart",
    input: "Toggle",
  },
  woundTypeOtherName: {
    type: String,
    label: "Name",
    max: 100,
    optional: true,
    group: "Wundart",
    visible: "woundTypeOther",
  },
  woundLocalisation: {
    type: String,
    label: "Lokalisation",
    optional: true,
    group: "Wundart",
  },
  healed: {
    type: Boolean,
    label: "Geheilt?",
    optional: true,
    input: "Toggle",
    group: "Wundart",
  },
  patientId: {
    type: String,
    label: "PatientId",
    visible: "false",
    listed: false,
    defaultValue: (props) => {
      return props.patientId;
    },
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Therapys = new Mongo.Collection("therapys");
  Therapys.schema = simpleSchema;
  Therapys.attachSchema(simpleSchema);
  Therapys._ensureIndex({ companyId: 1 });
  Therapys._ensureIndex({ patientId: 1 });
}

export { simpleSchema as TherapySchema };

export default schema;
