import * as React from "react";
import { Flex, Text } from "unikit";
import dayjs from "dayjs";

import { Page, ListItem, ListView } from "../../components";

export default function ShareScreen({ navigation, route }) {
  return (
    <Page title="Geteilte Patienten">
      <ListView
        data={route.params.share.documents}
        renderHeader={() => {
          return (
            <Flex p={15} bg="surface">
              <Text style={{ fontSize: 18, marginBottom: 8 }}>Hinweis</Text>
              <Text>{route.params.share.note}</Text>
            </Flex>
          );
        }}
        renderItem={({ item, index }) => (
          <ListItem
            title={`${item.name}`}
            desc={
              item.surveyDate
                ? `Erhebungsdatum ${dayjs(item.surveyDate).format(
                    "DD.MM.YYYY"
                  )}`
                : null
            }
            onPress={() => {
              navigation.navigate("PDF", {
                title: item.name,
                type: item.type,
                id: item._id,
                options: item.options,
              });
            }}
          />
        )}
      />
    </Page>
  );
}
