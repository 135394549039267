// @flow
import * as React from "react";
import Pdf from "@mikecousins/react-pdf";

export default function PDF({ source, pages = true, ...rest }) {
  return (
    <Pdf file={source.uri} page={1} {...rest}>
      {({ pdfDocument, pdfPage, canvas }) => (
        <>
          {!pdfDocument && <span>Loading...</span>}
          {canvas}
          {Boolean(pdfDocument && pdfDocument.numPages) && pages ? (
            <>
              {Array.from(Array(pdfDocument.numPages).keys()).map((i) => {
                return (
                  <>
                    <div style={{ width: "100%", height: 20 }} />
                    <Pdf key={`page-${2 + i}`} file={source.uri} page={2 + i} />
                  </>
                );
              })}
            </>
          ) : null}
        </>
      )}
    </Pdf>
  );
}
