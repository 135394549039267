import React, { Fragment, useState, useEffect, useRef } from "react";
import { Platform } from "react-native";
import { Flex, Text, Button, useTheme } from "unikit";
import { useQuery } from "react-apollo";

import { MATERIALS_QUERY, CONTACTS_QUERY } from "../api/queries";
import ListView from "./ListView";
import Icon from "./Icon";
import i18n from "../I18n";

const QUERIES = {
  Contact: CONTACTS_QUERY,
  Material: MATERIALS_QUERY,
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default ({
  title,
  titleProps,
  searchData,
  query,
  filter = {},
  subFilter = {},
  sort = {},
  name,
  limit = 25,
  addTitle = "",
  onAdd,
  typename,
  horizontal = false,
  onLoad,
  action = null,
  withDeleted = false,
  ...rest
}) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [refreshing, setRefresh] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [index, setIndex] = useState(0);
  const listRef = useRef(null);
  const { loading, error, data, fetchMore, refetch } = useQuery(
    query || QUERIES[typename],
    {
      variables: {
        filter: filter,
        sort: sort,
        page: 0,
        limit: limit || undefined,
        ...(withDeleted ? { withDeleted: true } : {}),
        ...subFilter,
      },
    }
  );

  //console.log({ filter, data });

  const prevFilter = usePrevious(JSON.stringify(filter));

  useEffect(() => {
    if (loading === false && data && data[name] && onLoad) {
      onLoad({ name, data: data[name] });
    }
  }, [loading, data]);

  useEffect(() => {
    if (prevFilter !== JSON.stringify(filter)) {
      setPage(0);
      refetch();
    }
  }, [filter]);

  const getData = (da) => {
    if (withDeleted) return da;
    return da.filter((d) => d.deleted !== true);
  };

  const listData = searchData
    ? searchData
    : data
    ? getData(data[name] || [])
    : [];

  const onLoadMoreTriggert = () => {
    setLoadMore(true);
    let newPage = page + 1;

    setPage(newPage);
    fetchMore({
      variables: {
        filter: filter,
        sort: sort,
        page: newPage,
        limit: limit || undefined,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoadMore(false);
        if (!fetchMoreResult || !fetchMoreResult[name]) return prev;
        return Object.assign({}, prev, {
          [name]: [...prev[name], ...fetchMoreResult[name]],
        });
      },
    });
  };

  return (
    <Fragment>
      {title && !typename ? (
        <Flex
          px={horizontal ? 15 : 10}
          pb={5}
          pt={10}
          row
          content="space-between"
          align="center"
        >
          <Text font="h2" mt={5} ml={5} {...titleProps}>
            {title}
          </Text>
        </Flex>
      ) : null}
      {typename ? (
        <Flex
          px={15}
          pb={5}
          pt={10}
          row
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex row>
            <Text font="h4" mt={5} ml={5} {...titleProps}>
              {title || i18n.t(`types.${typename.toLowerCase()}_plural`)}
            </Text>
            {Platform.OS === "web" ? (
              <Flex w={60} ml={10} content="space-between" row>
                <Icon
                  name="chevronLeft"
                  onPress={() => {
                    let newIndex = index > 0 ? index - 1 : 0;
                    setIndex(newIndex);
                    listRef.current.scrollTo({ index: newIndex });
                  }}
                />
                <Icon
                  name="chevronRight"
                  onPress={() => {
                    let newIndex = (index + 1) % listData.length;
                    setIndex(newIndex);
                    listRef.current.scrollTo({ index: newIndex });
                  }}
                />
              </Flex>
            ) : null}
          </Flex>
          <Flex row>
            {action}
            {onAdd ? (
              <Button
                size={44}
                renderRight={
                  <Icon
                    name="plus"
                    color="#FFF"
                    style={{ marginLeft: theme.width < 768 ? 0 : 10 }}
                    size={20}
                  />
                }
                onPress={() => onAdd(data ? data[name] : [], refetch)}
                style={{ borderRadius: 22 }}
              >
                {theme.width < 768 ? "" : `hinzufügen`}
              </Button>
            ) : null}
          </Flex>
        </Flex>
      ) : null}

      <ListView
        ref={listRef}
        data={listData}
        loading={loading}
        limit={page * limit}
        refreshing={refreshing}
        loadMore={loadMore}
        style={horizontal ? { padding: 7.5 } : {}}
        horizontal={horizontal}
        refetch={refetch}
        noContentText={
          typename
            ? `Noch keine ${
                title || i18n.t(`types.${typename.toLowerCase()}_plural`)
              } vorhanden`
            : undefined
        }
        renderFooter={
          horizontal && listData.length >= page * limit
            ? () => (
                <Flex flex={1} flexCenter px={25}>
                  <Button
                    onPress={() => {
                      onLoadMoreTriggert();
                    }}
                    loading={loadMore}
                    clean
                  >
                    Mehr laden
                  </Button>
                </Flex>
              )
            : rest.renderFooter || undefined
        }
        onRefresh={() => {
          setRefresh(true);
          setPage(0);
          refetch()
            .then(() => {
              setRefresh(false);
            })
            .catch((error) => {
              console.log(error);
            });
        }}
        fetchMore={() => {
          if (listData.length >= page * limit) {
            onLoadMoreTriggert();
          }
        }}
        {...rest}
      />
    </Fragment>
  );
};
