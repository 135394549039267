import * as React from "react";
import gql from "graphql-tag";
import { Flex } from "unikit";
import dayjs from "dayjs";

import { Page, ListViewQuery, ListItem, Icon } from "../../components";
import { useAppContext } from "../../AppContext";

const CONTACTS_QUERY = gql`
  query contacts($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    contacts(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "contacts", filter: ["filter"]) {
      _id
      typ
      name
      address
      zipCode
      residence
      phone
      mobile
      fax
      email
      note
      updatedAt
      deleted
    }
  }
`;

export default function ContactsScreen({ navigation }) {
  const { user } = useAppContext();
  return (
    <Page
      title="Kontakte"
      headerRight={() => (
        <Flex row alignItems="center">
          <Icon
            name="search"
            color="#FFF"
            size={23}
            onPress={() =>
              navigation.navigate("Search", {
                type: "contacts",
              })
            }
          />
          <Icon
            name="plus"
            color="#FFF"
            onPress={() =>
              navigation.navigate("Form", {
                title: "Neuer Kontakt",
                type: "Contact",
                mode: "insert",
                query: {
                  query: CONTACTS_QUERY,
                  variables: {
                    filter: { companyId: user.companyId },
                    sort: { name: 1 },
                  },
                },
              })
            }
          />
        </Flex>
      )}
    >
      <ListViewQuery
        query={CONTACTS_QUERY}
        filter={{ companyId: user.companyId }}
        sort={{ name: 1 }}
        name="contacts"
        groupBy="typ"
        renderItem={({ item, index }) => (
          <ListItem
            title={`${item.name}`}
            desc={`Bearbeitet am ${dayjs(item.updatedAt).format(
              "DD. MMMM YYYY"
            )}`}
            onPress={() => {
              navigation.navigate("Form", {
                title: "Kontakt bearbeiten",
                data: item,
                id: item._id,
                type: "Contact",
                mode: "update",
              });
            }}
          />
        )}
      />
    </Page>
  );
}
