import React, { Fragment } from "react";
import { Platform, Alert } from "react-native";
import dayjs from "dayjs";
import {
  useTheme,
  Button,
  Flex,
  isWeb,
  setObjValue,
  getObjValue,
  Input as UniInput,
} from "unikit";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";

import getSchemaByType, { SimpleSchema } from "../clouddoku/lib/schemas";

import { Page, Input, ListView, Loading, Icon } from "../components";

import i18n from "../I18n";
import { useAppContext } from "../AppContext";

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

var mongoObjectId = function () {
  var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};

export const getSchema = ({ route, user, inRole }) => {
  let schema = getSchemaByType(route.params.type).schema || {};
  let roles = route.params.roles;
  let hiddenFields = route.params.hiddenFields || [];
  let schemaArray = [];
  let extenstion = route.params.schemaExtension || {};

  Object.keys(schema).map((key) => {
    const item = schema[key];
    var options = schema[key].options;
    if (item.input === "UserSelect") {
      options = [];
      user.team.map((member) => {
        if (member.banned !== true) {
          options.push({
            label: `${member.lastName}, ${member.firstName}`,
            value: member._id,
          });
        }
      });
      options.push({
        label: `${user.lastName}, ${user.firstName}`,
        value: user._id,
      });
    }
    //console.log("extenstion", extenstion[key]);
    if (extenstion[key] && extenstion[key].options) {
      options = extenstion[key].options;
    }

    var listed = item.listed;

    if (item.roles && roles) {
      var remove = inRole(item.roles, roles) ? false : true;
    }

    if (hiddenFields && hiddenFields.indexOf(key) > -1) {
      var remove = true;
    }

    if (key.indexOf("$") > -1) {
      var remove = true;
    }

    if (schemaArray.find((item) => item.key === key.split(".")[0])) {
      var remove = true;
    }

    if (remove !== true) {
      schemaArray.push({
        ...item,
        key: key === "emails" ? "emails.0.address" : key,
        label:
          item.label ||
          i18n.t(`form.${key === "emails[0].address" ? "email" : key}`),
        placeholder:
          item.placeholder ||
          item.label ||
          i18n.t(`form.${key === "emails[0].address" ? "email" : key}`),
        required: !item.optional,
        input: item.input || "String",
        listed: listed,
        options: options,
      });
    }
  });
  return schemaArray;
};

const calcBMI = (size, weight) => {
  var bmiState = undefined;
  var bmi =
    parseInt(weight) / (((parseInt(size) / 100) * parseInt(size)) / 100);
  if (bmi < 20) {
    bmiState = "untergewicht";
  } else if (bmi > 20 && bmi < 25) {
    bmiState = "normalgewicht";
  } else if (bmi > 25 && bmi < 29) {
    bmiState = "leichtes_übergewicht";
  } else if (bmi > 29 && bmi < 38) {
    bmiState = "adipositas";
  } else if (bmi > 38) {
    bmiState = "schwere_adipositas";
  }
  return bmiState;
};

const getDefaultValue = (defaultValue, defaultValues) => {
  if (defaultValue) {
    if (typeof defaultValue === "function") {
      return defaultValue(defaultValues);
    } else {
      return defaultValue;
    }
  }
  return undefined;
};

const defaultDoc = (fields, defaultValues) => {
  var defaultDoc = {};
  fields.map((item) => {
    defaultDoc[item.key] = getDefaultValue(item.defaultValue, defaultValues);
  });
  return Object.assign({}, defaultDoc);
};

export default function FormScreen({ route, navigation }) {
  const { onChangeDoc } = route?.params || {};
  const [days, setDays] = React.useState(7);
  const theme = useTheme();
  const client = useApolloClient();
  const { user, inRole, changeDoc } = useAppContext();
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const schema = React.useMemo(() => {
    return getSchema({ route, user, inRole });
  }, []);

  const [doc, setDoc] = React.useState(() => {
    if (route.params.mode === "insert") {
      var d = Object.assign(
        {},
        defaultDoc(schema, route.params.defaultValues),
        route.params.defaultValues
      );
    } else {
      var d = Object.assign({}, route.params.data, route.params.defaultValues);
    }

    // console.log({ doc });
    // var keys = "";
    // Object.keys(doc).map((key) => {
    //   keys += ` ${key}`;
    // });
    //console.log(keys);

    return d;
  });

  if (!schema) return null;

  const setNull = (doc) => {
    var newDoc = {};
    Object.keys(doc).map((key) => {
      if (getObjValue(doc, key) !== undefined) {
        newDoc[key] = getObjValue(doc, key);
      } else {
        newDoc[key] = null;
      }
    });
    return newDoc;
  };

  const submit = (autoSave) => {
    if (loading) return;

    setLoading(true);

    var schemaObj = getSchemaByType(route.params.type).schema;
    var simpleSchema = new SimpleSchema(schemaObj);

    const cleanedDoc = simpleSchema.clean(
      Object.assign(
        {},
        doc,
        route.params.type !== "User" && route.params.mode === "insert"
          ? {
              createdBy: user._id,
              companyId: user.companyId,
            }
          : {}
      ),
      { removeEmptyStrings: false }
    );

    if (route.params.mode === "insert") {
      const validationContext = simpleSchema.newContext();

      validationContext.validate(cleanedDoc);

      const errors = validationContext
        .validationErrors()
        .filter((e) => e.dataType !== "date" && e.type !== "expectedType");
      console.log({ errors });
      if (errors.length > 0) {
        setErrors(errors);
        const label = schemaObj[errors[0].name]
          ? schemaObj[errors[0].name].label
          : errors[0].name;
        theme.alert({
          type: "error",
          message: `${label} ist ein Pflichfeld`,
        });
        setLoading(false);
        return false;
      }
    }

    if (route.params.mode === "insert" && !cleanedDoc["_id"]) {
      cleanedDoc["_id"] = mongoObjectId();
    }

    const responseDoc = Object.assign(
      {},
      route.params.defaultValues,
      cleanedDoc,
      doc,
      {
        __typename: route.params.type,
        updatedAt: new Date(),
      }
    );

    console.log({ cleanedDoc, doc, responseDoc });

    if (route.params.id) {
      responseDoc["_id"] = route.params.id;
    }

    if (doc.canceled && doc.canceled === true) {
      theme.alert({
        type: "error",
        message: "error.doc_cancled",
      });
    }

    client
      .mutate({
        mutation: gql`
          mutation handleDoc($_id: ID, $doc: Object, $type: String!, $mode: String!) {
            handleDoc(_id: $_id, doc: $doc, type: $type, mode: $mode) {
              ... on ${route.params.type} {
                ${getSchemaByType(route.params.type).typeDef}
                ${route.params.ast || ""}
              }
            }
          }
        `,
        variables: {
          _id: route.params.id,
          doc: cleanedDoc,
          type: route.params.type,
          mode: route.params.mode,
        },
        refetchQueries: route.params.refetch
          ? [route.params.refetch]
          : undefined,
        optimisticResponse: {
          __typename: "Mutation",
          handleDoc: setNull(responseDoc),
        },
        update:
          route.params.mode === "update"
            ? undefined
            : (proxy, { data: { handleDoc = {} } }) => {
                var mutationResult = Object.assign(
                  {},
                  route.params.defaultValues,
                  handleDoc
                );
                // const mutationResult = handleDoc; // mutation result to pass into the updater
                if (route.params.mode === "insert") {
                  var data = proxy.readQuery(route.params.query);

                  const sorting = route.params.query.variables.sort;
                  data[
                    route.params.query.query.definitions[0].name.value
                  ].unshift(mutationResult);

                  if (sorting) {
                    data[
                      route.params.query.query.definitions[0].name.value
                    ].sort(function (a, b) {
                      if (
                        a[Object.keys(sorting)[0]] > b[Object.keys(sorting)[0]]
                      ) {
                        return Object.values(sorting)[0];
                      }
                      return 0;
                    });
                  }

                  //console.log({ data });

                  proxy.writeQuery({
                    ...route.params.query,
                    data,
                  });
                }
              },
      })
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          theme.alert({
            type: "error",
            message: result.errors[0].message || "Error",
          });
          setLoading(false);
        } else {
          theme.alert({ type: "success", message: i18n.t(`saved`) });
          console.log("result.data", result);

          if (route.params && route.params.onSuccess && !autoSave) {
            route.params.onSuccess(navigation, result.data.handleDoc);
            setLoading(false);
          } else if (!autoSave) {
            navigation.goBack();
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log({ error });
        theme.alert({
          type: "error",
          message: error.message
            ? error.message.replace("GraphQL error:", "")
            : "Error",
        });
        setLoading(false);
      });
  };

  const onChange = (key, value, autoSave) => {
    setDoc((d) => {
      d = setObjValue(d, key, value);
      if (key === "size" && d.weight) {
        d["BMI"] = calcBMI(value, d.weight);
      }
      if (key === "weight" && d.size) {
        d["BMI"] = calcBMI(d.size, value);
      }
      if (onChangeDoc) {
        d = onChangeDoc({ doc: d });
      }
      console.log(JSON.stringify(d));
      return { ...d };
    });
  };

  return (
    <Page
      id="page"
      title={route.params?.title || route.params?.type}
      gesturesEnabled={route.params?.type === "Anamnese" ? false : true}
      headerRight={() => (
        <Icon name="check" color="#FFF" size={25} onPress={() => submit()} />
      )}
    >
      <ListView
        data={schema}
        keyboardAware
        loading={false}
        groupBy="group"
        disableVirtualization={isWeb}
        renderItem={({ item, index }) => {
          if (
            item.listed === false ||
            (item.listed &&
              item.listed !== true &&
              item.listed !== route.params.mode)
          )
            return null;
          if (item.visible) {
            if (isFunction(item.visible) && item.visible({ doc }) !== true) {
              return null;
            } else if (
              !isFunction(item.visible) &&
              item.visible &&
              doc[item.visible] !== true
            ) {
              return null;
            }
          }

          return (
            <Input
              value={doc ? getObjValue(doc, item.key) : undefined}
              doc={doc}
              mode={route.params.mode}
              label={item.label}
              inputKey={item.key}
              field={item.key}
              onChange={onChange}
              required={item.required}
              input={item.input}
              options={item.options}
              placeholder={item.placeholder}
              navigation={navigation}
              visible={item.visible && !isFunction(item.visible)}
              multiple={item.multiple}
              min={item.min}
              max={item.max}
              ticks={item.ticks}
              user={user}
              editable={item.readonly ? false : true}
              inputOptions={item.inputOptions}
              error={errors?.find((e) => e.name === item.key) ? true : false}
            />
          );
        }}
        contentContainerStyle={{
          paddingHorizontal: 10,
        }}
        renderFooter={() => {
          var footerContent = null;
          if (
            route.params.type === "Appointment" &&
            doc.patientId &&
            route.params.mode === "update"
          ) {
            var footerContent = (
              <Fragment>
                <Button
                  light
                  onPress={() => {
                    navigation.navigate("Patient", {
                      _id: doc.patientId,
                      title: doc.patient,
                    });
                  }}
                >
                  Zum Patienten
                </Button>
                <Button
                  mt={5}
                  light
                  onPress={() => {
                    navigation.push("Form", {
                      title: "neuer Behandlungsnachweis",
                      type: "Evidence",
                      mode: "insert",
                      defaultValues: {
                        patientId: doc.patientId,
                      },
                    });
                  }}
                >
                  Neuer Behandlungsnachweis
                </Button>
                <Flex alignItems="flex-end" mt={5} row w="100%">
                  <UniInput
                    label={`Tage bis zum Folgetermin`}
                    type="number"
                    value={days}
                    onChange={(v) => setDays(v)}
                    style={{ flex: 1 }}
                    labelProps={{ pl: days > 9 ? 30 : 15, pt: 2 }}
                    inline
                  />
                  <Button
                    w={100}
                    onPress={() => {
                      const newEvent = {
                        ...doc,
                        datum: dayjs(doc.date).add(days, "days").toDate(),
                      };
                      navigation.replace("Form", {
                        title: "Folgetermin",
                        type: "Appointment",
                        mode: "insert",
                        defaultValues: newEvent,
                      });
                    }}
                    borderTopLeftRadius={0}
                    borderBottomLeftRadius={0}
                    size={55}
                    px={0}
                  >
                    Weiter
                  </Button>
                </Flex>
              </Fragment>
            );
          }

          return (
            <>
              <Flex py={10}>
                {footerContent}
                {route.params.mode === "update" &&
                [
                  "Appointment",
                  "MaterialGroup",
                  "Material",
                  "Contact",
                  "Order",
                ].indexOf(route.params.type) > -1 ? (
                  <Button
                    style={{ marginTop: 5 }}
                    bg="error"
                    light
                    onPress={() => {
                      const onConfirm = () => {
                        changeDoc({
                          id: route.params.id,
                          doc: doc,
                          type: route.params.type,
                          mode: "delete",
                          onSuccess: () => {
                            if (route.params.onSuccess) {
                              route.params.onSuccess(navigation, doc, "delete");
                            }
                            navigation.goBack();
                          },
                        });
                      };
                      if (Platform.OS === "web") {
                        if (window.confirm("Sind Sie sicher?")) {
                          onConfirm();
                        }
                      } else {
                        Alert.alert(
                          "Sind Sie sicher?",
                          "", //"Sie können das löschen unter Aktionen rückgängig machen",
                          [
                            {
                              text: "Abbrechen",
                              onPress: () => console.log("Cancel Pressed"),
                              style: "cancel",
                            },
                            {
                              text: "Ja",
                              onPress: () => {
                                onConfirm();
                              },
                            },
                          ],
                          { cancelable: false }
                        );
                      }
                    }}
                  >
                    Löschen
                  </Button>
                ) : null}
              </Flex>
              <Flex w="100%" h={300} />
            </>
          );
        }}
      />
      {loading ? (
        <Flex bg="background:setAlpha:0.8" zIndex={1000} absoluteFill>
          <Loading />
        </Flex>
      ) : null}
    </Page>
  );
}
