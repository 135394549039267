import React from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { useTheme, Button } from "unikit";

import i18n from "../../I18n";
import { Page, Form } from "../../components";
import { useAppContext } from "../../AppContext";

const SHARE_MUTATION = gql`
  mutation share($doc: Object!) {
    share(doc: $doc) {
      _id
    }
  }
`;

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export default function ShareScreen({ navigation }) {
  const { user, sharing, setSharing } = useAppContext();
  const [share, { loading }] = useMutation(SHARE_MUTATION);
  const theme = useTheme();

  const reset = () => {
    setSharing(
      {
        email: "",
        title: `${user.lastName}, ${user.firstName} möchte folgende Dokumente mit Ihnen teilen.`,
        note: "",
        documents: [],
        shareEnd: undefined,
        patientId: undefined,
        patientName: undefined,
      },
      true
    );
    navigation.goBack();
  };

  return (
    <Page scrollView>
      <Form
        navigation={navigation}
        defaultDoc={sharing}
        loading={loading}
        onSave={(doc) => {
          const { email, title, note, documents } = doc;

          if (!validateEmail(email)) {
            theme.alert({ type: "error", message: "E-Mail nicht valide" });
            return false;
          }
          const shareDoc = {
            documents: documents,
            title: title,
            note: note,
            email: email.toLowerCase(),
            patientId: sharing.patientId,
            patientName: sharing.patientName,
          };
          share({
            variables: {
              doc: shareDoc,
            },
          })
            .then((result) => {
              theme.alert({ type: "success", message: i18n.t(`send`) });
              reset();
            })
            .catch((error) => {
              console.log({ error });
              theme.alert({
                type: "error",
                message: error.message || "Error",
              });
            });
        }}
        schema={[
          {
            key: "email",
            label: "E-Mail",
            placeholder: "E-Mail",
            input: "Autocomplete",
            required: true,
            group: "Allgemein",
            inputOptions: {
              keyboardType: "email-address",
              autoCapitalize: "none",
              autoCompleteType: "email",
            },
          },
          {
            key: "title",
            label: "Titel",
            placeholder: "Titel",
            required: true,
            input: "TextArea",
            group: "Allgemein",
          },
          {
            key: "note",
            label: "Hinweis",
            placeholder: "Hinweis",
            input: "TextArea",
            optional: true,
            group: "Allgemein",
          },
          {
            key: "documents",
            label: "Dokumente",
            placeholder: "Dokumente",
            required: true,
            input: "ShareDocuments",
            group: "Allgemein",
          },
        ]}
        renderFooter={() => (
          <Button style={{ marginTop: 10 }} onPress={() => reset()} light>
            Abbrechen
          </Button>
        )}
      />
    </Page>
  );
}
