import React, { useRef } from "react";
import AsyncStorage from "@react-native-community/async-storage";
import { Platform, StatusBar, useColorScheme } from "react-native";
import { ThemeProvider, color, isWeb, isAndroid, Alert } from "unikit";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";
import * as Haptics from "expo-haptics";

import Updates from "./Updates";
import Push from "./Push";
import LocalAuth from "./LocalAuth";
import { GlobalInfo } from "./components";
import AppNavigation from "./AppNavigation";
import { useAppContext } from "./AppContext";

// const AppNavigation = withApollo(({ currentUser, loading, client, logout }) => {
//   const theme = useTheme();
//   const [sharing, setShare] = useState({
//     email: "",
//     title: `${currentUser.lastName}, ${currentUser.firstName} möchte folgende Dokumente mit Ihnen teilen.`,
//     note: "",
//     documents: [],
//     shareEnd: undefined,
//     patientId: undefined,
//     patientName: undefined,
//   });

//   useEffect(() => {
//     if (window && window.location) {
//       const url = Linking.parse(window.location);
//       if (url.queryParams.verified !== undefined) {
//         const verified = url.queryParams.verified === "true";
//         theme.alert({
//           type: verified ? "success" : "error",
//           message: `E-Mail Bestätigung ${
//             verified ? "erfolgreich" : "fehlgeschlagen"
//           }`,
//           timeout: 5000,
//         });
//       }
//       console.log("Linking.getInitalURL()", url);
//     }
//   }, []);

//   const setSharing = (shareObj, reset) => {
//     if (
//       sharing.patientId &&
//       shareObj.patientId !== sharing.patientId &&
//       reset !== true
//     ) {
//       alert(
//         "Sie können pro Teil-Vorgang nur Dokumente eines Patientens auswählen"
//       );
//     } else if (reset === true) {
//       setShare(shareObj);
//     } else {
//       setShare({ ...sharing, ...shareObj });
//     }
//   };

//   const inRole = (roles, userRoles) => {
//     return userRoles.filter((element) => roles.includes(element)).length > 0;
//   };

//   const callMeteor = ({ name, config, showAlert = true }) => {
//     client
//       .mutate({
//         mutation: gql`
//           mutation callMeteor($name: String!, $config: Object!) {
//             callMeteor(name: $name, config: $config)
//           }
//         `,
//         variables: {
//           name,
//           config,
//         },
//       })
//       .then((result) => {
//         if (showAlert) {
//           theme.alert({ type: "success", message: i18n.t(`success`) });
//         }
//       })
//       .catch((error) => {
//         if (showAlert) {
//           theme.alert({
//             type: "error",
//             message: error.message
//               ? i18n.t(`error.${error.message.replace("GraphQL error: ", "")}`)
//               : "Error",
//           });
//         }
//       });
//   };

//   const changeDoc = ({
//     id,
//     doc = {},
//     type,
//     mode,
//     query,
//     onUpdate,
//     onSuccess,
//   }) => {
//     const responseDoc = Object.assign({}, doc, {
//       __typename: type,
//     });

//     if (id) {
//       responseDoc["_id"] = id;
//     }
//     if (mode === "delete") {
//       responseDoc["deleted"] = true;
//     }

//     logger({ responseDoc }, getSchemaByType(type).typeDef);

//     client
//       .mutate({
//         mutation: gql`
//       mutation handleDoc($_id: ID, $doc: Object, $type: String!, $mode: String!) {
//         handleDoc(_id: $_id, doc: $doc, type: $type, mode: $mode) {
//           ... on ${type} {
//             ${getSchemaByType(type).typeDef}
//           }
//         }
//       }
//     `,
//         variables: {
//           _id: id,
//           doc: doc,
//           type: type,
//           mode: mode,
//         },
//         refetchQueries: query ? query : undefined,
//         optimisticResponse: {
//           __typename: "Mutation",
//           handleDoc: responseDoc,
//         },
//         update: !onUpdate ? undefined : onUpdate,
//       })
//       .then((result) => {
//         theme.alert({ type: "success", message: i18n.t(`saved`) });
//         if (onSuccess) onSuccess();
//       })
//       .catch((error) => {
//         theme.alert({
//           type: "error",
//           message: error.message
//             ? i18n.t(`error.${error.message.replace("GraphQL error: ", "")}`)
//             : "Error",
//         });
//       });
//   };

//   if (inRole(["admin", "client", "employee"], currentUser.roles)) {
//     return (
//       <AppNavigator
//         screenProps={{
//           user: currentUser,
//           inRole: (roles, userRoles) =>
//             inRole(roles, userRoles || currentUser.roles),
//           userLoading: loading,
//           changeDoc: changeDoc,
//           callMeteor: callMeteor,
//           logout: logout,
//           sharing,
//           setSharing,
//           logger,
//         }}
//       />
//     );
//   } else if (inRole(["reader"], currentUser.roles)) {
//     return (
//       <ReaderNavigator
//         screenProps={{
//           user: currentUser,
//           inRole: (roles, userRoles) =>
//             inRole(roles, userRoles || currentUser.roles),
//           userLoading: loading,
//           changeDoc: changeDoc,
//           logout: logout,
//           logger,
//         }}
//       />
//     );
//   } else {
//     return null;
//   }
// });

export default function App() {
  const { user, userLoading } = useAppContext();
  const client = useApolloClient();
  const authRef = useRef(null);
  let colorScheme = useColorScheme();

  const callMeteor = ({ name, config, showAlert = true }) => {
    client
      .mutate({
        mutation: gql`
          mutation callMeteor($name: String!, $config: Object!) {
            callMeteor(name: $name, config: $config)
          }
        `,
        variables: {
          name,
          config,
        },
      })
      .then((result) => {
        console.log({ result });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const logout = () => {
    AsyncStorage.removeItem("token");
    client
      .resetStore()
      .then(() => {
        console.log("logout and reset");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userColor = user ? user.color : "#673fb4";
  return (
    <ThemeProvider
      mode={colorScheme === "dark" ? colorScheme : undefined}
      onFeedback={(type) => {
        if (Platform.OS !== "web") {
          Haptics.notificationAsync(type === "success" ? "success" : "error");
        }
      }}
      theme={{
        colors: {
          primary: userColor,
          surface: "#FFF",
          primaryLight: color(userColor).setAlpha(0.1).toRgbString(),
          primaryDark: color(userColor).darken(0.4).toString(),
          background: "rgb(245,245,245)",
          grey: color(userColor).setAlpha(0.3).toRgbString(),
          errorLight: color("#f44336").setAlpha(0.1).toRgbString(),
          successLight: color("#8bc34a").setAlpha(0.1).toRgbString(),
          border: "rgba(0,0,0,0.1)",
          modes: {
            dark: {
              background: "rgb(20,20,20)",
              input: "rgb(35,35,35)",
              surface: "rgb(35,35,35)",
              text: "#FFF",
              border: "rgba(0,0,0,0.1)",
            },
          },
        },
        globals: {
          shadowColor: "rgba(0,0,0,0.2)",
          roundness: 5,
          fontScale: isWeb ? 0.9 : 0.8,
        },
        translations: {
          done: "Fertig",
          showToday: "Heute anzeigen",
          close: "Schließen",
          confirm: "Ja",
          cancel: "Abbrechen",
        },
        Icon: {
          strokeWidth: 1,
        },
        Input: {
          mb: 1,
          clean: true,
          shadow: isAndroid ? 2 : 5,
          indicatorBlurColor: "transparent",
          labelProps: {
            ml: 10,
          },
        },
        Tabs: {
          bg: "primary",
          inactiveColor: "#FFF",
          indicatorSize: 3,
          indicatorColor: "#FFF",
          activeColor: "#FFF",
          roundness: 0,
          indicatorProps: { top: "auto", b: 0, zIndex: 9999 },
        },
        Slider: {
          trackColor: "background",
        },
        Dropdown: {
          position: "right",
          animateProps: {
            t: "120%",
          },
          contentProps: {
            w: 325,
            r: 7,
            shadow: 60,
          },
        },
        Button: {
          rounded: true,
        },
      }}
    >
      <>
        {Platform.OS !== "web" ? <Updates client={client} /> : null}
        {Platform.OS !== "web" ? (
          <LocalAuth
            ref={authRef}
            isUser={user ? true : false}
            onError={() => {
              logout();
            }}
          />
        ) : null}
        {Platform.OS === "ios" ? <StatusBar barStyle="light-content" /> : null}

        <AppNavigation
          currentUser={user}
          loading={userLoading}
          logout={logout}
          callMeteor={callMeteor}
          authRef={authRef || {}}
        />

        {Platform.OS !== "web" && user ? (
          <Push
            onToken={(token) => {
              const pushTokens = user.pushTokens ? user.pushTokens : [];
              console.log({ pushTokens });
              if (pushTokens.indexOf(token) === -1 && !userLoading) {
                pushTokens.push(token);
                callMeteor({
                  name: "savePushToken",
                  config: { pushTokens, userId: user._id },
                  showAlert: false,
                });
              }
            }}
          />
        ) : null}
        {user ? <GlobalInfo user={user} /> : null}
        <Alert />
      </>
    </ThemeProvider>
  );
}
