import React from "react";
import gql from "graphql-tag";

import { Tabs } from "unikit";
import { ListViewQuery, Page, ListItem, Icon } from "../components";
import i18n from "../I18n";
import { useAppContext } from "../AppContext";

const PATIENTS_QUERY = gql`
  query patients($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    patients(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "patients", filter: ["filter"]) {
      _id
      firstName
      lastName
      zipcode
      residence
      address
      archived
      updatedAt
      myPatient
      users {
        _id
        firstName
        lastName
        userPictureFileId
        color
        image {
          _id
          url
          name
        }
      }
    }
  }
`;

export default function Screen({ navigation, route }) {
  const [tab, setTab] = React.useState(i18n.t(route.params?.tab || "my"));
  const { user } = useAppContext();

  const renderItem = ({ item }) => {
    return (
      <ListItem
        title={`${item.lastName}, ${item.firstName}`}
        desc={`${item.address}, ${item.zipcode} ${item.residence}`}
        users={item.users}
        onPress={() =>
          navigation.navigate("Patient", {
            _id: item._id,
            title: `${item.lastName}, ${item.firstName}`,
          })
        }
      />
    );
  };

  return (
    <Page
      title="Patienten"
      headerLeft={() => (
        <Icon
          name="search"
          color="#FFF"
          onPress={() =>
            navigation.navigate("Search", {
              type: "patients",
            })
          }
        />
      )}
      headerRight={() => (
        <Icon
          name="plus"
          color="#FFF"
          onPress={() =>
            navigation.navigate("Form", {
              title: "Neuer Patient",
              type: "Patient",
              mode: "insert",
              defaultValues: {
                myPatient: [user?._id],
                users: [user],
              },
              query: {
                query: PATIENTS_QUERY,
                variables: {
                  filter: {
                    archived: false,
                    myPatient: { $in: [user?._id] },
                  },
                  sort: { lastName: 1 },
                },
              },
              onSuccess: (navigation, item) => {
                console.log("onSuccess", item);
                navigation.replace("Patient", {
                  _id: item._id,
                  title: `${item.lastName}, ${item.firstName}`,
                });
              },
            })
          }
        />
      )}
    >
      <Tabs
        value={tab}
        options={[i18n.t("my"), i18n.t("all"), i18n.t("archiv")]}
        onChange={(value) => setTab(value)}
      />
      {tab === i18n.t("my") ? (
        <ListViewQuery
          query={PATIENTS_QUERY}
          filter={{
            archived: false,
            myPatient: { $in: [user?._id] },
          }}
          sort={{ lastName: 1 }}
          name="patients"
          renderItem={renderItem}
          groupBy="lastName"
          groupByFormat={({ key, value }) => value.toUpperCase().charAt(0)}
        />
      ) : null}

      {tab === i18n.t("all") ? (
        <ListViewQuery
          query={PATIENTS_QUERY}
          filter={{ archived: false }}
          sort={{ lastName: 1 }}
          name="patients"
          renderItem={renderItem}
          groupBy="lastName"
          groupByFormat={({ key, value }) => value.toUpperCase().charAt(0)}
        />
      ) : null}

      {tab === i18n.t("archiv") ? (
        <ListViewQuery
          query={PATIENTS_QUERY}
          filter={{ archived: true }}
          sort={{ lastName: 1 }}
          name="patients"
          renderItem={renderItem}
          groupBy="lastName"
          groupByFormat={({ key, value }) => value.toUpperCase().charAt(0)}
        />
      ) : null}
    </Page>
  );
}
