import React, { useState } from "react";
import { Image, View, TouchableOpacity } from "react-native";

import { WoundImageUtil } from "../../helper";

const maxWidth = 700;

export default ({ value, onChange }) => {
  const [window, setWindow] = useState({ width: 0, height: 0 });

  const getPoint = (e) => {
    let { locationX, layerX, locationY, layerY } = e.nativeEvent;
    var obj = {
      posX: locationX || layerX,
      posY: locationY || layerY,
    };
    obj = WoundImageUtil.makeRelativeToScope(obj, window.width, window.height);
    onChange(obj);
  };
  const circleSize = 20;
  return (
    <View style={{ width: "100%", alignItems: "center" }}>
      <TouchableOpacity
        style={{
          width: "100%",
          maxWidth: maxWidth,
          position: "relative",
          height: window.height,
        }}
        onLayout={({ nativeEvent: { layout } }) => {
          const width = layout.width > maxWidth ? maxWidth : layout.width;
          setWindow({
            width: width,
            height: width * 0.56,
          });
        }}
        onPress={getPoint}
        activeOpacity={0.8}
      >
        <Image
          style={{ width: "100%", height: window.height }}
          source={{ uri: `https://api.clouddoku.de/images/body.png` }}
        />
        {value && value.posX && value.posY ? (
          <View
            style={{
              position: "absolute",
              left: (value.posX * 100 * window.width) / 100 - circleSize / 2,
              top: (value.posY * 100 * window.width) / 100 - circleSize / 2,
              backgroundColor: "#ff0000",
              width: circleSize,
              height: circleSize,
              borderRadius: 15,
            }}
          />
        ) : null}
      </TouchableOpacity>
    </View>
  );
};
