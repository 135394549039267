import React from "react";

import { Tabs, Dropdown, Input, Flex } from "unikit";
import { Page, Icon } from "../components";
import DeliveryNotes from "./Documents/DeliveryNotes";
import Evidences from "./Documents/Evidences";

import i18n from "../I18n";
import { useAppContext } from "../AppContext";

export default function DocumentsScreen() {
  const { user, inRole, changeDoc } = useAppContext();
  const [docType, setDocType] = React.useState("DeliveryNote");
  const [filter, setFilter] = React.useState("all");
  return (
    <Page
      title={i18n.t("documents")}
      headerRight={() => (
        <Dropdown
          content={
            <>
              <Input
                value={filter}
                type="select"
                label="Mitarbeiter wählen"
                placeholder="Mitarbeiter wählen"
                bg="transparent"
                shadow={0}
                inline
                onChange={(value) => {
                  setFilter(value);
                }}
                options={[
                  {
                    label: "Alle",
                    value: "all",
                  },
                  {
                    label: `${user.lastName}, ${user.firstName}`,
                    value: user._id,
                  },
                  ...user.team.map((member) => {
                    return {
                      label: `${member.lastName}, ${member.firstName}`,
                      value: member._id,
                    };
                  }),
                ]}
              />
            </>
          }
        >
          <Flex mr={10}>
            <Icon name="moreHorizontal" color="#FFF" />
          </Flex>
        </Dropdown>
      )}
    >
      <Tabs
        value={docType}
        options={[
          { label: "Offene Lieferscheine", value: "DeliveryNote" },
          { label: "Behandlungsnachweise", value: "Evidence" },
        ]}
        onChange={(v) => setDocType(v)}
      />
      {docType === "DeliveryNote" ? (
        <DeliveryNotes
          user={user}
          admin={inRole(["client"])}
          changeDoc={changeDoc}
          filter={filter === "all" ? undefined : { createdBy: filter }}
        />
      ) : null}

      {docType === "Evidence" ? (
        <Evidences
          user={user}
          admin={inRole(["client"])}
          changeDoc={changeDoc}
          filter={filter === "all" ? undefined : { createdBy: filter }}
        />
      ) : null}
    </Page>
  );
}
