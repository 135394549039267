import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { useMutation, useQuery, useApolloClient } from "react-apollo";
import AsyncStorage from "@react-native-community/async-storage";
import gql from "graphql-tag";
import { showAlert } from "unikit";

import { CALL_METEOR } from "./api/mutations";
import { USER_QUERY } from "./api/queries";
import getSchemaByType from "./clouddoku/lib/schemas";
import i18n from "./I18n";

export const AppState = React.createContext();

export { useNavigation };

export const AppContextProvider = ({ children }) => {
  const client = useApolloClient();
  const { data = {}, loading, error, refetch } = useQuery(USER_QUERY);
  const { currentUser = null } = data;
  const [callMeteor, callMeteorState] = useMutation(CALL_METEOR);

  const [state, setState] = React.useState({});
  const [sharing, setShare] = React.useState({
    email: "",
    title: `${currentUser?.lastName}, ${currentUser?.firstName} möchte folgende Dokumente mit Ihnen teilen.`,
    note: "",
    documents: [],
    shareEnd: undefined,
    patientId: undefined,
    patientName: undefined,
  });

  const call = async ({
    successMessage,
    onSuccess,
    refetchQueries,
    ...rest
  }) => {
    showAlert({
      key: "globalAlert",
      type: "surface",
      loading: true,
    });
    try {
      const result = await callMeteor({
        variables: rest,
        refetchQueries,
      });
      if (successMessage) {
        showAlert({
          key: "globalAlert",
          type: "success",
          message: successMessage,
          loading: false,
          timeout: 2000,
        });
        if (onSuccess) {
          onSuccess();
        }
      } else {
        showAlert({
          key: "globalAlert",
          loading: false,
          timeout: 1,
        });
        if (onSuccess) {
          onSuccess();
        }
      }
      console.log({ result });
      return result;
    } catch (error) {
      showAlert({
        key: "globalAlert",
        type: "error",
        message: error?.message || "Error",
        loading: false,
        timeout: 2000,
      });
    }
  };

  const setSharing = (shareObj, reset) => {
    if (
      sharing.patientId &&
      shareObj.patientId !== sharing.patientId &&
      reset !== true
    ) {
      showAlert({
        type: "warning",
        message:
          "Sie können pro Teil-Vorgang nur Dokumente eines Patientens auswählen",
      });
    } else if (reset === true) {
      setShare(shareObj);
    } else {
      setShare({ ...sharing, ...shareObj });
    }
  };

  const set = (obj) => {
    setState({ ...state, ...obj });
  };

  const inRole = (roles, userRoles) => {
    if (!userRoles) userRoles = currentUser?.roles || [];
    if (userRoles && userRoles.length > 0 && typeof userRoles === "object") {
      return userRoles?.filter((element) => roles.includes(element)).length > 0;
    } else {
      return false;
    }
  };

  const changeDoc = ({
    id,
    doc = {},
    type,
    mode,
    query,
    onUpdate,
    onSuccess,
  }) => {
    const responseDoc = Object.assign({}, doc, {
      __typename: type,
    });

    if (id) {
      responseDoc["_id"] = id;
    }
    if (mode === "delete") {
      responseDoc["deleted"] = true;
    }

    // logger({ responseDoc }, getSchemaByType(type).typeDef);

    client
      .mutate({
        mutation: gql`
      mutation handleDoc($_id: ID, $doc: Object, $type: String!, $mode: String!) {
        handleDoc(_id: $_id, doc: $doc, type: $type, mode: $mode) {
          ... on ${type} {
            ${getSchemaByType(type).typeDef}
          }
        }
      }
    `,
        variables: {
          _id: id,
          doc: doc,
          type: type,
          mode: mode,
        },
        refetchQueries: query ? query : undefined,
        optimisticResponse: {
          __typename: "Mutation",
          handleDoc: responseDoc,
        },
        update: !onUpdate ? undefined : onUpdate,
      })
      .then((result) => {
        showAlert({ type: "success", message: i18n.t(`saved`) });
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        showAlert({
          type: "error",
          message: error.message
            ? i18n.t(`error.${error.message.replace("GraphQL error: ", "")}`)
            : "Error",
        });
      });
  };

  const logout = () => {
    AsyncStorage.removeItem("token");
    client
      .resetStore()
      .then(() => {
        console.log("logout and reset");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUser = (userId) => {
    const team = [...[currentUser], ...currentUser.team];
    return team.find((t) => t._id === userId);
  };

  return (
    <AppState.Provider
      value={{
        ...state,
        set,
        user: currentUser,
        userLoading: loading,
        refetchUser: refetch,
        callMeteor,
        call,
        callLoading: callMeteorState.loading,
        callMeteorState,
        inRole,
        logout,
        changeDoc,
        sharing,
        setSharing,
        getUser,
      }}
    >
      {children}
    </AppState.Provider>
  );
};

export const useAppContext = () => {
  const appState = React.useContext(AppState);
  return appState;
};
