import SimpleSchema from "./simple";

const schema = {
  producer: {
    type: String,
    label: "Hersteller",
    max: 50,
    group: "Allgemein",
    listed: false,
  },
  category: {
    type: String,
    label: "Kategorie",
    options: [
      { label: "Wundreinigung", value: "Wundreinigung" },
      { label: "Wundfüllung", value: "Wundfüllung" },
      { label: "Kollagene/Hyaloron", value: "Kollagene/Hyaloron" },
      { label: "Wundfüllung + AG", value: "Wundfüllung + AG" },
      { label: "Wundrandschutz", value: "Wundrandschutz" },
      { label: "Wundabdeckung", value: "Wundabdeckung" },
      { label: "Wundgaze", value: "Wundgaze" },
      { label: "Kompression", value: "Kompression" },
      { label: "Medizinische Hautpflege", value: "Medizinische Hautpflege" },
      {
        label: "Abpolsterung Druckentlastung",
        value: "Abpolsterung Druckentlastung",
      },
      { label: "Fixiermaterialen", value: "Fixiermaterialen" },
      { label: "Salben", value: "Salben" },
      { label: "Lokalanästhesie", value: "Lokalanästhesie" },
      { label: "Zusatznahrung", value: "Zusatznahrung" },
      { label: "Sonstige", value: "Sonstige" },
      { label: "Zubehör", value: "Zubehör" },
    ],
    input: "Select",
    group: "Allgemein",
    listed: false,
  },
  name: {
    type: String,
    label: "Materialname",
    group: "Allgemein",
    listed: false,
  },
  materialNumber: {
    type: String,
    label: "PZN-Nummer",
    max: 50,
    optional: true,
    group: "Allgemein",
  },
  orderNumber: {
    type: String,
    label: "Bestellnummer",
    max: 50,
    optional: true,
    group: "Allgemein",
  },
  EANNumber: {
    type: Array,
    label: "EAN Nummern",
    optional: true,
    group: "Allgemein",
    input: "Tags",
    defaultValue: function () {
      return [];
    },
  },
  "EANNumber.$": {
    type: String,
    listed: false,
    optional: true,
  },
  packagingUnit: {
    type: String,
    label: "Verpackungseinheit",
    max: 50,
    group: "Allgemein",
  },
  size: {
    type: String,
    label: "Größe",
    max: 50,
    group: "Allgemein",
    optional: true,
  },
  purchasingPrice: {
    type: Number,
    label: "Einkaufspreis (netto)",
    optional: true,
    input: "Number",
    group: "Allgemein",
  },
  inStock: {
    type: Number,
    label: "Auf Lager",
    optional: true,
    group: "Allgemein",
    readonly: true,
  },
  cashSale: {
    type: Boolean,
    label: "Barverkauf",
    optional: true,
    input: "Toggle",
    group: "Allgemein",
  },
  note: {
    type: String,
    label: "Anwendungshinweis",
    max: 1000,
    optional: true,
    input: "TextArea",
    group: "Allgemein",
    listed: false,
  },
  contactId: {
    type: String,
    label: "Lieferant wählen",
    optional: true,
    listed: false,
  },
  discount: {
    type: Number,
    label: "Rabatt in %",
    optional: true,
    listed: false,
  },
  actions: {
    type: String,
    listed: "update",
    label: "Letzte Lagerbewegungen",
    optional: true,
    input: "Actions",
    group: "Allgemein",
    inputOptions: ({ doc }) => ({
      filter: {
        type: { $in: ["warehouse_in", "warehouse_out", "warehouse_change"] },
        "materials._id": doc._id,
        companyId: doc.companyId,
      },
    }),
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  copyId: {
    type: String,
    label: "copyId",
    optional: true,
    listed: false,
  },
  groupId: {
    type: String,
    label: "groupId",
    optional: true,
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Materials = new Mongo.Collection("materials");
  Materials.schema = simpleSchema;
  Materials.attachSchema(simpleSchema);
  Materials._ensureIndex({ companyId: 1 });
}

export { simpleSchema as MaterialSchema };

export default schema;
