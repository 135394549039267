import SimpleSchema from "../simple";

const schema = {
  surveyDate: {
    type: Date,
    label: "Erhebungsdatum",
    defaultValue: function () {
      return new Date();
    },
    group: "Allgemeine Erhebungsdaten",
    input: "DatePicker",
  },
  name: {
    type: String,
    label: "Dokumentenname",
    max: 100,
    group: "Allgemeine Erhebungsdaten",
    defaultValue: (props, state) => {
      return props.count + 1 + ". Therapieempfehlung";
    },
  },
  note: {
    type: String,
    label: "Bemerkung",
    optional: true,
    input: "TextArea",
    group: "Allgemeine Erhebungsdaten",
  },
  materialIds: {
    type: Array,
    label: "MaterialId",
    optional: true,
    input: "MaterialList",
    group: "Materialien",
    defaultValue: function () {
      return [];
    },
    options: {
      allowSearch: true,
      allowCreate: false,
      allowEdit: false,
      full: true,
    },
  },
  "materialIds.$": {
    type: String,
    listed: false,
  },
  interval: {
    type: Array,
    label: "Intervall",
    optional: true,
    options: [
      { label: "Täglich", value: "daily" },
      { label: "Alle 48 Stunden", value: "every48hours" },
      { label: "Alle 72 Stunden", value: "every72hours" },
      { label: "Montag", value: "monday" },
      { label: "Dienstag", value: "tuesday" },
      { label: "Mittwoch", value: "wednesday" },
      { label: "Donnerstag", value: "thursday" },
      { label: "Freitag", value: "friday" },
      { label: "Samstag", value: "saturday" },
      { label: "Sonntag", value: "sunday" },
    ],
    inputOptions: {
      multi: true,
      mode: "pills",
    },
    input: "Select",
    group: "Intervall",
  },
  "interval.$": {
    type: String,
    listed: false,
  },
  interval24: {
    type: Boolean,
    label: "Täglich",
    optional: true,
    input: "Toggle",
    group: "Intervall",
    listed: false,
    listedPDF: false,
  },
  interval48: {
    type: Boolean,
    label: "Alle 48 Stunden",
    optional: true,
    input: "Toggle",
    group: "Intervall",
    listed: false,
    listedPDF: false,
  },
  interval72: {
    type: Boolean,
    label: "Alle 72 Stunden",
    optional: true,
    input: "Toggle",
    group: "Intervall",
    listed: false,
    listedPDF: false,
  },
  intervalMonday: {
    type: Boolean,
    label: "Montag",
    optional: true,
    input: "Toggle",
    group: "Intervall",
    listed: false,
    listedPDF: false,
  },
  intervalTuesday: {
    type: Boolean,
    label: "Dienstag",
    optional: true,
    input: "Toggle",
    group: "Intervall",
    listed: false,
    listedPDF: false,
  },
  intervalWednesday: {
    type: Boolean,
    label: "Mittwoch",
    optional: true,
    input: "Toggle",
    group: "Intervall",
    listed: false,
    listedPDF: false,
  },
  intervalThursday: {
    type: Boolean,
    label: "Donnerstag",
    optional: true,
    input: "Toggle",
    group: "Intervall",
    listed: false,
    listedPDF: false,
  },
  intervalFriday: {
    type: Boolean,
    label: "Freitag",
    optional: true,
    input: "Toggle",
    group: "Intervall",
    listed: false,
    listedPDF: false,
  },
  intervalSaturday: {
    type: Boolean,
    label: "Samstag",
    optional: true,
    input: "Toggle",
    group: "Intervall",
    listed: false,
    listedPDF: false,
  },
  intervalSunday: {
    type: Boolean,
    label: "Sonntag",
    optional: true,
    input: "Toggle",
    group: "Intervall",
    listed: false,
    listedPDF: false,
  },
  signature: {
    type: String,
    label: "Unterschrift",
    optional: true,
    listed: true,
    input: "Signature",
    group: "Unterschrift",
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    listed: false,
  },
  patientId: {
    type: String,
    label: "PatientId",
    visible: "false",
    defaultValue: (props) => {
      return props.patientId;
    },
  },
  therapyId: {
    type: String,
    label: "TherapyId",
    visible: "false",
    defaultValue: (props) => {
      return props.therapyId;
    },
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  canceled: {
    type: Boolean,
    label: "Canceled",
    optional: true,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Advices = new Mongo.Collection("advices");
  Advices.schema = simpleSchema;
  Advices.attachSchema(simpleSchema);
  Advices._ensureIndex({ therapyId: 1 });
  Advices._ensureIndex({ patientId: 1 });
  Advices._ensureIndex({ companyId: 1 });
}

export { simpleSchema as AdviceSchema };

export default schema;
