import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { useTheme } from "unikit";

import i18n from "../../I18n";
import { Page, Form, ScorePassword } from "../../components";

const RESET_PASSWORD = gql`
  mutation resetPassword($token: String, $password: String!, $_id: String) {
    resetPassword(token: $token, password: $password, _id: $_id)
  }
`;

export default function Auth({ route, navigation }) {
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const [password, setPassword] = useState("");
  const theme = useTheme();
  const _id = route?.params?._id;
  const backOnSuccess = route?.params?.backOnSuccess;
  return (
    <Page title="Passwort ändern">
      <Form
        renderHeader={() => {
          return (
            <ScorePassword password={password} style={{ marginBottom: 1 }} />
          );
        }}
        buttonText="Passwort ändern"
        onChange={(doc) => {
          if (doc.password !== password) {
            setPassword(doc.password);
          }
        }}
        onSave={({ password, passwordRepeat }, setDoc) => {
          if (password === passwordRepeat && password.length >= 4) {
            resetPassword({
              variables: {
                password,
                _id,
              },
            })
              .then((result) => {
                theme.alert({ type: "success", message: i18n.t(`saved`) });
                setDoc({});
                if (backOnSuccess) {
                  navigation.goBack();
                }
              })
              .catch((error) => {
                console.log({ error });
                theme.alert({
                  type: "error",
                  message: error.message || "Error",
                });
              });
          } else {
            theme.alert({ type: "error", message: "error.password_match" });
          }
        }}
        schema={[
          {
            key: "password",
            input: "Text",
            label: "Neues Passwort",
            placeholder: "Neues Passwort",
            required: true,
            group: "Allgemein",
          },
          {
            key: "passwordRepeat",
            input: "Text",
            label: "Neues Passwort widerholen",
            placeholder: "Neues Passwort widerholen",
            required: true,
            group: "Allgemein",
          },
        ]}
      />
    </Page>
  );
}

Auth.navigationOptions = ({ navigation, screenProps }) => ({
  title: "Login/Passwort",
});
