import * as React from "react";
import { AppState } from "react-native";
import { usePrevious, useTheme } from "unikit";

export function useAppState() {
  const currentState = AppState.currentState;
  const [appState, setAppState] = React.useState(currentState);

  function onChange(newState) {
    setAppState(newState);
  }

  React.useEffect(() => {
    AppState.addEventListener("change", onChange);

    return () => {
      AppState.removeEventListener("change", onChange);
    };
  }, []);

  return appState;
}

export default function AppUpdates({ user }) {
  const theme = useTheme();
  const appState = useAppState();
  const prevAppState = usePrevious(appState);

  React.useEffect(() => {
    const current = appState;
    const prev = prevAppState;
    if (prev) {
      if (current.match(/active/) && prev.match(/inactive|background/)) {
        if (user.globalInfo && user.globalInfo.length > 0) {
          theme.alert({
            type: "warning",
            message: user.globalInfo,
            timeout: 10000,
          });
        }
      }
    }
  }, [appState]);

  React.useEffect(() => {
    if (user.globalInfo && user.globalInfo.length > 0) {
      theme.alert({
        type: "warning",
        message: user.globalInfo,
        timeout: 10000,
      });
    }
  }, []);

  return null;
}
