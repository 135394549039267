import React, { useState, Fragment, useEffect } from "react";
import { ScrollView } from "react-native";
import { Progress, Flex, Touchable, Avatar, useTheme } from "unikit";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as WebBrowser from "expo-web-browser";
import * as Print from "expo-print";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";

import Icon from "../Icon";
import PdfPreview from "../PdfReader/PdfPreview";
import { Platform } from "react-native";
import { useAppContext } from "../../AppContext";

const UPLOAD_FILE = gql`
  mutation uploadFile($base64: String!) {
    uploadFile(base64: $base64)
  }
`;

const DELETE_FILE = gql`
  mutation deleteFile($_id: String!) {
    deleteFile(_id: $_id)
  }
`;

const File = ({ value, inputKey, onChange, multiple, doc, navigation }) => {
  const { callMeteor } = useAppContext();
  //alert(inputKey);
  const theme = useTheme();

  const [load, setLoad] = useState([]);
  const [list, setList] = useState([]);
  const [uploadFile, { loading }] = useMutation(UPLOAD_FILE);
  const [deleteFile] = useMutation(DELETE_FILE);

  useEffect(() => {
    const getFiles = async () => {
      setLoad(true);
      if (!value) {
        setLoad(false);
      } else if (typeof value === "object") {
        await value.map(async (id) => {
          const result = await callMeteor({
            variables: {
              name: "getSignedURL",
              config: { fileId: id },
            },
          });

          setList((l) => [...l, result.data.callMeteor.result]);
        });
        setLoad(false);
      } else {
        const result = await callMeteor({
          variables: {
            name: "getSignedURL",
            config: { fileId: value },
          },
        });
        setLoad(false);
        if (result.data.callMeteor.result !== null) {
          setList([result.data.callMeteor.result]);
        }
      }
    };
    getFiles();
  }, []);

  const upload = (base64) => {
    uploadFile({
      variables: {
        base64,
      },
    })
      .then((result) => {
        if (result.data) {
          var item = result.data.uploadFile;

          if (multiple) {
            setList([...list, { url: item.url, name: item.name }]);
            var newValue = value || [];
            newValue.push(item._id);
            onChange(newValue);
          } else {
            setList([{ url: item.url, name: item.name }]);
            onChange(item._id);
            if (inputKey === "imageFileId") {
              onChange(
                {
                  _id: result.data.uploadFile._id,
                  url: result.data.uploadFile.url,
                  name: result.data.uploadFile.name,
                  key: result.data.uploadFile.key,
                },
                "image"
              );
            }
          }
        } else {
          alert("Ein Fehler ist aufgetreten");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const size = 140;

  return (
    <Fragment>
      {loading || load ? (
        <Flex w="100%" h={100} align="center" content="center">
          <Progress size={30} trackWidth={5} progressWidth={3} loading />
        </Flex>
      ) : null}
      <ScrollView
        style={{ width: "100%" }}
        showsHorizontalScrollIndicator={false}
        horizontal
      >
        <Flex row>
          {multiple || list.length === 0 ? (
            <Touchable
              bg="primary:setAlpha:0.1"
              w={size + theme.globals.gap}
              h={size + theme.globals.gap}
              p={theme.globals.gap / 2}
              borderRadius={theme.globals.roundness}
              mr={theme.globals.gap / 2}
              flexCenter
              onPress={() =>
                theme.alert({
                  type: "surface",
                  position: "bottom",
                  actionSheet: true,
                  backdrop: true,
                  actions: [
                    {
                      icon: "image",
                      label: "Bild auswählen",
                      onPress: () => {
                        navigation.push("File", {
                          onSelect: (base64) => {
                            upload(base64);
                          },
                        });
                      },
                    },
                    ...(multiple
                      ? [
                          {
                            icon: "fileText",
                            label: "PDF auswählen",
                            onPress: () => {
                              async function pick() {
                                let result = await DocumentPicker.getDocumentAsync(
                                  {
                                    type: "application/pdf",
                                  }
                                );
                                var base64 = result.uri;
                                // console.log({ result });
                                if (Platform.OS !== "web") {
                                  base64 = await FileSystem.readAsStringAsync(
                                    result.uri,
                                    {
                                      encoding: FileSystem.EncodingType.Base64,
                                    }
                                  );
                                  base64 = `data:application/pdf;base64,${base64}`;
                                }
                                console.log({ base64 });
                                upload(base64);
                              }
                              pick();
                            },
                          },
                        ]
                      : []),
                  ],
                })
              }
            >
              <Icon name="plus" color="primary" />
            </Touchable>
          ) : null}
          {list.map((item, i) => {
            console.log({ item });
            if (!item) return null;
            return (
              <Flex
                key={`file-${i}`}
                bg="input"
                p={theme.globals.gap / 2}
                borderRadius={theme.globals.roundness}
                mr={theme.globals.gap / 2}
              >
                {item.type === "application/pdf" ? (
                  <Flex
                    w={size}
                    h={size}
                    overflow="hidden"
                    borderRadius={theme.globals.roundness}
                  >
                    <PdfPreview source={{ uri: item.url }} pages={false} />
                  </Flex>
                ) : (
                  <Avatar
                    size={size}
                    source={{ uri: item.url }}
                    roundness={theme.globals.roundness}
                  />
                )}
                <Touchable
                  bg="input"
                  borderRadius={theme.globals.roundness}
                  absolute
                  t={0}
                  r={0}
                  p={theme.globals.gap / 2}
                  onPress={() =>
                    theme.alert({
                      type: "surface",
                      position: "bottom",
                      actionSheet: true,
                      backdrop: true,
                      actions: [
                        {
                          icon: "download",
                          label: "Download",
                          onPress: () => {
                            WebBrowser.openBrowserAsync(item.url);
                          },
                        },
                        {
                          icon: "printer",
                          label: "Print",
                          onPress: () => {
                            Print.printAsync({ uri: item.url });
                          },
                        },
                        {
                          icon: "trash",
                          label: "Delete",
                          onPress: () => {
                            deleteFile({
                              variables: {
                                _id: item._id,
                              },
                            })
                              .then((result) => {
                                var newList = list;
                                newList = newList.filter(
                                  (e) => e._id !== item._id
                                );
                                setList(newList);
                                if (multiple) {
                                  var newValue = value || [];
                                  newValue = newValue.filter(
                                    (e) => e !== item._id
                                  );
                                  onChange(newValue, undefined, true);
                                } else {
                                  onChange("");
                                }
                              })
                              .catch((error) => {
                                alert(error);
                              });
                          },
                        },
                      ],
                    })
                  }
                >
                  <Icon name="moreVertical" color="text" size={20} />
                </Touchable>
              </Flex>
            );
          })}
        </Flex>
      </ScrollView>
    </Fragment>
  );
};

export default File;
