import * as React from "react";
import moment from "moment";

import { Page, ListItem, ListView, Icon } from "../../components";

import { USER_QUERY } from "../../api/queries";
import { useAppContext } from "../../AppContext";

export default function TeamScreen({ navigation }) {
  const { user, userLoading, call } = useAppContext();

  const activeUser = user?.team?.filter((t) => t.banned !== true).length;
  return (
    <Page
      title="Team"
      headerRight={() => (
        <Icon
          name="plus"
          color="#FFF"
          opacity={activeUser >= user.employeeCount ? 0.5 : 1}
          onPress={() => {
            if (activeUser >= user.employeeCount) {
              alert("Mitarbeiter limit erreicht");
            } else {
              navigation.navigate("Form", {
                title: "Neuer Mitarbeiter",
                type: "User",
                mode: "insertEmployee",
                roles: ["employee"],
                refetch: {
                  query: USER_QUERY,
                },
              });
            }
          }}
        />
      )}
    >
      <ListView
        data={user && user.team ? user.team : []}
        loading={userLoading}
        groupBy="banned"
        groupByFormat={({ key, value }) =>
          value === true
            ? "Inaktiv"
            : `Aktiv: ${activeUser}/${user.employeeCount}`
        }
        renderItem={({ item, index }) => (
          <ListItem
            title={`${item.lastName}, ${item.firstName}`}
            desc={`Bearbeitet am ${moment(item.updatedAt).format(
              "DD. MMMM YYYY"
            )}`}
            users={[item]}
            onPress={() => {
              navigation.navigate("Form", {
                title: "Mitarbeiter bearbeiten",
                data: item,
                id: item._id,
                type: "User",
                mode: "updateEmployee",
                roles: item.roles,
              });
            }}
            actions={[
              {
                icon: "print",
                label: "Passwort zurücksetzen",
                onPress: () => {
                  call({
                    name: "enrollAccount",
                    config: {
                      mail: item.emails[0].address,
                    },
                    successMessage: "E-Mail gesendet",
                  });
                },
              },
            ]}
          />
        )}
      />

      {/* Go ahead and delete ExpoLinksView and replace it with your
       * content, we just wanted to provide you with some helpful links */}
    </Page>
  );
}

// export default class LinksScreen extends React.Component {
//   static navigationOptions = ({ navigation, screenProps: { user } }) => ({
//     title: navigation.state.params.title,
//     headerRight: (
//       <Icon
//         name="plus"
//         color="#FFF"
//         onPress={() => {
//           if (navigation.state.params.activeUser >= user.employeeCount) {
//             alert("Mitarbeiter limit erreicht");
//           } else {
//             navigation.navigate("Form", {
//               title: "Neuer Mitarbeiter",
//               type: "User",
//               mode: "insertEmployee",
//               roles: ["employee"],
//               refetch: {
//                 query: USER_QUERY,
//               },
//             });
//           }
//         }}
//       />
//     ),
//   });

//   constructor() {
//     super();
//     this.state = {
//       activeUser: 0,
//     };
//   }

//   componentDidMount() {
//     this.setUser();
//   }

//   componentDidUpdate(prevProps) {
//     this.setUser();
//   }

//   setUser = () => {
//     const {
//       screenProps: { user },
//     } = this.props;
//     const { setParams } = this.props.navigation;
//     if (
//       user.team &&
//       user.team.filter((t) => t.banned !== true).length !==
//         this.state.activeUser
//     ) {
//       var activeUser = user.team.filter((t) => t.banned !== true).length;
//       this.setState({ activeUser });
//       setParams({ activeUser });
//     }
//   };

//   render() {
//     const {
//       navigation: { state, navigate },
//       screenProps: { user, userLoading, callMeteor },
//     } = this.props;
//     return (

//     );
//   }
// }
