import React, { Fragment } from "react";
import { Button } from "unikit";
import dayjs from "dayjs";

import { Page, ListItem, Icon, ListViewQuery } from "../../components";
import { EVIDENCES_QUERY } from "../../api/queries";
import { useAppContext } from "../../AppContext";

export default function Screen({ route, navigation }) {
  const { changeDoc, getUser } = useAppContext();
  return (
    <Page
      headerRight={() => (
        <Icon
          name="plus"
          color="#FFF"
          onPress={() =>
            navigation.navigate("Form", {
              title: "Neuer Behandlungsnachweis",
              type: "Evidence",
              mode: "insert",
              defaultValues: {
                patientId: route.params.patientId,
              },
              query: {
                query: EVIDENCES_QUERY,
                variables: {
                  filter: {
                    patientId: route.params.patientId,
                  },
                  sort: { surveyDate: -1 },
                },
              },
            })
          }
        />
      )}
    >
      <ListViewQuery
        query={EVIDENCES_QUERY}
        filter={{
          patientId: route.params.patientId,
        }}
        sort={{ surveyDate: -1 }}
        name="evidences"
        sectionAction={(section) => (
          <Button
            onPress={() =>
              navigation.navigate("PDF", {
                type: "Evidence",
                id: section.data[0]._id,
              })
            }
            size={30}
            rounded
          >
            <Icon name="printer" color="#FFF" size={18} />
          </Button>
        )}
        groupBy="surveyDate"
        groupByFormat={({ key, value }) => dayjs(value).format("MMMM YYYY")}
        renderItem={({ item, index }) => {
          return (
            <ListItem
              title={dayjs(item.surveyDate).format("DD. MMMM YYYY")}
              desc={`Bearbeitet am ${dayjs(item.updatedAt).format(
                "DD. MMMM YYYY"
              )}`}
              users={[getUser(item.createdBy)]}
              onPress={() => {
                navigation.push("Form", {
                  title: "Behandlungsnachweis bearbeiten",
                  data: item,
                  id: item._id,
                  type: "Evidence",
                  mode: "update",
                  query: {
                    query: EVIDENCES_QUERY,
                    variables: {
                      filter: {
                        patientId: route.params.patientId,
                      },
                      sort: { surveyDate: -1 },
                    },
                  },
                });
              }}
              pills={[
                ...(item.canceled
                  ? [{ color: "error", label: "cancled" }]
                  : []),
              ]}
              actions={[
                ...(!item.canceled
                  ? [
                      {
                        icon: "x",
                        label: `Dokument stonieren`,
                        onPress: () => {
                          changeDoc({
                            id: item._id,
                            doc: {
                              canceled: true,
                            },
                            type: item.__typename,
                            mode: "update",
                          });
                        },
                      },
                    ]
                  : []),
              ]}
            />
          );
        }}
      />
    </Page>
  );
}
