import React from "react";
import { styled, Text, Chart, LineChart } from "unikit";

import NoContent from "./NoContent";

const Box = styled.View({
  backgroundColor: "surface",
  padding: 15,
  borderRadius: 5,
  width: "100%",
});

const getLegend = (data) => {
  const legend = [];
  data.map((item) => {
    if (!legend.find((a) => a.label === item.key)) {
      legend.push({
        color: item.color,
        label: item.key,
      });
    }
  });
  return legend;
};

export default ({
  title,
  data = [],
  keys,
  keyProps = {},
  gradient = false,
  legend = true,
  ...rest
}) => {
  console.log({ data });
  return (
    <Box shadow={3}>
      <Text level={4} style={{ marginBottom: 20 }}>
        {title}
      </Text>
      {data.length < 2 ? (
        <NoContent title="Nicht genug Daten vorhanden" />
      ) : (
        <LineChart
          data={data}
          keys={keys}
          keyProps={keyProps}
          height={200}
          minChartWidth={data.length * 100}
          gradient={gradient}
          scrollable
          legend={legend}
          xAxis
          yAxis
          grid
          {...rest}
        />
      )}
    </Box>
  );
};
