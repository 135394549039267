import React, { useState, useEffect } from "react";
import { Alert, Platform } from "react-native";
import {
  Flex,
  Text,
  TextInput,
  Input,
  Button,
  Group,
  styled,
  Icon,
  Progress,
  Overlay,
} from "unikit";

import Listitem from "../ListItem";
import i18n from "../../I18n";
import ListViewQuery from "../ListViewQuery";
import { useAppContext } from "../../AppContext";

const Wrapper = styled.View({});

const ListItemValues = {
  contactId: {
    title: (item) => {
      return `${item.name}, ${item.typ}`;
    },
    desc: (item) => {
      return `Telefon: ${item.phone}, Fax: ${item.fax}`;
    },
    subdesc: (item) => {
      return `${item.address}, ${item.zipCode} ${item.residence}`;
    },
  },
  contactIDs: {
    title: (item) => {
      return `${item.name}, ${item.typ}`;
    },
    desc: (item) => {
      return `Telefon: ${item.phone}, Fax: ${item.fax}`;
    },
    subdesc: (item) => {
      return `${item.address}, ${item.zipCode} ${item.residence}`;
    },
  },
  materials: {
    title: (item) => {
      return `${item.count}x ${item.producer}, ${item.name}`;
    },
    desc: (item) => {
      return `Größe: ${item.size}, Verpackungseinheit: ${item.packagingUnit}`;
    },
  },
  materialIds: {
    title: (item) => {
      return `${item.producer}, ${item.name}`;
    },
    desc: (item) => {
      return `Größe: ${item.size}, Verpackungseinheit: ${item.packagingUnit}`;
    },
  },
};

export default ({
  value = [],
  desc,
  subdesc,
  inputKey,
  placeholder,
  onChange,
  navigation,
  doc,
  actions,
  filter,
  previewFilter,
  mode,
  showProgress = false,
  disabled = false,
  tableFooter,
  footerActions,
}) => {
  const { user } = useAppContext();
  value = value === undefined || value === null ? [] : value;
  const [list, setList] = useState(undefined);
  const [text, setText] = useState(value || "");
  const [type, setType] = useState(undefined);
  const [prompt, showPrompt] = useState(false);
  const [promptValue, setPromptValue] = useState(1);
  const [item, setItem] = useState(undefined);

  const filterObj = filter ? filter(doc) : {};
  console.log({ filter: filterObj });

  useEffect(() => {
    if (inputKey === "patient") {
      setList(value || "");
      setType("patients");
    }
    if (inputKey === "email") {
      setList(value || "");
      setType("email");
    }
    if (inputKey === "contactIDs") {
      setList(value || "");
      setType("contacts");
    }
    if (inputKey === "contactIDs") {
      if (doc.contacts) {
        setList(doc.contacts);
      } else {
        setList([]);
      }
      setType("contacts");
    }
    if (inputKey === "materialIds" || inputKey === "materials") {
      if (doc.materials) {
        setList(doc.materials);
      } else {
        setList([]);
      }
      setType("materials");
    }
  }, []);

  useEffect(() => {
    if (
      inputKey === "patient" ||
      inputKey === "email" ||
      inputKey === "contactId"
    ) {
      setList(typeof value === "string" ? value : "");
    }
    if (inputKey === "materials") {
      setList(value);
    }
  }, [value]);

  const getList = (list) => {
    if (typeof list === "string") {
      return (
        <TextInput
          key={"1"}
          value={text}
          placeholder={placeholder}
          onChange={(value) => {
            setText(value || "");
            if (Platform.OS !== "web") {
              onChange(value || "");
            }
          }}
          onBlur={() => onChange(text)}
        />
      );
    } else if (typeof list === "object") {
      return list.map((item) => {
        if (!item) return null;
        return (
          <Listitem
            title={ListItemValues[inputKey].title(item)}
            desc={desc ? desc(item) : ListItemValues[inputKey].desc(item)}
            subdesc={
              subdesc
                ? subdesc(item)
                : ListItemValues?.[inputKey]?.subdesc?.(item)
            }
            renderLeft={
              showProgress ? (
                <Flex mr={10}>
                  <Progress
                    size={35}
                    showValue
                    formatValue={(v) => `${v}/${item.count}`}
                    value={item.bookedCount}
                    max={item.count}
                    progressColor={
                      item.bookedCount === item.count ? "success" : "warning"
                    }
                    trackWidth={3}
                    progressWidth={3}
                  />
                </Flex>
              ) : null
            }
            style={{
              width: "100%",
              borderRadius: 10,
              marginBottom: 10,
            }}
            wrapperStyle={{ width: "100%" }}
            actions={[
              ...(actions ? actions({ doc, item, navigation }) : []),
              ...(disabled
                ? []
                : [
                    {
                      label: "Anzahl ändern",
                      onPress: () => {
                        setPromptValue(parseInt(item?.count || 1));
                        showPrompt(true);
                        setItem(item);
                      },
                    },
                    {
                      icon: "trash",
                      label: "Löschen",
                      onPress: () => {
                        var newList = list;
                        newList = newList.filter(
                          (e) => e && e._id !== item._id
                        );
                        setList(newList);
                        if (inputKey === "materials") {
                          onChange(newList);
                        } else {
                          var newValue = value || [];
                          newValue = newValue.filter(
                            (e) => e && e !== item._id
                          );
                          onChange(newValue);
                          if (inputKey === "materialIds") {
                            onChange(newList, "materials");
                          }
                        }
                      },
                    },
                  ]),
            ]}
          />
        );
      });
    } else {
      return null;
    }
  };

  const setValueWithCount = (count, item) => {
    if (count !== null) {
      const newValue = value || [];
      const found = newValue.find((v) => v._id === item._id);
      if (found) {
        found.count = count;
      } else {
        newValue.push(
          Object.assign({}, item, {
            count: parseInt(count),
            id: item._id,
          })
        );
      }

      onChange(newValue);
      setList(newValue);
      setPromptValue(1);
    }
  };

  const onSelectItem = (item, goBack) => {
    if (typeof list === "object") {
      var found = list.filter((e) => e && e._id === item._id);
      if (found.length > 0) {
        alert("Schon in Liste vorhanden");
        return;
      }
    }
    if (inputKey === "contactIDs") {
      var newValue = value || [];
      newValue.push(item._id);
      newValue = newValue.filter((v) => v !== null);
      onChange(newValue);
      setList([...list, item]);
      onChange([...list, item], "contacts");
    } else if (inputKey === "email") {
      var newValue = `${item.email}`;
      onChange(newValue);
      setText(newValue);
    } else if (inputKey === "materialIds") {
      var newValue = value || [];
      newValue.push(item._id);
      newValue = newValue.filter((v) => v !== null);
      onChange(newValue);
      setList([...list, item]);
      onChange([...list, item], "materials");
    } else if (inputKey === "patient") {
      var newValue = `${item.lastName}, ${item.firstName}`;
      onChange(newValue);
      setText(newValue);
      onChange(item._id, "patientId");
    } else if (inputKey === "materials") {
      showPrompt(true);
      setItem(item);
    }

    if (goBack) goBack();
  };

  return (
    <>
      <Wrapper flex={Platform.OS !== "web" ? 0 : undefined} w="100%">
        {getList(list)}

        {disabled ? null : (
          <Group style={{ marginBottom: 8 }}>
            <Button
              width="100%"
              light
              onPress={() => {
                navigation.push("Search", {
                  type: type === "materials" ? "materialSingle" : type,
                  filter: filterObj,
                  onSelect: (item, goBack) => {
                    onSelectItem(item, goBack);
                  },
                });
              }}
            >
              {typeof list === "string"
                ? `${i18n.t(`types.${type}`)} wählen`
                : `${i18n.t(`types.${type}_plural`)} hinzufügen`}
            </Button>
          </Group>
        )}

        {footerActions
          ? footerActions.map((action, index) => {
              return (
                <Button key={`action-${index}`} onPress={action.onPress}>
                  {action.label}
                </Button>
              );
            })
          : null}

        {tableFooter
          ? tableFooter.map((item, index) => {
              return (
                <Flex
                  key={`tablefooter-${index}`}
                  style={{
                    width: "100%",
                    marginTop: 10,
                    alignItems: "flex-end",
                  }}
                >
                  <Flex
                    style={{
                      flexDirection: "row",
                      width: 300,
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>{item.label}</Text>
                    <Text style={{ fontWeight: "bold" }}>{item.value}</Text>
                  </Flex>
                </Flex>
              );
            })
          : null}

        <Overlay visible={prompt} onClose={() => showPrompt(false)}>
          <Text textAlign="center" mb={15}>
            Wie viele möchten Sie hinzufügen?
          </Text>
          <Flex row>
            <Button
              mr={5}
              size={60}
              opacity={promptValue < 2 ? 0.5 : 1}
              onPress={() => {
                let v = promptValue || 0;
                if (v > 1) {
                  setPromptValue(v - 1);
                }
              }}
            >
              <Icon name="minus" color="#FFF" size={24} />
            </Button>
            <Input
              type="text"
              onChange={(v) =>
                v.length > 0 ? setPromptValue(parseInt(v)) : setPromptValue("")
              }
              value={promptValue}
              keyboardType="number-pad"
              flex={1}
              w="auto"
              textAlign="center"
              font="h4"
            />
            <Button
              ml={5}
              size={60}
              onPress={() => {
                let v = promptValue || 0;
                setPromptValue(v + 1);
              }}
            >
              <Icon name="plus" color="#FFF" size={24} />
            </Button>
          </Flex>
          <Button
            mt={10}
            onPress={() => {
              setValueWithCount(promptValue, item);
              showPrompt(false);
            }}
          >
            Hinzufügen
          </Button>
        </Overlay>
      </Wrapper>
      {previewFilter &&
      doc.contactId &&
      doc.contactId?.length > 0 &&
      mode === "insert" &&
      user.companyId ? (
        <>
          <ListViewQuery
            title={`Weitere Materialien des Lieferanten mit geringem Lagerbestand`}
            titleProps={{ font: "p" }}
            typename="Material"
            name="materials"
            filter={{
              ...previewFilter,
              ...(value && value.length > 0
                ? { _id: { $nin: value.map((v) => v._id) } }
                : {}),
              contactId: doc.contactId,
              companyId: user.companyId,
            }}
            sort={{ inStock: -1 }}
            limit={15}
            renderItem={({ item, index }) => {
              return (
                <Listitem
                  onPress={() => onSelectItem(item)}
                  title={`${item.name}, Größe: ${item.size}, Verpackungseinheit: ${item.packagingUnit}`}
                  desc={`Auf Lager: ${item.inStock}, Hersteller: ${item.producer}, PZN: ${item.materialNumber}`}
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: 10,
                  }}
                  rightAction={
                    <Button size={32}>
                      <Icon name="plus" color="#FFF" size={24} />
                    </Button>
                  }
                />
              );
            }}
          />
        </>
      ) : null}
    </>
  );
};
