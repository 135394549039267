import React from "react";
import { Platform } from "react-native";
import { Animate, Grid, Flex, Text, useTheme } from "unikit";

import { Page, ListItem, Icon, Chat } from "../components";
import { USER_QUERY } from "../api/queries";
import pkg from "../package.json";
import { useAppContext } from "../AppContext";

export default function SettingsScreen({ navigation }) {
  const theme = useTheme();
  const { user, inRole, logout } = useAppContext();

  const getItems = () => {
    const items = [
      {
        title: "Account",
        icon: "user",
        onPress: () => {
          navigation.navigate("Form", {
            title: "Account",
            id: user._id,
            type: "User",
            data: user,
            mode: inRole(["client"])
              ? "updateClient"
              : inRole(["employee"])
              ? "updateEmployee"
              : "updateReader",
            roles: user.roles,
            hiddenFields: ["banned"],
            refetch: "currentUser",
            query: {
              query: USER_QUERY,
            },
          });
        },
      },
      {
        title: "Password/Login",
        icon: "lock",
        onPress: () => {
          navigation.navigate("Reset");
        },
      },
    ];
    if (user && inRole(["admin"])) {
      items.push({
        title: "Admin",
        icon: "user",
        onPress: () => {
          navigation.navigate("Clients", {
            title: "Admin",
          });
        },
      });
    }
    if (user && inRole(["client"])) {
      items.push({
        title: "Mitarbeiter",
        icon: "users",
        onPress: () => {
          navigation.navigate("Team", {
            title: "Team",
          });
        },
      });
    }
    if (user && inRole(["client", "employee"])) {
      items.push({
        title: "Kontakte",
        icon: "paperclip",
        onPress: () => {
          navigation.navigate("Contacts", {
            title: "Contacts",
          });
        },
      });
      items.push({
        title: "Materialien",
        icon: "package",
        onPress: () => {
          navigation.navigate("Materials", {
            title: "Materials",
          });
        },
      });

      items.push({
        title: "Warenwirtschaft",
        icon: "archive",
        onPress: () => {
          if (user.warehouse) {
            navigation.navigate("Overview", {
              title: "Warenwirtschaft",
            });
          } else {
            theme.alert({
              type: "error",
              message: "Warenwirtschaft nicht freigeschaltet",
            });
          }
        },
      });

      items.push({
        title: "Geteilte Patienten",
        icon: "share",
        onPress: () => {
          navigation.navigate("ShareList", {
            title: "Geteilte Patienten",
            showName: true,
          });
        },
      });
      items.push({
        title: "Statistiken",
        icon: "pieChart",
        onPress: () => {
          navigation.navigate("Stats", {
            title: "Statistiken",
          });
        },
      });
    }

    return items;
  };
  return (
    <Flex relative f={1} style={{ flex: 1 }}>
      <Page
        style={{ minHeight: "100%" }}
        title="Mehr"
        headerRight={() => (
          <Icon name="logOut" color="#FFF" onPress={() => logout()} />
        )}
        scrollView
      >
        <Grid min={300} maxCols={4} gap={15} mb={50} outerGap>
          {getItems().map((item, index) => (
            <Animate delay={index * 100} key={index}>
              <ListItem
                key={index}
                title={item.title}
                icon={item.icon}
                iconColor="primary"
                card
                style={{ padding: 0, width: "100%" }}
                onPress={item.onPress}
                noMargin
              />
            </Animate>
          ))}
        </Grid>
        <Flex w="100%" row flexCenter pt={30} pb={100}>
          <Text o={0.5} font="label">{`Version: ${pkg.version}`}</Text>
        </Flex>
        {Platform.OS === "web" ? (
          <Flex w="100%" row flexCenter pb={80}>
            <a
              style={{ margin: "0 5px", opacity: 0.5 }}
              target="_blank"
              href="https://apps.apple.com/us/app/clouddoku/id1468273852?ls=1"
            >
              <img
                src={require("../assets/images/appstore.svg")}
                width="150px"
                height="auto"
              />
            </a>
            <a
              style={{ margin: "0 5px", opacity: 0.5 }}
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.clouddoku.clouddokuapp"
            >
              <img
                src={require("../assets/images/playstore.svg")}
                width="150px"
                height="auto"
              />
            </a>
          </Flex>
        ) : null}
      </Page>

      <Chat label="Hilfe/Support" user={user} />
      {/* 
      <Support
        onPress={() => {
          if (Platform.OS !== "android") {
            navigation.navigate("Chatra");
          } else {
            WebBrowser.openBrowserAsync("https://clouddoku.de");
          }
        }}
      >
        <Icon name="messageCircle" color="#FFF" size={25} />
        <Text ml={7} font="label" color="#FFF" style={{ color: "#FFF" }}>
          Hilfe/Support
        </Text>
      </Support> */}
    </Flex>
  );
}
