import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { useAppState } from "react-native-hooks";
import { AsyncStorage, Platform } from "react-native";
import * as LocalAuthentication from "expo-local-authentication";
import { BlurView } from "expo-blur";
import { usePrevious, useTheme } from "unikit";
import Icon from "./components/Icon";

function LocalAuth({ isUser, onSuccess, onError }, ref) {
  const theme = useTheme();
  const appState = useAppState();
  const [ready, setReady] = useState(false);
  const [active, setActive] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [lock, setLock] = useState(false);

  const authenticateAsync = async (force) => {
    if ((active && ready) || force === true) {
      let results = await LocalAuthentication.authenticateAsync();
      console.log({ results });
      if (results.success) {
        if (onSuccess) onSuccess();
      } else {
        if (onError) onError();
      }
      setLock(false);
      setTimeout(() => {
        setWaiting(false);
      }, 2500);

      return results;
    }
  };

  const prevAppState = usePrevious(appState);

  useEffect(() => {
    const current = appState;
    const prev = prevAppState;
    if (prev) {
      if (prev.match(/active/) && current.match(/inactive|background/)) {
        if (isUser && ready && active) {
          setLock(true);
        }
      } else if (current.match(/active/) && prev.match(/inactive|background/)) {
        if (isUser && ready && active && !waiting) {
          setWaiting(true);
          authenticateAsync();
        }
      }
    }
  }, [appState]);

  useImperativeHandle(ref, () => ({
    authenticateAsync,
    ready,
    active,
    useAuth: (bool) => {
      if (ready) {
        AsyncStorage.setItem("useLocalAuth", bool ? "true" : "false");
        setActive(bool);
        return "success";
      } else {
        AsyncStorage.setItem("useLocalAuth", null);
        setActive(false);
        return "error";
      }
    },
  }));

  useEffect(() => {
    async function init() {
      const supported = await LocalAuthentication.hasHardwareAsync();
      const enrolled = await LocalAuthentication.isEnrolledAsync();
      const isActive = await AsyncStorage.getItem("useLocalAuth");
      if (supported && enrolled) {
        setReady(true);
        if (isActive === "true") {
          setActive(true);
          if (isUser) {
            setLock(true);
            setWaiting(true);
            authenticateAsync(true);
          }
        }
      }
    }
    init();
  }, []);

  if (Platform.OS === "web") return null;
  if (!isUser) return null;
  if (!active) return null;
  if (lock !== true && active === true) return null;
  return (
    <BlurView
      tint={theme.mode === "dark" ? "dark" : "default"}
      intensity={95}
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <Icon name="lock" color="primary" size={50} />
    </BlurView>
  );
}

export default forwardRef(LocalAuth);

// class LocalAuth extends Component {
//   state = {
//     compatible: false,
//     isEnrolled: false,
//     types: null,
//     appState: AppState.currentState,
//     askForAuth: false,
//     useLocalAuth: false
//   };

//   componentDidMount() {
//     this.hasHardware();
//     this.isEnrolled();
//     AppState.addEventListener("change", this._handleAppStateChange);
//     AsyncStorage.getItem("useLocalAuth").then(val => {
//       if (val === "true") {
//         this.setState({ useLocalAuth: true, askForAuth: true });
//         if (this.props.isUser) {
//           this.setState({ askForAuth: true });
//           this.localAuth();
//         }
//       } else {
//         this.props.onAuth("success");
//       }
//     });
//   }

//   componentDidUpdate(prevProps) {
//     if (
//       !prevProps.isUser &&
//       this.props.isUser &&
//       this.state.compatible &&
//       this.state.useLocalAuth
//     ) {
//       //this.localAuth();
//     }
//   }

//   componentWillUnmount() {
//     AppState.removeEventListener("change", this._handleAppStateChange);
//   }

//   hasHardware = async () => {
//     let compatible = await LocalAuthentication.hasHardwareAsync();
//     this.setState({ compatible });
//   };

//   isEnrolled = async () => {
//     let isEnrolled = await LocalAuthentication.isEnrolledAsync();
//     this.setState({ isEnrolled });
//   };

//   supportedAuthenticationTypes = async () => {
//     let types = await LocalAuthentication.supportedAuthenticationTypesAsync();
//     this.setState({ types });
//   };

//   localAuth = async () => {
//     const { client } = this.props;
//     let result = await LocalAuthentication.authenticateAsync();

//     if (result.success === true) {
//       this.setState({ askForAuth: false, appState: "active" });
//       this.props.onAuth("success");
//     } else {
//       AsyncStorage.removeItem("token");
//       client
//         .resetStore()
//         .then(() => {
//           console.log("logout and reset");
//           this.setState({ askForAuth: false, appState: "active" });
//         })
//         .catch(error => {
//           console.log(error);
//         });
//     }
//   };

//   _handleAppStateChange = nextAppState => {
//     const { isUser } = this.props;
//     if (
//       this.state.appState.match(/inactive|background/) &&
//       nextAppState === "active"
//     ) {
//       if (isUser && this.state.compatible && this.state.useLocalAuth) {
//         this.localAuth();
//       }
//     } else if (
//       this.state.appState.match(/active/) &&
//       nextAppState.match(/inactive|background/)
//     ) {
//       if (isUser && this.state.compatible && this.state.useLocalAuth) {
//         this.setState({ askForAuth: true });
//       }
//     }
//     this.setState({ appState: nextAppState });
//   };

//   render() {
//     const { isUser } = this.props;
//     if (!this.state.askForAuth || !isUser) return null;
//     return (
//       <BlurView
//         tint="default"
//         intensity={95}
//         style={{
//           position: "absolute",
//           left: 0,
//           top: 0,
//           width: "100%",
//           height: "100%",
//           zIndex: 1000
//         }}
//       />
//     );
//   }
// }

// export default withApollo(LocalAuth);
