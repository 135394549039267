import "regenerator-runtime/runtime";
import React, { useState, useEffect } from "react";
import { Platform } from "react-native";
import { AppLoading } from "expo";
import { ApolloProvider } from "react-apollo";
import dayjs from "dayjs";
import { SafeAreaProvider } from "react-native-safe-area-context";

import "dayjs/locale/de";
dayjs.locale("de");

import getClient from "./Apollo";
import AppLayout from "./AppLayout";
import { AppContextProvider } from "./AppContext";

if (Platform.OS !== "web") {
  console.disableYellowBox = true;
}

export default function ApolloApp() {
  const [client, setClient] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function mount() {
      const client = await getClient();
      setClient(client);
      if (Platform.OS === "android") {
        setTimeout(() => {
          setLoaded(true);
        }, 2000);
      } else {
        setLoaded(true);
      }
    }
    mount();
  }, []);

  if (!loaded) {
    return <AppLoading />;
  } else {
    console.log("loaded client");

    return (
      <ApolloProvider client={client}>
        <AppContextProvider>
          <SafeAreaProvider>
            <AppLayout />
          </SafeAreaProvider>
        </AppContextProvider>
      </ApolloProvider>
    );
  }
}
