import SimpleSchema from "../simple";

const schema = {
  surveyDate: {
    type: Date,
    label: "Erhebungsdatum",
    defaultValue: function () {
      return new Date();
    },
    group: "Allgemeine Erhebungsdaten",
    input: "DatePicker",
  },
  name: {
    type: String,
    label: "Dokumentenname",
    max: 100,
    group: "Allgemeine Erhebungsdaten",
    defaultValue: (props) => {
      if (props.count === 0) {
        return "1. Lieferschein";
      } else {
        return props.count + 1 + ". Lieferschein";
      }
    },
  },
  number: {
    type: Number,
    label: "Lieferscheinnummer",
    group: "Allgemeine Erhebungsdaten",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        const field = this.siblingField("companyId");
        var latest = DeliveryNotes.findOne(
          {
            companyId: field.value,
          },
          { sort: { number: -1 } }
        );
        var count = (latest?.number || 0) + 1;
        console.log({ count });
        return count;
      }
    },
    optional: true,
    listed: false,
    listedPDF: true,
  },
  note: {
    type: String,
    label: "Bemerkung",
    optional: true,
    max: 1000,
    input: "TextArea",
    group: "Allgemeine Erhebungsdaten",
  },
  recipe_requested: {
    type: Boolean,
    label: "Rezept angefordert",
    optional: true,
    input: "Toggle",
    group: "Allgemeine Erhebungsdaten",
  },
  recipe_received: {
    type: Boolean,
    label: "Rezept erhalten",
    optional: true,
    input: "Toggle",
    group: "Allgemeine Erhebungsdaten",
  },
  material_send: {
    type: Boolean,
    label: "Material geliefert",
    optional: true,
    input: "Toggle",
    group: "Allgemeine Erhebungsdaten",
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  materials: {
    type: Array,
    optional: true,
    blackbox: true,
    input: "DocListWithCount",
    label: "Materialien",
    group: "Materialien",
    defaultValue: function () {
      return [];
    },
  },
  "materials.$": {
    type: Object,
    listed: false,
    blackbox: true,
  },
  signature: {
    type: String,
    label: "Unterschrift",
    optional: true,
    input: "Signature",
    group: "Unterschrift",
  },
  patientId: {
    type: String,
    label: "PatientId",
    visible: "false",
    defaultValue: (props) => {
      return props.patientId;
    },
  },
  therapyId: {
    type: String,
    label: "TherapyId",
    visible: "false",
    defaultValue: (props) => {
      return props.therapyId;
    },
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  canceled: {
    type: Boolean,
    label: "Canceled",
    optional: true,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  DeliveryNotes = new Mongo.Collection("deliveryNotes");
  DeliveryNotes.schema = simpleSchema;
  DeliveryNotes.attachSchema(simpleSchema);
  DeliveryNotes._ensureIndex({ therapyId: 1 });
  DeliveryNotes._ensureIndex({ patientId: 1 });
  DeliveryNotes._ensureIndex({ companyId: 1 });
}

export { simpleSchema as DeliveryNoteSchema };

export default schema;
