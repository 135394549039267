import * as React from "react";
import * as Updates from "expo-updates";
import { AppState } from "react-native";
import {
  usePrevious,
  Animate,
  Flex,
  styled,
  Text,
  Button,
  Avatar,
} from "unikit";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const Image = styled.Image();

export function useAppState() {
  const currentState = AppState.currentState;
  const [appState, setAppState] = React.useState(currentState);

  function onChange(newState) {
    setAppState(newState);
  }

  React.useEffect(() => {
    AppState.addEventListener("change", onChange);

    return () => {
      AppState.removeEventListener("change", onChange);
    };
  }, []);

  return appState;
}

export default function AppUpdates({ client, onUpdate }) {
  const [show, setShow] = React.useState(false);
  const appState = useAppState();
  const prevAppState = usePrevious(appState);
  const insets = useSafeAreaInsets();

  const checkForUpdates = async () => {
    if (!__DEV__) {
      try {
        const { isAvailable } = await Updates.checkForUpdateAsync();
        if (isAvailable) {
          const { isNew, manifest } = await Updates.fetchUpdateAsync();
          if (isNew) {
            console.log("Got new bundle!", JSON.stringify(manifest));
            setShow(true);
          }
        } else {
          console.log("No updates were found!");
        }
      } catch (e) {
        console.log({ e });
      }
    } else {
      // setShow(true);
    }
  };

  React.useEffect(() => {
    const current = appState;
    const prev = prevAppState;
    if (prev) {
      if (current.match(/active/) && prev.match(/inactive|background/)) {
        checkForUpdates();
      }
    }
  }, [appState]);

  React.useEffect(() => {
    checkForUpdates();
  }, []);

  return (
    <Animate
      isVisible={show}
      from={{ y: -100, o: 0 }}
      to={{ y: 0, o: 1 }}
      absolute
      t={insets.top}
      l={0}
      r={0}
      p={15}
      flexCenter
      zIndex={9999}
    >
      <Flex
        width="100%"
        maxWidth={400}
        bg="surface"
        borderWidth={1}
        borderColor="text:setAlpha:0.05"
        shadow={4}
        borderRadius={20}
        mb={10}
        p={15}
        row
        alignItems="center"
      >
        <Avatar
          size={75}
          shadow={5}
          source={require("./assets/images/icon.png")}
          roundness={15}
        />
        <Flex ml={20}>
          <Text>Update verfügbar</Text>
          <Flex mt={7} row>
            <Button onPress={() => Updates.reloadAsync()} rounded>
              Update
            </Button>
            <Button ml={5} onPress={() => setShow(false)} rounded light>
              Später
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Animate>
  );
}
