import * as React from "react";
import dayjs from "dayjs";

import { Page, ListItem, ListViewQuery, Icon } from "../../components";
import { useAppContext } from "../../AppContext";
import { ORDERS_QUERY } from "../../api/queries";

export default function Screen({ navigation }) {
  const { call, changeDoc } = useAppContext();

  const onChangeDoc = ({ doc }) => {
    console.log({ doc });
    if (doc.contactId && doc.materials && doc.materials.length > 0) {
      return {
        ...doc,
        materials: doc.materials.filter((m) => m.contactId === doc.contactId),
      };
    } else {
      return doc;
    }
  };

  const renderItem = ({ item, refetch }) => {
    return (
      <ListItem
        title={item.name}
        desc={`Bei ${item.contact?.name || ""} vom ${dayjs(
          item.surveyDate
        ).format("DD. MMMM YYYY")}`}
        card
        icon="shoppingCart"
        iconColor="primary"
        pills={[
          ...(item.canceled ? [{ color: "error", label: "cancled" }] : []),
        ]}
        iconPill={item.materials?.length}
        onPress={() => {
          navigation.push("Form", {
            title: item.name,
            type: "Order",
            mode: "update",
            id: item._id,
            data: item,
            onChangeDoc,
            onSuccess: (navigation) => {
              if (refetch) refetch();
              navigation?.goBack();
            },
            query: {
              query: ORDERS_QUERY,
              variables: {
                filter: { send: false, canceled: { $ne: true } },
                sort: { surveyDate: -1 },
              },
            },
          });
        }}
        actions={[
          {
            icon: "fileText",
            label: "PDF erstellen",
            onPress: () => {
              navigation.navigate("PDF", {
                type: item.__typename,
                id: item._id,
                options: undefined,
              });
            },
          },
          {
            icon: "send",
            label: `Bestellung ${item.send ? "erneut " : ""}per E-Mail senden`,
            onPress: () => {
              call({
                name: "sendOrder",
                successMessage:
                  "Ihre Bestellung wurde erfolgreich an den Lieferanten übermittelt",
                refetchQueries: ["orders"],
                config: {
                  orderId: item._id,
                },
              });
            },
          },
          {
            icon: "x",
            label: `Bestellung stonieren`,
            onPress: () => {
              changeDoc({
                id: item._id,
                doc: {
                  canceled: true,
                },
                type: "Order",
                mode: "update",
              });
            },
          },
        ]}
      />
    );
  };
  return (
    <Page
      title="Bestellungen"
      headerRight={() => (
        <Icon
          name="search"
          color="#FFF"
          onPress={() =>
            navigation.navigate("Search", {
              type: "orders",
            })
          }
        />
      )}
      scrollView
    >
      <ListViewQuery
        typename="Order"
        query={ORDERS_QUERY}
        title="Offene Bestellungen"
        filter={{ send: false, canceled: { $ne: true } }}
        sort={{ surveyDate: -1 }}
        limit={20}
        name="orders"
        onAdd={(data, refetch) => {
          navigation.push("Form", {
            title: `Bestellung hinzufügen`,
            type: "Order",
            mode: "insert",
            defaultValues: { contact: { name: "", __typename: "Contact" } },
            onChangeDoc,
            refetch: "orders",
            query: {
              query: ORDERS_QUERY,
              variables: {
                filter: { send: false, canceled: { $ne: true } },
                sort: { surveyDate: -1 },
              },
            },
          });
        }}
        horizontal
        renderItem={renderItem}
      />
      <ListViewQuery
        typename="Order"
        query={ORDERS_QUERY}
        title="Artikel im Zulauf"
        filter={{ send: true, booked: { $ne: true }, canceled: { $ne: true } }}
        sort={{ surveyDate: -1 }}
        limit={5}
        name="orders"
        renderItem={({ item, index }) => {
          return item.materials.map((mat, i) => {
            return (
              <ListItem
                key={`mat-${i}`}
                title={`${mat.count - (mat.bookedCount || 0)}x ${
                  mat.name
                }, Größe: ${mat.size}`}
                desc={`Bestellt am ${dayjs(item.createdAt).format(
                  "DD. MMMM YYYY"
                )}`}
              />
            );
          });
        }}
      />
      <ListViewQuery
        typename="Order"
        query={ORDERS_QUERY}
        title="Bestellhistorie"
        sort={{ surveyDate: -1 }}
        filter={{ send: true }}
        limit={20}
        name="orders"
        horizontal
        renderItem={renderItem}
      />
    </Page>
  );
}
