import * as React from "react";
import { Flex } from "unikit";
import ListItem from "../ListItem";

export default ({ value, onChange }) => {
  const list = value || [];

  return (
    <Flex>
      {list.map((item, index) => (
        <ListItem
          key={`doc-${index}`}
          title={item.name}
          style={{ width: "100%", borderRadius: 8 }}
          wrapperStyle={{ width: "100%" }}
          actions={[
            {
              icon: "delete",
              label: "Löschen",
              onPress: () => {
                var newList = list;
                newList = newList.filter((e) => e._id !== item._id);
                onChange(newList);
              },
            },
          ]}
        />
      ))}
    </Flex>
  );
};
