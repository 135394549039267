import React, { Fragment } from "react";
import { Alert, Platform } from "react-native";
import { Flex, Overlay, Button, Tabs } from "unikit";
import moment from "moment";
import gql from "graphql-tag";

import {
  Page,
  ListItem,
  ListViewQuery,
  Icon,
  Form,
  ShareButton,
} from "../../components";
import i18n from "../../I18n";
import TherapyStats from "./TherapyStats";

import { THERAPY_QUERY, PATIENT_QUERY } from "../../api/queries";

import { getTherapyName } from "../../clouddoku/lib/helper";
import getSchemaByType from "../../clouddoku/lib/schemas";
import { useAppContext } from "../../AppContext";

export default function LinksScreen({ route, navigation }) {
  const [tab, setTab] = React.useState("Dokumente");
  const [state, setState] = React.useState({});
  const [images, setImages] = React.useState(false);
  const { user, sharing, setSharing, changeDoc } = useAppContext();

  const { patient, therapy } = route.params;

  if (!therapy) return <Page />;

  const getName = (__typename, data) => {
    if (!data) data = [];
    if (__typename === "Anamnese" && data.length === 0) {
      return `1. Anamnese`;
    } else if (data.length > 0) {
      var number = data[0].name.match(/\d+/);
      if (number) {
        console.log({ number });
        return `${parseInt(number[0]) + 1}. ${i18n.t(
          `types.${__typename.toLowerCase()}`
        )}`;
      } else {
        return `${parseInt(data.length) + 1}. ${i18n.t(
          `types.${__typename.toLowerCase()}`
        )}`;
      }
    } else {
      return `1. ${i18n.t(`types.${__typename.toLowerCase()}`)}`;
    }
  };

  const getType = (__typename) => {
    const typeNames = {
      Anamnese: "anamnese",
      Advice: "advice",
      DeliveryNote: "deliveryNote",
      DoctorsLetter: "doctorsLetter",
      WoundImage: "woundImage",
    };
    return typeNames[__typename];
  };

  const getCopy = (item, section, clean) => {
    const copyItem = Object.assign({}, item);
    copyItem["surveyDate"] = new Date();
    copyItem["age"] = moment(item["age"]).toDate();
    copyItem["createdAt"] = new Date();
    copyItem["updatedAt"] = new Date();
    copyItem["createdBy"] = user._id;
    copyItem["canceled"] = false;
    Object.keys(copyItem).map((key) => {
      if (copyItem[key] === null || copyItem[key] === undefined) {
        delete copyItem[key];
      }
    });
    delete copyItem["_id"];
    delete copyItem["therapyId"];
    delete copyItem["patientId"];

    if (section?.value === "DeliveryNote") {
      delete copyItem["recipe_requested"];
      delete copyItem["recipe_received"];
      delete copyItem["material_send"];
    }
    if (clean) {
      const copyedAnamnese = {};
      var done = false;
      Object.keys(getSchemaByType("Anamnese").schema).map((key) => {
        if (done === false && copyItem[key]) {
          copyedAnamnese[key] = copyItem[key];
        }
        if (key === "stressFactorNote") {
          done = true;
        }
      });
      delete copyedAnamnese["age"];

      return {
        ...copyedAnamnese,
        name: getName(section.value, section.data),
      };
    }
    return Object.assign({}, copyItem, {
      name: getName(section.value, section.data),
    });
  };

  const shareDoc = (item, options) => {
    const { patient, therapy } = route.params;
    setSharing({
      patientName: `${patient.lastName}, ${patient.firstName}`,
      patientId: item.patientId,
      documents: [
        ...sharing.documents,
        {
          _id: item._id,
          type: item.__typename,
          name: options === "recipe" ? "Rezeptanforderung" : item.name,
          patientId: item.patientId,
          therapyId: item.therapyId,
          therapyName: getTherapyName(therapy),
          surveyDate: item.surveyDate,
          options: options,
        },
      ],
    });
  };

  const docsToRender = [
    {
      typename: "Anamnese",
      filter: { therapyId: therapy._id },
      defaultValues: {},
      query: ``,
    },
    {
      typename: "WoundImage",
      filter: { therapyId: therapy._id },
      defaultValues: {
        image: null,
      },
      icon: "image",
      query: `
        image {
          _id
          url
          name
          key
        } 
     `,
    },
    {
      typename: "DeliveryNote",
      filter: { patientId: patient._id },
      defaultValues: {},
      query: ``,
    },
    {
      typename: "Advice",
      filter: { therapyId: therapy._id },
      defaultValues: {
        materials: null,
      },
      query: ``,
    },
    {
      typename: "DoctorsLetter",
      filter: { therapyId: therapy._id },
      defaultValues: {},
      query: ``,
    },
    {
      typename: "Document",
      filter: { patientId: patient._id },
      defaultValues: {},
      query: `
        files {
          _id
          type
          url
          name
          key
        }
      `,
    },
  ];
  return (
    <>
      <Page
        headerRight={() => (
          <Icon
            name="edit"
            color="#FFF"
            onPress={() =>
              navigation.navigate("Form", {
                title: getTherapyName(route.params.therapy),
                data: route.params.therapy,
                id: route.params.therapy._id,
                type: "Therapy",
                mode: "update",
                query: {
                  query: THERAPY_QUERY,
                  variables: {
                    filter: {
                      _id: route.params._id,
                    },
                    sort: { createdAt: -1 },
                  },
                },
              })
            }
          />
        )}
        scrollView
      >
        {/* Go ahead and delete ExpoLinksView and replace it with your
         * content, we just wanted to provide you with some helpful links */}

        <Tabs
          value={tab}
          options={["Dokumente", "Wundverlauf"]}
          onChange={(value) => setTab(value)}
        />
        {tab === "Wundverlauf" ? (
          <TherapyStats
            anamnesen={state?.anamneses || []}
            woundImages={state?.woundimages || []}
          />
        ) : (
          <Fragment>
            <Fragment>
              {docsToRender.map((doc) => {
                const queryname = doc.typename.toLowerCase() + "s";
                const query = gql`
                      query ${queryname}($filter: Object, $sort: Object, $page: Int, $limit: Int) {
                        ${queryname}(filter: $filter, sort: $sort, page: $page, limit: $limit) @connection(key: ${queryname}, filter: ["filter"]) {
                          ${getSchemaByType(doc.typename).typeDef}
                          ${doc.query}
                        }
                      }
                    `;
                return (
                  <ListViewQuery
                    key={doc.typename}
                    typename={doc.typename}
                    query={query}
                    filter={doc.filter}
                    sort={{ surveyDate: -1 }}
                    name={queryname}
                    limit={doc.limit}
                    onLoad={({ name, data }) => {
                      setState((s) => ({ ...s, [queryname]: data }));
                    }}
                    action={
                      doc.typename === "WoundImage" ? (
                        <Button
                          onPress={() => {
                            setImages(true);
                          }}
                          style={{ borderRadius: 22, marginRight: 10 }}
                        >
                          <Icon name="printer" size={20} color="#FFF" />
                        </Button>
                      ) : null
                    }
                    onAdd={(listData) => {
                      var defaultValues = doc.defaultValues;
                      if (
                        state?.anamneses?.length === 0 &&
                        queryname === "anamneses" &&
                        patient.anamnese
                      ) {
                        alert(
                          "Daten aus letzter Erhebung automatisch übernommen"
                        );
                        defaultValues = Object.assign(
                          {},
                          getCopy(
                            patient.anamnese,
                            {
                              value: doc.typename,
                              data: state[queryname],
                            },
                            true
                          ),
                          defaultValues
                        );
                      }
                      navigation.push("Form", {
                        title: `${i18n.t(
                          `types.${doc.typename.toLowerCase()}`
                        )} hinzufügen`,
                        type: doc.typename,
                        mode: "insert",
                        defaultValues: {
                          name: getName(doc.typename, state[queryname]),
                          patientId: therapy.patientId,
                          therapyId: therapy._id,
                          ...defaultValues,
                        },
                        schemaExtension: {
                          anamneseId: {
                            options: state?.anamneses
                              ? state.anamneses.map((item) => ({
                                  value: item._id,
                                  label: item.name,
                                }))
                              : [],
                          },
                        },
                        ast: doc.query,
                        refetch: {
                          query: PATIENT_QUERY,
                          variables: {
                            filter: {
                              _id: patient._id,
                            },
                            sort: { updatedAt: -1 },
                          },
                        },
                        query: {
                          query: query,
                          variables: {
                            filter: doc.filter,
                            sort: { surveyDate: -1 },
                          },
                        },
                      });
                    }}
                    horizontal
                    renderItem={({ item, index, listData }) => (
                      <ListItem
                        card
                        title={`${item.name}`}
                        image={
                          item.image && !item.canceled
                            ? { uri: item.image.url }
                            : undefined
                        }
                        icon={
                          item.image && !item.canceled ? undefined : doc.icon
                        }
                        desc={`Erhoben am ${moment(item.surveyDate).format(
                          "DD. MMMM YYYY"
                        )}`}
                        pills={[
                          ...(item.canceled
                            ? [{ color: "error", label: "cancled" }]
                            : []),
                        ]}
                        actions={
                          doc.typename === "Document"
                            ? undefined
                            : [
                                {
                                  icon: "copy",
                                  label: "Dokument duplizieren",
                                  onPress: () => {
                                    navigation.push("Form", {
                                      title: `${item.name} kopieren`,
                                      type: item.__typename,
                                      data: item,
                                      mode: "insert",
                                      schemaExtension: {
                                        anamneseId: {
                                          options: state?.anamneses
                                            ? state.anamneses.map((item) => ({
                                                value: item._id,
                                                label: item.name,
                                              }))
                                            : [],
                                        },
                                      },
                                      defaultValues: {
                                        patientId: therapy.patientId,
                                        therapyId: therapy._id,
                                        ...getCopy(item, {
                                          value: doc.typename,
                                          data: state[queryname],
                                        }),
                                      },
                                      query: {
                                        query: query,
                                        variables: {
                                          filter: doc.filter,
                                          sort: { surveyDate: -1 },
                                        },
                                      },
                                    });
                                  },
                                },
                                {
                                  icon: "share",
                                  label: "Dokument teilen",
                                  onPress: () => {
                                    shareDoc(item);
                                  },
                                },
                                ...(!item.canceled
                                  ? [
                                      {
                                        icon: "x",
                                        label: `Dokument stonieren`,
                                        onPress: () => {
                                          const onConfirm = () => {
                                            changeDoc({
                                              id: item._id,
                                              doc: {
                                                canceled: true,
                                              },
                                              type: item.__typename,
                                              mode: "update",
                                              query: [
                                                {
                                                  query: query,
                                                  variables: {
                                                    filter: doc.filter,
                                                    sort: { surveyDate: -1 },
                                                  },
                                                },
                                              ],
                                            });
                                          };
                                          if (Platform.OS === "web") {
                                            if (
                                              window.confirm(
                                                "Dokument wirklich stonieren?"
                                              )
                                            ) {
                                              onConfirm();
                                            }
                                          } else {
                                            Alert.alert(
                                              "Dokument wirklich stonieren?",
                                              "", //"Sie können das löschen unter Aktionen rückgängig machen",
                                              [
                                                {
                                                  text: "Abbrechen",
                                                  onPress: () =>
                                                    console.log(
                                                      "Cancel Pressed"
                                                    ),
                                                  style: "cancel",
                                                },
                                                {
                                                  text: "Ja",
                                                  onPress: () => {
                                                    onConfirm();
                                                  },
                                                },
                                              ],
                                              { cancelable: false }
                                            );
                                          }
                                        },
                                      },
                                    ]
                                  : []),
                                {
                                  icon: "fileText",
                                  label: "PDF erstellen",
                                  onPress: () => {
                                    navigation.navigate("PDF", {
                                      type: item.__typename,
                                      id: item._id,
                                      options: undefined,
                                    });
                                  },
                                },
                                ...(item.__typename === "DeliveryNote"
                                  ? [
                                      {
                                        icon: "fileText",
                                        label:
                                          "Zu Therapieempfehlung weiterführen",
                                        onPress: () => {
                                          navigation.push("Form", {
                                            title: `Neue Therapieempfehlung`,
                                            type: "Advice",
                                            mode: "insert",
                                            defaultValues: {
                                              name: getName(
                                                "Advice",
                                                state.advices || []
                                              ),
                                              patientId: therapy.patientId,
                                              therapyId: therapy._id,
                                              materialIds: item.materials.map(
                                                (m) => m._id
                                              ),
                                            },
                                            query: {
                                              query: gql`
                                        query advices($filter: Object, $sort: Object, $page: Int, $limit: Int) {
                                          advices(filter: $filter, sort: $sort, page: $page, limit: $limit) @connection(key: advices, filter: ["filter"]) {
                                            ${getSchemaByType("Advice").typeDef}
                                          }
                                        }
                                      `,
                                              variables: {
                                                filter: docsToRender.find(
                                                  (d) => d.typename === "Advice"
                                                ).filter,
                                                sort: { surveyDate: -1 },
                                              },
                                            },
                                          });
                                        },
                                      },
                                    ]
                                  : []),
                                ...(item.__typename === "DeliveryNote"
                                  ? [
                                      {
                                        icon: "fileText",
                                        label: "Rezeptanforderung PDF",
                                        onPress: () => {
                                          navigation.navigate("PDF", {
                                            type: item.__typename,
                                            id: item._id,
                                            options: "recipe",
                                          });
                                        },
                                      },
                                    ]
                                  : []),
                                ...(item.__typename === "DeliveryNote" &&
                                user.warehouse
                                  ? [
                                      {
                                        icon: "package",
                                        label: "Materialabtrag buchen",
                                        onPress: () => {
                                          navigation.navigate("Change", {
                                            title: "Entnahme",
                                            defaultDoc: {
                                              materials: item.materials,
                                              warehouseFromId: "warehouse",
                                              warehouseToId: user._id,
                                            },
                                          });
                                        },
                                      },
                                    ]
                                  : []),
                                ...(item.__typename === "DeliveryNote"
                                  ? [
                                      {
                                        icon: "share",
                                        label: "Rezeptanforderung teilen",
                                        onPress: () => {
                                          shareDoc(item, "recipe");
                                        },
                                      },
                                    ]
                                  : []),
                              ]
                        }
                        onPress={() => {
                          navigation.push("Form", {
                            title: `${item.name}`,
                            id: item._id,
                            type: item.__typename,
                            data: item,
                            mode: "update",
                            schemaExtension: {
                              anamneseId: {
                                options: state?.anamneses
                                  ? state.anamneses.map((item) => ({
                                      value: item._id,
                                      label: item.name,
                                    }))
                                  : [],
                              },
                            },
                          });
                        }}
                      />
                    )}
                  />
                );
              })}
            </Fragment>
          </Fragment>
        )}
      </Page>
      {state.woundimages ? (
        <Overlay
          height="auto"
          position="center"
          visible={images}
          onClose={() => setImages(false)}
          contentProps={{ maxWidth: 600 }}
          scrollable
        >
          <Form
            defaultDoc={{ images: [] }}
            buttonText="PDF erstellen"
            onSave={(doc) => {
              if (doc.images && doc.images.length > 0) {
                navigation.navigate("PDF", {
                  type: "WoundImage",
                  id: doc.images[0],
                  options: JSON.stringify(doc.images.slice(1)),
                });
                setImages(false);
              }
            }}
            schema={[
              {
                key: "images",
                label: "Wundbilder",
                placeholder: "Wundbilder",
                input: "Select",
                inputOptions: {
                  multi: true,
                  inline: true,
                },
                options: state?.woundimages?.map((item) => ({
                  value: item._id,
                  label: item.name,
                })),
              },
            ]}
            group={false}
          />
        </Overlay>
      ) : null}

      {sharing?.documents?.length > 0 ? (
        <ShareButton sharing={sharing} navigate={navigation.navigate} />
      ) : null}
    </>
  );
}
