import React from "react";
import { Animated } from "react-native";

export default class Animate extends React.PureComponent {
  static defaultProps = {
    delay: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      fadeAnim: new Animated.Value(0)
    };
  }

  componentDidMount() {
    setTimeout(() => {
      Animated.timing(this.state.fadeAnim, { toValue: 1 }).start();
    }, this.props.delay);
  }

  render() {
    const { transformY, transformX, children } = this.props;

    return (
      <Animated.View
        style={{
          opacity: this.state.fadeAnim,
          transform: [
            {
              translateY: this.state.fadeAnim.interpolate({
                inputRange: [0, 1],
                outputRange: transformY || [0, 0]
              })
            },
            {
              translateX: this.state.fadeAnim.interpolate({
                inputRange: [0, 1],
                outputRange: transformX || [0, 0]
              })
            }
          ]
        }}
      >
        {children}
      </Animated.View>
    );
  }
}
