import React, { useCallback, useReducer } from "react";

type VoidFunction = () => void;

const reducer = (state, _action) => !state;

const useForceUpdate = () => {
  const [, dispatch] = useReducer(reducer, true);

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = useCallback((): void => {
    dispatch(null);
  }, [dispatch]);
  return memoizedDispatch;
};

export default useForceUpdate;
