import React from "react";
import { TouchableOpacity } from "react-native";
import { Icon } from "unikit";

import { useTheme } from "unikit";

export default ({ name, focused, size, color, onPress, style, ...rest }) => {
  const theme = useTheme();
  const icon = (
    <Icon
      name={name}
      size={size || 25}
      style={onPress ? undefined : style}
      color={
        color
          ? theme.colors[color] || color
          : focused
          ? theme.colors.primary
          : theme.colors.grey || "#CCC"
      }
      {...rest}
    />
  );
  if (onPress) {
    return (
      <TouchableOpacity
        onPress={onPress}
        style={[
          {
            width: 44,
            height: 44,
            alignItems: "center",
            justifyContent: "center",
          },
          style,
        ]}
      >
        {icon}
      </TouchableOpacity>
    );
  }
  return icon;
};

// class TabBarIcon extends React.Component {
//   render() {

//     return (
//       <Icon.Feather
//         name={this.props.name}
//         size={25}
//         style={{ marginBottom: -3 }}
//         color={
//           this.props.focused ? Colors.tabIconSelected : Colors.tabIconDefault
//         }
//       />
//     );
//   }
// }
