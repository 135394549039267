import * as React from "react";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { Avatar, Button } from "unikit";

import { ListViewQuery, Icon } from "../../components";
import getSchemaByType from "../../clouddoku/lib/schemas";
import { useAppContext, useNavigation } from "../../AppContext";

const DELIVERY_QUERY = gql`
  query deliverynotes($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    deliverynotes(filter: $filter, sort: $sort, page: $page, limit: $limit) {
      ${getSchemaByType("DeliveryNote").typeDef}
      patient {
        firstName
        lastName
      }
      user {
        _id
        firstName
        lastName
        userPictureFileId
        color
        image {
          _id
          url
          name
        }
      }
    }
  }
`;

export default function Screen({ filter }) {
  const { push } = useNavigation();
  const { user, changeDoc } = useAppContext();
  return (
    <ListViewQuery
      query={DELIVERY_QUERY}
      filter={filter}
      sort={{ number: -1 }}
      name="deliverynotes"
      groupBy="surveyDate"
      groupByFormat={({ key, value }) => {
        return dayjs(value).format("MMMM YYYY");
      }}
      table
      tableSchema={[
        {
          label: "Von",
          key: "user",
          getValue: (item) => {
            var user = item.user;
            if (user) {
              return (
                <Avatar
                  size={38}
                  fontSize={14}
                  borderColor="#FFF"
                  bg={user.color}
                  source={user.image ? { uri: user.image.url } : undefined}
                  char={`${user.firstName ? user.firstName.charAt(0) : ""}${
                    user.lastName ? user.lastName.charAt(0) : ""
                  }`}
                />
              );
            }
            return null;
          },
          width: 60,
        },
        {
          label: "Erhebungsdatum",
          key: "surveyDate",
          getValue: (item) => {
            return dayjs(item.surveyDate).format("DD.MM.YYYY");
          },
          width: 150,
        },
        {
          label: "Storniert",
          key: "canceled",
          getValue: (item) => {
            return item.canceled === true ? "Ja" : "Nein";
          },
          width: 80,
        },
        {
          label: "Name",
          key: "name",
          getValue: (item) => {
            return `${item.name}`;
          },
          width: 150,
        },
        {
          label: "Nummer",
          key: "number",
          getValue: (item) => {
            return `${item.number}`;
          },
          width: 100,
        },
        {
          label: "Patient",
          key: "patient",
          getValue: (item) => {
            return item.patient
              ? `${item.patient.lastName}, ${item.patient.firstName}`
              : "-";
          },
          width: 220,
        },
        {
          label: "Rez. angefordert",
          key: "recipe_requested",
          defaultValue: false,
          width: 130,
        },
        {
          label: "Rez. erhalten",
          key: "recipe_received",
          defaultValue: false,
          width: 130,
        },
        {
          label: "Mat. geliefert",
          key: "material_send",
          defaultValue: false,
          width: 130,
        },
        {
          label: "Rezeptanforderung",
          key: "button",
          getValue: (item) => {
            return (
              <Button
                onPress={() => {
                  push("PDF", {
                    type: "DeliveryNote",
                    id: item._id,
                    options: "recipe",
                  });
                }}
                light
                rounded
                mr={15}
              >
                <Icon name="printer" size={20} color="primary" />
              </Button>
            );
          },
          width: 130,
        },
        {
          label: "Editiern",
          key: "button",
          getValue: (item) => {
            return (
              <Button
                onPress={() => {
                  push("Form", {
                    title: `${item.name}`,
                    id: item._id,
                    type: item.__typename,
                    data: item,
                    mode: "update",
                    schemaExtension: {
                      anamneseId: [],
                    },
                  });
                }}
                light
                rounded
                mr={15}
              >
                <Icon name="edit" size={20} color="primary" />
              </Button>
            );
          },
          width: 130,
        },
      ]}
      onChange={(doc, value, key) => {
        if (doc.canceled) {
          alert("Dokument stoniert");
        } else {
          changeDoc({
            id: doc._id,
            doc: { [key]: value },
            type: doc.__typename,
            mode: "update",
          });
        }
      }}
    />
  );
}
