import React, { Component } from "react";
import { View } from "react-native";
import { Group, Button } from "unikit";

export default ({ navigation, onChange, value, label }) => {
  return (
    <View style={{ paddingHorizontal: 15 }}>
      <Group>
        <Button
          width="100%"
          onPress={() => {
            navigation.navigate("TextEditor", {
              title: label,
              value: value,
              onSave: (newValue, goBack) => {
                onChange(newValue);

                goBack();
              },
            });
          }}
        >
          {`Text bearbeiten`}
        </Button>
      </Group>
    </View>
  );
};
