import * as React from "react";
import { Tabs, Dropdown, Flex, Input } from "unikit";

import { Page, ListViewQuery, ListItem, Icon } from "../../components";
import { ActionItem } from "../../components/inputs/Actions";
import { ACTIONS_QUERY, MATERIALS_QUERY } from "../../api/queries";
import i18n from "../../I18n";
import { useAppContext } from "../../AppContext";

export default function Screen({ navigation: { navigate } }) {
  const { user, call } = useAppContext();
  const [filter, setFilter] = React.useState(user._id);
  const [tab, setTab] = React.useState(i18n.t("materials"));
  const team = [...[user], ...user.team];

  const activeUser = team.find((t) => t._id === filter);

  const userWarehouseMaterials = activeUser?.warehouseMaterials
    ? Object.keys(activeUser.warehouseMaterials)
    : [];

  console.log({ activeUser, filter });
  return (
    <Page
      title="Mein Lager"
      style={{ minHeight: "100%", position: "relative" }}
      headerRight={() => (
        <Flex row alignItems="center">
          <Dropdown
            content={
              <>
                <Input
                  value={filter}
                  type="select"
                  label="Mitarbeiter wählen"
                  placeholder="Mitarbeiter wählen"
                  bg="transparent"
                  shadow={0}
                  inline
                  onChange={(value) => {
                    setFilter(value);
                  }}
                  options={[
                    {
                      label: `${user.lastName}, ${user.firstName}`,
                      value: user._id,
                    },
                    ...user.team.map((member) => {
                      return {
                        label: `${member.lastName}, ${member.firstName}`,
                        value: member._id,
                      };
                    }),
                  ]}
                />
              </>
            }
          >
            <Flex mr={10}>
              <Icon name="moreHorizontal" color="#FFF" />
            </Flex>
          </Dropdown>
          <Icon
            name="printer"
            color="#FFF"
            size={25}
            onPress={() => {
              call({
                name: "printMyWarehouse",
                config: { userId: activeUser._id },
              })
                .then((result) => {
                  console.log({ result });
                  navigate("PDF", {
                    url: result.data.callMeteor.result.Location,
                  });
                })
                .catch((error) => {
                  console.log({ error });
                });
            }}
          />
        </Flex>
      )}
    >
      <Tabs
        value={tab}
        options={[i18n.t("materials"), i18n.t("transactions")]}
        onChange={(value) => setTab(value)}
      />

      {tab === i18n.t("materials") && (
        <ListViewQuery
          query={MATERIALS_QUERY}
          filter={{
            _id: {
              $in: userWarehouseMaterials,
            },
          }}
          sort={{ updatedAt: -1 }}
          name="materials"
          renderItem={({ item, index }) => {
            const count = activeUser.warehouseMaterials[item._id] || 0;
            return (
              <ListItem
                key={`doc-${index}`}
                title={`${count}x ${item.name}`}
                desc={`${item.packagingUnit} - ${item.size}`}
                style={{ width: "100%" }}
                wrapperStyle={{ width: "100%" }}
                users={[activeUser]}
                actions={[
                  {
                    icon: "change",
                    label: "Umbuchen",
                    onPress: () => {
                      navigate("Change", {
                        defaultDoc: {
                          type: "warehouse_change",
                          warehouseFromId: activeUser._id,
                          materials: [{ ...item, count }],
                        },
                      });
                    },
                  },
                ]}
              />
            );
          }}
        />
      )}

      {tab === i18n.t("transactions") && (
        <ListViewQuery
          query={ACTIONS_QUERY}
          filter={{
            createdBy: activeUser._id,
            type: {
              $in: ["warehouse_out", "warehouse_in", "warehouse_change"],
            },
          }}
          sort={{ createdAt: -1 }}
          name="actions"
          contentContainerStyle={{ padding: 5 }}
          renderItem={({ item, index }) => {
            return <ActionItem key={`action-${index}`} team={team} {...item} />;
          }}
        />
      )}
    </Page>
  );
}
