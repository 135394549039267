import React, { Fragment } from "react";
import {
  FlatList,
  SectionList,
  View,
  ScrollView,
  Platform,
  Dimensions,
} from "react-native";
import {
  KeyboardAwareFlatList,
  KeyboardAwareSectionList,
} from "react-native-keyboard-aware-scroll-view";

import { Progress, withTheme, Button, Text, Flex, getObjValue } from "unikit";
import ListItem from "./ListItem";
import Icon from "./Icon";

// import { Table, TableWrapper, Row, Cell } from "react-native-table-component";

const groupByArray = (
  array,
  prop,
  groupByFormat,
  hideEmptyGroup,
  defaultSections = []
) => {
  const defaultArray = defaultSections.map((section) => ({
    title: groupByFormat({ key: prop, value: section }),
    value: section,
    data: [],
  }));
  return array.reduce((groups, item) => {
    const val = groupByFormat
      ? groupByFormat({ key: prop, value: getObjValue(item, prop) })
      : getObjValue(item, prop);
    if ((hideEmptyGroup && val) || !hideEmptyGroup) {
      if (groups.find((a) => a.title === val)) {
        groups.find((a) => a.title === val).data.push(item);
      } else {
        groups.push({
          title: val,
          value: getObjValue(item, prop),
          data: [item],
        });
      }
    }
    return groups;
  }, defaultArray);
};

const Pill = ({ label = "", color, onPress }) => {
  return (
    <Button onPress={onPress} bg={color} mr={15} light pill={!onPress} rounded>
      {label}
    </Button>
  );
};

class ListView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      numColumns: 1,
      setLayout: false,
    };
    this.ref = null;
  }

  scrollTo = ({ index = 0 }) => {
    if (this.ref) {
      this.ref.scrollToIndex({ index });
    }
  };

  getTableData = (doc, key, defaultValue, getValue) => {
    const { onChange } = this.props;
    var value = getObjValue(doc, key);
    //if (key === "material_send") console.log({ typeof: typeof value, value });
    if (
      (typeof value === "undefined" && defaultValue !== undefined) ||
      (value === null && defaultValue !== undefined)
    ) {
      value = defaultValue;
    }
    if (getValue) {
      return getValue(doc);
    }
    if (value === true) {
      return (
        <Pill
          onPress={onChange ? () => onChange(doc, !value, key) : null}
          label={<Icon name="check" size={20} color="success" />}
          color="success"
        />
      );
    } else if (value === false) {
      return (
        <Pill
          onPress={onChange ? () => onChange(doc, !value, key) : null}
          label={<Icon name="x" size={20} color="error" />}
          color="error"
        />
      );
    }
    return <Text>{value || "-"}</Text>;
  };

  render() {
    const {
      horizontal = false,
      gap = 10,
      data = [],
      min,
      renderItem,
      style,
      loading,
      fetchMore,
      limit,
      offset,
      groupBy,
      groupByFormat,
      sectionAction,
      renderFooter,
      renderHeader,
      renderSectionFooter,
      defaultSections,
      hideEmptyGroup,
      theme,
      disableVirtualization,
      refreshing,
      loadMore,
      onRefresh,
      table,
      tableSchema,
      CellRendererComponent,
      onChange,
      windowSize,
      noContentText = "Noch keine Inhalte vorhanden",
      keyboardAware = false,
      headerStyle = {},
      refetch,
      ...rest
    } = this.props;

    if (!data) {
      var listData = [];
    } else {
      var listData = data;
    }

    if (groupBy) {
      const sectionData = groupByArray(
        listData,
        groupBy,
        groupByFormat,
        hideEmptyGroup,
        defaultSections
      );

      defaultSections.map((section) => {
        if (!sectionData[section]) {
          sectionData[section] = [];
        }
      });

      const renderTableItem = ({ item, index }) => {
        return (
          <Flex
            key={index}
            style={{
              width: "100%",
              height: 55,
              flexDirection: "row",
              backgroundColor: theme.colors.surface,
            }}
            row
          >
            {tableSchema.map((schemaItem, cellIndex) => {
              const Comp = this.getTableData(
                item,
                schemaItem.key,
                schemaItem.defaultValue,
                schemaItem.getValue
              );
              return (
                <Flex
                  w={schemaItem.width}
                  h={50}
                  pl={15}
                  justifyContent="center"
                  key={cellIndex}
                  borderRightWidth={1}
                  borderRightColor="background"
                >
                  {typeof Comp === "string" || Comp === "" ? (
                    <Text>{Comp}</Text>
                  ) : (
                    Comp
                  )}
                </Flex>
              );
            })}
          </Flex>
        );
      };

      const renderTableHeader = () => {
        return (
          <Flex
            style={{
              width: "100%",
              height: 35,
              flexDirection: "row",
              backgroundColor: theme.colors.background,
            }}
            row
            borderBottomWidth={1}
            borderBottomColor="background:darken:5"
          >
            {tableSchema.map((schemaItem, cellIndex) => {
              return (
                <Flex
                  w={schemaItem.width}
                  h={35}
                  pl={15}
                  justifyContent="center"
                  key={cellIndex}
                >
                  <Text font="label">{schemaItem.label}</Text>
                </Flex>
              );
            })}
          </Flex>
        );
      };

      const WrapView = table ? ScrollView : Fragment;
      const WrapViewProps = table
        ? {
            horizontal: true,
            style: {
              minWidth: "100%",
            },
            contentContainerStyle: { minWidth: "100%" },
          }
        : {};
      const renderSectionItem = table ? renderTableItem : renderItem;
      const ListComp =
        keyboardAware && Platform.OS !== "web"
          ? KeyboardAwareSectionList
          : SectionList;

      return (
        <WrapView {...WrapViewProps}>
          <ListComp
            ref={(l) => (this.ref = l)}
            enableOnAndroid={Platform.OS === "android"}
            extraHeight={Platform.OS === "android" ? 250 : undefined}
            windowSize={Platform.OS !== "web" ? 5 : 20}
            renderItem={renderSectionItem}
            renderSectionHeader={({ section }) =>
              horizontal ? null : (
                <View style={{ backgroundColor: "rgba(255,255,255,0.9)" }}>
                  <ListItem
                    as={View}
                    title={section.title}
                    height={35}
                    titleSize={14}
                    backgroundColor={theme.colors.background}
                    rightAction={sectionAction ? sectionAction(section) : null}
                    style={{ marginTop: 0 }}
                    {...headerStyle}
                  />
                </View>
              )
            }
            renderSectionFooter={renderSectionFooter || null}
            sections={sectionData}
            onEndReachedThreshold={0.5}
            onEndReached={() => {
              if (fetchMore && listData.length >= limit && !loading) {
                fetchMore();
              }
            }}
            ListFooterComponent={
              <Fragment>
                {renderFooter ? renderFooter() : null}
                {loadMore && !horizontal ? (
                  <View
                    style={{
                      height: 100,
                      width: theme.width,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Progress
                      size={30}
                      trackWidth={5}
                      progressWidth={3}
                      loading
                    />
                  </View>
                ) : null}
              </Fragment>
            }
            ListHeaderComponent={
              table ? renderTableHeader() : renderHeader || null
            }
            keyExtractor={(item, index) => `list-item-${index}`}
            style={{
              flex: 1,
            }}
            contentContainerStyle={{ paddingBottom: 10 }}
            initialNumToRender={15}
            stickySectionHeadersEnabled={true}
            disableVirtualization={disableVirtualization}
            refreshing={refreshing}
            onRefresh={onRefresh}
            horizontal={horizontal}
            showsHorizontalScrollIndicator={false}
            ListEmptyComponent={
              loading || listData.length > 0 ? null : (
                <View
                  style={{
                    height: 200,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text color="text">{noContentText}</Text>
                </View>
              )
            }
            {...rest}
          />
        </WrapView>
      );
    }

    return (
      <FlatList
        ref={(l) => (this.ref = l)}
        contentContainerStyle={style}
        key={`flatlist-${data.length}`}
        data={listData}
        removeClippedSubviews={true}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          if (fetchMore && listData.length >= limit && !loading) {
            fetchMore();
          }
        }}
        numColumns={horizontal ? undefined : this.state.numColumns}
        keyExtractor={(item, index) => `list-item-${index}`}
        style={{
          flexGrow: horizontal ? 0 : 1,
          flexBasis: "auto",
          paddingHorizontal: horizontal ? gap / 2 : 0,
        }}
        renderItem={({ item, index }) =>
          renderItem({
            item,
            index,
            listData: this.state.setLayout ? listData : [],
            refetch,
          })
        } // item index
        ListFooterComponent={
          <Fragment>
            {renderFooter ? renderFooter() : null}
            {loadMore && !horizontal ? (
              <View
                style={{
                  height: 100,
                  width: theme.width,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Progress size={30} trackWidth={5} progressWidth={3} loading />
              </View>
            ) : null}
          </Fragment>
        }
        ListHeaderComponent={renderHeader || null}
        horizontal={horizontal}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        CellRendererComponent={CellRendererComponent}
        removeClippedSubviews={horizontal ? false : undefined}
        ListEmptyComponent={
          loading || listData.length > 0 ? (
            <View
              style={{
                height: 150,
                width: theme.width,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Progress size={30} trackWidth={5} progressWidth={3} loading />
            </View>
          ) : (
            <View
              style={{
                height: 150,
                width: theme.width,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text>{noContentText}</Text>
            </View>
          )
        }
        {...rest}
      />
    );
  }
}

ListView.defaultProps = {
  hideEmptyGroup: true,
  disableVirtualization: false,
  defaultSections: [],
};

export default withTheme(ListView);
