import * as React from "react";
import { Linking, Platform, Alert } from "react-native";
import {
  Grid,
  styled,
  Input,
  Button,
  Flex,
  Progress,
  Text,
  Icon,
  Touchable,
} from "unikit";
import dayjs from "dayjs";

import { getTherapyName } from "../../helper";
import {
  Page,
  ListItem,
  ListViewQuery,
  Actions,
  Wrapper,
} from "../../components";
import { PATIENT_QUERY, THERAPIES_QUERY, useQuery } from "../../api/queries";
import { useAppContext } from "../../AppContext";

const PatientScreen = ({ route, navigation: { navigate, push, goBack } }) => {
  const { user, changeDoc, inRole } = useAppContext();
  const [warnings, setWarnings] = React.useState([]);
  const [healed, setHealed] = React.useState(false);
  const [therapys, setTherapys] = React.useState(false);
  const {
    data = {},
    loading,
    error,
    refetch,
  } = useQuery(PATIENT_QUERY, {
    variables: {
      filter: {
        _id: route.params._id,
      },
      sort: { updatedAt: -1 },
    },
  });
  const { patient } = data;

  const myPatient = patient ? patient.myPatient || [] : [];

  React.useEffect(() => {
    if (patient && warnings.length === 0) {
      const w = [];
      if (data.patient.anamnese) {
        if (data.patient.anamnese.hiv === true) {
          w.push("HIV");
        }
        if (data.patient.anamnese.swabMRSA === true) {
          w.push("MRSA");
        }
        if (data.patient.anamnese.hepatitis === true) {
          w.push("Hepatitis");
        }
      }
      setWarnings([...w]);
    }
  }, [patient]);

  const askForPrescriptionCharge =
    dayjs(data.patient?.updatedAt).format("YYYY") !== dayjs().format("YYYY");

  return (
    <Page loading={loading || !data.patient} scrollView>
      <Wrapper mt={15}>
        {askForPrescriptionCharge && patient?.prescriptionCharge === true ? (
          <Flex
            bg="warning:darken:10"
            justifyContent="space-between"
            row
            wrap
            borderRadius={10}
            p={20}
            mb={10}
          >
            <Text font="h5" color="#FFF">
              Ist der Patient weiterhin Rezeptgebührenbefreit?
            </Text>
            <Flex row>
              <Button
                bg="warning:darken:10"
                color="#FFF"
                onPress={() => {
                  changeDoc({
                    id: data.patient._id,
                    doc: { prescriptionCharge: true },
                    type: "Patient",
                    mode: "update",
                  });
                }}
              >
                Ja
              </Button>
              <Button
                bg="warning"
                color="#FFF"
                onPress={() => {
                  changeDoc({
                    id: data.patient._id,
                    doc: { prescriptionCharge: false },
                    type: "Patient",
                    mode: "update",
                  });
                }}
              >
                Nein
              </Button>
            </Flex>
          </Flex>
        ) : null}
        <Text font="h4" pb={10}>
          Stammdaten
        </Text>
        <Touchable
          w="100%"
          bg="surface"
          p={15}
          borderRadius={10}
          onPress={() => {
            push("Form", {
              title: "Patient bearbeiten",
              data: data.patient,
              id: data.patient._id,
              type: "Patient",
              mode: "update",
              query: {
                query: PATIENT_QUERY,
                variables: {
                  filter: {
                    _id: data.patient._id,
                  },
                  sort: { updatedAt: -1 },
                },
              },
            });
          }}
          shadow={3}
        >
          <Text mt={7} font="p">
            {`${data.patient?.address || "-"}\n${`${data.patient?.zipcode} `}${
              data.patient?.residence || ""
            }\nRezeptgebührenbefreit: ${
              data.patient?.prescriptionCharge ? "Ja" : "Nein"
            }`}
          </Text>

          <Icon
            name="chevronRight"
            color="text:setAlpha:0.2"
            style={{
              position: "absolute",
              top: "50%",
              right: 10,
              marginTop: -5,
            }}
          />
          <Flex row w="100%" mt={20} wrap>
            {data.patient?.phone ? (
              <Button
                size={44}
                onPress={() => {
                  Linking.openURL(`tel:${data.patient.phone}`);
                }}
                rounded
              >
                <Icon name="phone" color="#FFF" size={22} />
              </Button>
            ) : null}

            {warnings?.length > 0
              ? warnings.map((w, i) => (
                  <Button
                    size={44}
                    ml={10}
                    renderLeft={
                      <Icon name="zap" mr={7} size={22} color="error" />
                    }
                    bg="error"
                    light
                    rounded
                  >
                    {w}
                  </Button>
                ))
              : null}
          </Flex>
        </Touchable>

        <Input
          label="Mein Patient"
          type="switch"
          inline
          my={10}
          roundness={8}
          value={myPatient.indexOf(user._id) > -1}
          onChange={() => {
            var newMyPatient = myPatient;
            if (myPatient.indexOf(user._id) > -1) {
              newMyPatient = newMyPatient.filter((e) => e !== user._id);
            } else {
              newMyPatient.push(user._id);
            }
            changeDoc({
              id: data.patient._id,
              doc: { myPatient: newMyPatient },
              type: "Patient",
              mode: "update",
              query: {
                query: PATIENT_QUERY,
                variables: {
                  filter: {
                    _id: data.patient._id,
                  },
                  sort: { updatedAt: -1 },
                },
              },
            });
          }}
          shadow={3}
        />
      </Wrapper>

      <ListViewQuery
        typename="Therapy"
        query={THERAPIES_QUERY}
        filter={{
          patientId: data.patient?._id,
        }}
        sort={{ updatedAt: -1 }}
        name="therapys"
        addTitle="Neue Wunde"
        onLoad={({ name, data }) => {
          setTherapys(data);
        }}
        action={
          <Button
            onPress={() => {
              setHealed(!healed);
            }}
            style={{ borderRadius: 22, marginRight: 10 }}
            light
          >
            <Flex>
              <Text font="caption">Status</Text>
              <Text color="primary" font="label">
                {healed ? "Geheilt" : "In Behandlung"}
              </Text>
            </Flex>
          </Button>
        }
        defaultSections={["In Behandlung", "Geheilt"]}
        onAdd={() => {
          push("Form", {
            title: "Neue Therapie",
            type: "Therapy",
            mode: "insert",
            defaultValues: {
              patientId: data.patient?._id,
              healed: false,
              anamnesen: [],
            },
            query: {
              query: THERAPIES_QUERY,
              variables: {
                filter: {
                  patientId: data.patient._id,
                },
                sort: { updatedAt: -1 },
              },
            },
          });
        }}
        horizontal
        renderItem={({ item, index }) => {
          if (healed && !item.healed) return null;
          if (!healed && item.healed === true) return null;

          return (
            <ListItem
              title={`${getTherapyName(item)}`}
              desc={`Bearbeitet am ${dayjs(item.updatedAt).format(
                "DD. MMMM YYYY"
              )}`}
              icon={item.healed ? "check" : "clipboard"}
              iconColor={item.healed ? "success" : "primary"}
              card
              onPress={() => {
                navigate("Therapy", {
                  title: `${data.patient.lastName}, ${
                    data.patient.firstName
                  } - ${getTherapyName(item)}`,
                  patient: data.patient,
                  therapy: item,
                });
              }}
            />
          );
        }}
      />

      <Wrapper>
        <Text font="h4" pt={20} pb={10}>
          Weiteres
        </Text>
        <Grid min={300} gap={15} maxCols={3}>
          <ListItem
            title="Nachweise"
            icon="layers"
            iconColor="primary"
            card
            style={{ width: "100%" }}
            onPress={() => {
              navigate("Evidence", {
                title: "Nachweise",
                patientId: data.patient._id,
                type: "Evidence",
              });
            }}
            noMargin
          />
          <ListItem
            title="Geteilt mit"
            icon="share"
            iconColor="primary"
            card
            style={{ width: "100%" }}
            onPress={() => {
              navigate("ShareList", {
                title: `${route?.params?.title} - geteilte Dokumente`,
                _id: data.patient._id,
                patient: data.patient,
                showName: false,
              });
            }}
            noMargin
          />
          {data.patient?.informedConsentImage ? (
            <ListItem
              title="Einverständiserklärung drucken"
              icon="printer"
              iconColor="primary"
              card
              style={{ width: "100%" }}
              onPress={() => {
                push("PDF", {
                  type: "Patient",
                  id: data.patient._id,
                  options: undefined,
                });
              }}
              noMargin
            />
          ) : null}
        </Grid>

        {data.patient?._id ? (
          <>
            <Text font="h4" pt={20} pb={10}>
              Neuste Aktionen
            </Text>
            <Actions patientId={data.patient._id} />
          </>
        ) : null}
      </Wrapper>

      {therapys.length === 0 && user && inRole(["client", "employee"]) ? (
        <Wrapper>
          <Button
            style={{ marginTop: 5 }}
            bg="error"
            light
            onPress={() => {
              const onConfirm = () => {
                changeDoc({
                  id: data.patient._id,
                  doc: data.patient,
                  type: "Patient",
                  mode: "delete",
                  onSuccess: () => {
                    goBack();
                  },
                });
              };
              if (Platform.OS === "web") {
                if (window.confirm("Sind Sie sicher?")) {
                  onConfirm();
                }
              } else {
                Alert.alert(
                  "Sind Sie sicher?",
                  "", //"Sie können das löschen unter Aktionen rückgängig machen",
                  [
                    {
                      text: "Abbrechen",
                      onPress: () => console.log("Cancel Pressed"),
                      style: "cancel",
                    },
                    {
                      text: "Ja",
                      onPress: () => {
                        onConfirm();
                      },
                    },
                  ],
                  { cancelable: false }
                );
              }
            }}
          >
            Löschen
          </Button>
        </Wrapper>
      ) : null}
    </Page>
  );
};

export default PatientScreen;
