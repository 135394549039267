import React, { useState, useEffect } from "react";
import { Flex, TextInput, styled, useDebounce } from "unikit";
import { useQuery } from "react-apollo";

import Icon from "./Icon";

const Search = styled(Flex)({
  width: "100%",
  height: 50,
});

export default ({
  query,
  name,
  searchKey = "name",
  onChange,
  projectId,
  contentTypeId,
  filter = {},
  placeholder = "Suche",
  ...rest
}) => {
  const [searchString, setSearchString] = useState("");

  const debouncedSearchTerm = useDebounce(searchString, 500);

  const { data = {}, loading, error } = useQuery(query, {
    variables: {
      filter: {
        [searchKey]: { $regex: `.*${debouncedSearchTerm}.*`, $options: "i" },
        ...(projectId ? { projectId: projectId } : {}),
        ...(contentTypeId ? { contentTypeId: contentTypeId } : {}),
        ...filter,
      },
    },
    skip: searchString < 3,
  });

  useEffect(() => {
    onChange({ data, loading, error });
  }, [data, loading, error]);

  return (
    <Search
      alignItems="center"
      bg="surface"
      bgAlpha={0.4}
      shadow={30}
      pl={17}
      row
    >
      <Icon name="search" size={26} color="primary" />
      <TextInput
        value={searchString}
        placeholder={placeholder}
        style={{ height: 50, paddingLeft: 16 }}
        onChange={(text) => setSearchString(text)}
        autoFocus
      />
    </Search>
  );
};
