import gql from "graphql-tag";
import { useQuery } from "react-apollo";

import getSchemaByType from "../clouddoku/lib/schemas";

export { useQuery };

export const PATIENTS_QUERY = gql`
  query patients($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    patients(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "patients", filter: ["filter"]) {
      _id
      firstName
      lastName
      zipcode
      residence
      address
      archived
      updatedAt
      myPatient
      users {
        _id
        firstName
        lastName
        userPictureFileId
        color
        image {
          _id
          url
          name
        }
      }
    }
  }
`;

export const SHARES_QUERY = gql`
  query shares($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    shares(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "shares", filter: ["filter"]) {
        ${getSchemaByType("Share").typeDef}
    }
  }
`;

export const EVIDENCES_QUERY = gql`
  query evidences($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    evidences(filter: $filter, sort: $sort, page: $page, limit: $limit) @connection(key: "evidences", filter: ["filter"]) {
    ${getSchemaByType("Evidence").typeDef}
    }
  }
`;

export const THERAPIES_QUERY = gql`
  query therapys($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    therapys(filter: $filter, sort: $sort, page: $page, limit: $limit) @connection(key: "therapys", filter: ["filter"]) {
      ${getSchemaByType("Therapy").typeDef}
    }
  }
`;

export const THERAPY_QUERY = gql`
  query therapy($filter: Object, $sort: Object) {
    therapy(filter: $filter, sort: $sort) {
      ${getSchemaByType("Therapy").typeDef}
      anamnesen {
        ${getSchemaByType("Anamnese").typeDef}
      }
      woundImages {
        ${getSchemaByType("WoundImage").typeDef}
      }
    }
  }
`;

export const ORDERS_QUERY = gql`
  query orders($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    orders(filter: $filter, sort: $sort, page: $page, limit: $limit) @connection(key: "orders", filter: ["filter"]) {
      ${getSchemaByType("Order").typeDef}
      contact {
        name
        discount
      }
    }
  }
`;

export const PATIENT_QUERY = gql`
  query patient($filter: Object, $sort: Object) {
    patient(filter: $filter, sort: $sort) {
      ${getSchemaByType("Patient").typeDef}
      anamnese {
        ${getSchemaByType("Anamnese").typeDef}
      }
    }
  }
`;

export const ACTIONS_QUERY = gql`
  query actions($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    actions(filter: $filter, sort: $sort, page: $page, limit: $limit) {
      _id
      createdAt
      type
      typeName
      contactId
      orderId
      warehouseToId
      warehouseFromId
      materials
      user {
        _id
        firstName
        lastName
        color
      }
    }
  }
`;

export const APPOINTMENTS_QUERY = gql`
  query appointments($filter: Object, $sort: Object, $limit: Int) {
    appointments(filter: $filter, sort: $sort, limit: $limit) {
      ${getSchemaByType("Appointment").typeDef}
    }
  }
`;

export const USER_QUERY = gql`
  query currentUser {
    currentUser {
      ${getSchemaByType("User").typeDef}
      warehouseMaterials
      image {
        _id
        url
        name
      }
      logo {
        _id
        url
        name
      }
      stats {
        _id
        patients
        therapys
        patientsData
        therapysData
        warehouseReports
        createdAt
        updatedAt
      }
      team {
        ${getSchemaByType("User").typeDef}
        warehouseMaterials
        image {
          _id
          url
          name
        }
      }
    }
  }
`;

export const MATERIALS_QUERY = gql`
  query materials($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    materials(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted)
      @connection(key: "materials", filter: ["filter"]) {
        ${getSchemaByType("Material").typeDef}
    }
  }
`;

export const MATERIALSGROUP_QUERY = gql`
  query materialgroups(
    $filter: Object
    $sort: Object
    $page: Int
    $limit: Int
  ) {
    materialgroups(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "materialgroups", filter: ["filter"]) {
        ${getSchemaByType("MaterialGroup").typeDef}
    }
  }
`;

export const CONTACTS_QUERY = gql`
  query contacts($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    contacts(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "contacts", filter: ["filter"]) {
        ${getSchemaByType("Contact").typeDef}
    }
  }
`;
