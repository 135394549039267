import React, { useState } from "react";
import { View, Text } from "react-native";
import { TextInput, useDebounce, Icon } from "unikit";
import moment from "moment";

import { ListView, Page, ListItem } from "../components";
import {
  MATERIALSGROUP_QUERY,
  APPOINTMENTS_QUERY,
  MATERIALS_QUERY,
  PATIENTS_QUERY,
  CONTACTS_QUERY,
  ORDERS_QUERY,
  useQuery,
} from "../api/queries";

import i18n from "../I18n";

const types = {
  patients: {
    query: PATIENTS_QUERY,
    filter: (string) => ({
      $or: [
        { firstName: { $regex: `.*${string}.*`, $options: "i" } },
        { lastName: { $regex: `.*${string}.*`, $options: "i" } },
        { insurer: { $regex: `.*${string}.*`, $options: "i" } },
      ],
    }),
    name: "patients",
    groupBy: "lastName",
    groupByFormat: ({ key, value }) => value.toUpperCase().charAt(0),
    title: (item) => `${item.lastName}, ${item.firstName}`,
    desc: (item) =>
      `Bearbeitet am ${moment(item.updatedAt).format("DD. MMMM YYYY")}`,
    pills: (item) =>
      item.archived === true ? [{ label: "Archiv", color: "primary" }] : [],
  },
  contacts: {
    query: CONTACTS_QUERY,
    filter: (string) => ({ name: { $regex: `.*${string}.*`, $options: "i" } }),
    name: "contacts",
    groupBy: "name",
    groupByFormat: undefined,
    title: (item) => `${item.name}`,
    desc: (item) =>
      `Bearbeitet am ${moment(item.updatedAt).format("DD. MMMM YYYY")}`,
  },
  email: {
    query: CONTACTS_QUERY,
    filter: (string) => ({
      $or: [
        { name: { $regex: `.*${string}.*`, $options: "i" } },
        { email: { $regex: `.*${string}.*`, $options: "i" } },
      ],
    }),
    name: "contacts",
    groupBy: "email",
    groupByFormat: undefined,
    title: (item) => `${item.email}`,
    desc: (item) => `${item.name}`,
  },
  materials: {
    query: MATERIALSGROUP_QUERY,
    filter: (string) => ({
      $or: [
        { name: { $regex: `.*${string}.*`, $options: "i" } },
        { producer: { $regex: `.*${string}.*`, $options: "i" } },
        { category: { $regex: `.*${string}.*`, $options: "i" } },
      ],
    }),
    name: "materialgroups",
    groupBy: "category",
    groupByFormat: undefined,
    title: (item) => `${item.name}`,
    desc: (item) => `Hersteller: ${item.producer}`,
  },
  materialSingle: {
    query: MATERIALS_QUERY,
    filter: (string, filter) => ({
      $or: [
        { name: { $regex: `.*${string}.*`, $options: "i" }, ...filter },
        { producer: { $regex: `.*${string}.*`, $options: "i" }, ...filter },
        { category: { $regex: `.*${string}.*`, $options: "i" }, ...filter },
        {
          materialNumber: { $regex: `.*${string}.*`, $options: "i" },
          ...filter,
        },
      ],
    }),
    name: "materials",
    groupBy: "name",
    groupByFormat: undefined,
    title: (item) =>
      `Größe: ${item.size}, Verpackungseinheit: ${item.packagingUnit}`,
    desc: (item) => `Hersteller: ${item.producer}, PZN: ${item.materialNumber}`,
  },
  appointments: {
    query: APPOINTMENTS_QUERY,
    filter: (string) => ({
      patient: { $regex: `.*${string}.*`, $options: "i" },
    }),
    sort: { datum: -1 },
    name: "appointments",
    groupBy: "datum",
    groupByFormat: ({ key, value }) => moment(value).format("MMMM YYYY"),
    title: (item) =>
      `${moment(item.datum).format("DD. MMMM YYYY")}, ${item.patient}`,
    desc: (item) =>
      `Bearbeitet am ${moment(item.updatedAt).format("DD. MMMM YYYY")}`,
  },
  orders: {
    query: ORDERS_QUERY,
    filter: (string) => ({
      name: { $regex: `.*${string}.*`, $options: "i" },
    }),
    sort: { datum: -1 },
    name: "orders",
    groupBy: "surveyDate",
    groupByFormat: ({ key, value }) => moment(value).format("MMMM YYYY"),
    title: (item) => `${item.name}`,
    desc: (item) =>
      `Bearbeitet am ${moment(item.updatedAt).format("DD. MMMM YYYY")}`,
  },
};

export default function Search({
  route,
  navigation: { navigate, state, goBack, setOptions },
}) {
  const [string, setString] = useState("");
  const SearchType = types[route.params.type];
  if (!SearchType) return <Text>Error no Type!</Text>;

  const debouncedString = useDebounce(string, 500);

  console.log({
    SearchType,
    debouncedString,
    filter: SearchType.filter(debouncedString, route.params?.filter || {}),
  });

  const { loading, error, data } = useQuery(SearchType.query, {
    variables: {
      filter: SearchType.filter(debouncedString, route.params?.filter || {}),
      sort: SearchType.sort || undefined,
      page: 0,
      limit: 75,
    },
    skip: debouncedString.length < 1,
  });

  console.log({ loading, error, data });

  return (
    <Page title={`${i18n.t(`types.${route.params.type}_plural`)} suchen`}>
      <View style={{ width: "100%", height: 50 }}>
        <TextInput
          renderLeft={<Icon name="search" pl={15} size={25} />}
          value={string}
          onChange={(text) => {
            setString(text);
          }}
          bg="surface"
          style={{
            height: 50,
            outline: "none",
          }}
          placeholder={i18n.t("search")}
          autoCorrect={false}
          autoFocus
        />
      </View>
      <ListView
        data={data ? data[SearchType.name] : []}
        loading={loading}
        renderItem={({ item, index, type }) => {
          return (
            <ListItem
              title={SearchType.title(item)}
              desc={SearchType.desc(item)}
              pills={SearchType.pills ? SearchType.pills(item) : undefined}
              users={item.users}
              onPress={() => {
                if (route.params.onSelect) {
                  if (route.params.type === "materials") {
                    //this.setState({ materials: item.materials || [] });
                  } else {
                    route.params.onSelect(item, goBack);
                  }
                } else {
                  if (route.params.type === "patients") {
                    navigate("Patient", {
                      _id: item._id,
                      title: types[route.params.type].title(item),
                    });
                  } else if (route.params.type === "contacts") {
                    navigate("Form", {
                      title: "Kontakt bearbeiten",
                      data: item,
                      id: item._id,
                      type: "Contact",
                      mode: "update",
                    });
                  } else if (route.params.type === "materials") {
                    navigate("Form", {
                      title: "Material bearbeiten",
                      data: item,
                      id: item._id,
                      type: "MaterialGroup",
                      mode: "update",
                    });
                  } else if (route.params.type === "appointments") {
                    navigate("Form", {
                      title: "Termin bearbeiten",
                      data: item,
                      id: item._id,
                      type: "Appointment",
                      mode: "update",
                    });
                  } else if (route.params.type === "orders") {
                    navigate("Form", {
                      title: "Order bearbeiten",
                      data: item,
                      id: item._id,
                      type: "Order",
                      mode: "update",
                    });
                  }
                }
              }}
            />
          );
        }}
        groupBy={SearchType.groupBy}
        groupByFormat={SearchType.groupByFormat}
        showsVerticalScrollIndicator={false}
        noContentText={
          string.length > 0 ? "Keine Ergebnisse" : "Suchen Sie nach Einträgen"
        }
      />
    </Page>
  );
}
