import React, { useState } from "react";
import { Button, Flex } from "unikit";

import { Page, SignaturePad } from "../../components";

export default function Screen({ route, navigation: { goBack } }) {
  const [sign, setSign] = useState(null);
  const [loading, setLoading] = useState(false);
  return (
    <Page title="Unterschrift" style={{ padding: 10 }}>
      <Flex w="100%" style={{ alignItems: "center", justifyContent: "center" }}>
        <Flex
          w="100%"
          style={{
            maxWidth: 700,
          }}
        >
          <SignaturePad
            onChange={(signature) => {
              setSign(signature);
            }}
            onLoad={(saving) => {
              setLoading(saving);
            }}
          />
          <Button
            mt={10}
            loading={loading}
            onPress={() => {
              if (route.params?.onSave) {
                route.params.onSave(sign);
                goBack();
              }
            }}
          >
            Speichern
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
}

Screen.navigationOptions = ({ navigation, screenProps }) => ({
  title: "Unterschrift",
});
