import React, { Fragment, useState, useRef } from "react";
import { Platform, View } from "react-native";
import { styled, useGesture, Button, useTheme } from "unikit";
import Svg, { Path } from "react-native-svg";
import { captureRef } from "react-native-view-shot";
import d2i from "dom-to-image";

const SignatureWrap = styled.View(({ theme, width, height, aspect }) => ({
  position: "relative",
  width: width,
  maxWidth: 700,
  height: height ? height : "0px",
  borderWidth: 3,
  borderColor: theme.mode === "dark" ? "surface" : "background",
  borderStyle: "dashed",
}));

class Reaction {
  constructor(gestures) {
    this.gestures = gestures || [];
    this.reset();
    this._offsetX = 0;
    this._offsetY = 0;
  }

  addGesture(points) {
    if (points.length > 0) {
      this.gestures.push(points);
    }
  }

  setOffset(options) {
    this._offsetX = options.x;
    this._offsetY = options.y;
  }

  pointsToSvg(points) {
    let offsetX = this._offsetX;
    let offsetY = this._offsetY;

    if (points.length > 0) {
      var path = `M ${points[0].x},${points[0].y}`;
      points.forEach((point) => {
        path += ` L ${point.x},${point.y}`;
      });
      return path;
    } else {
      return "";
    }
  }

  replayLength() {
    return this.replayedGestures.length;
  }

  reset() {
    this.replayedGestures = [[]];
  }

  empty() {
    return this.gestures.length === 0;
  }

  copy() {
    return new Reaction(this.gestures.slice());
  }

  done() {
    return (
      this.empty() ||
      (this.replayedGestures.length === this.gestures.length &&
        this.lastReplayedGesture().length ===
          this.gestures[this.gestures.length - 1].length)
    );
  }

  lastReplayedGesture() {
    return this.replayedGestures[this.replayedGestures.length - 1];
  }

  stepGestureLength() {
    let gestureIndex = this.replayedGestures.length - 1;
    if (!this.gestures[gestureIndex]) {
      return;
    }
    if (
      this.replayedGestures[gestureIndex].length >=
      this.gestures[gestureIndex].length
    ) {
      this.replayedGestures.push([]);
    }
  }

  step() {
    if (this.done()) {
      return true;
    }
    this.stepGestureLength();
    let gestureIndex = this.replayedGestures.length - 1;
    let pointIndex = this.replayedGestures[gestureIndex].length;
    let point = this.gestures[gestureIndex][pointIndex];
    this.replayedGestures[gestureIndex].push(point);
    return false;
  }
}

export default ({
  width = "100%",
  aspect = 0.6,
  onChange,
  onLoad,
  children,
}) => {
  const theme = useTheme();
  const [currentPoints, setPoints] = useState([]);
  const [donePaths, setPaths] = useState([]);
  const [max, setMax] = useState(0);
  const [height, setHeight] = useState(0);
  const shotRef = useRef(null);
  const reaction = new Reaction();

  const save = async () => {
    if (onLoad) onLoad(true);
    if (Platform.OS === "web") {
      var result = await d2i.toPng(shotRef.current);
    } else {
      var result = await captureRef(shotRef.current, {
        format: "png",
        result: "base64",
        quality: 0.8,
      });
    }
    if (onChange) {
      onChange(
        `${
          result.indexOf("data:") > -1 ? "" : "data:image/png;base64,"
        }${result}`
      );
      if (onLoad) onLoad(false);
    }
  };

  const bindGesture = useGesture(
    {
      onPanResponderGrant: (evt, gs) => {
        //onTouch(evt, gs);
      },
      onPanResponderMove: (evt, gs) => {
        let { locationX, locationY } = evt.nativeEvent;
        setPoints([...currentPoints, { x: locationX, y: locationY }]);
      },
      onPanResponderRelease: (evt, gs) => {
        var newPaths = donePaths;
        if (currentPoints.length > 0) {
          newPaths.push(reaction.pointsToSvg(currentPoints));
        }

        reaction.addGesture(currentPoints);
        setPaths(newPaths);
        setMax(max + 1);
        setPoints([]);

        setTimeout(() => {
          save();
        }, 50);
      },
    },
    [currentPoints, donePaths, max, setPoints]
  );

  const undo = () => {
    let newPaths = donePaths.slice(0, donePaths.length - 1);
    setPaths(newPaths);
    setTimeout(() => {
      save();
    }, 300);
  };

  const PanComp = Platform.OS === "web" ? "div" : View;
  return (
    <Fragment>
      <SignatureWrap
        width={width}
        height={height}
        aspect={aspect}
        onLayout={({ nativeEvent: { layout } }) => {
          setHeight(layout.width * aspect);
        }}
      >
        <Button
          style={{
            position: "absolute",
            top: 15,
            right: 15,
            zIndex: 999,
          }}
          light
          rounded
          onPress={() => undo()}
        >
          Zurück
        </Button>
        <PanComp
          ref={shotRef}
          collapsable={Platform.OS === "android" ? false : true}
        >
          <View {...bindGesture} collapsable={false}>
            <Svg
              width={width}
              height={height}
              viewBox={`0 0 ${width} ${height}`}
              pointerEvents="none"
            >
              {donePaths.map((path, index) => (
                <Path
                  key={`path-${index}`}
                  d={path}
                  stroke={theme.colors.text}
                  strokeWidth={2}
                  fill="none"
                />
              ))}

              <Path
                key={max}
                d={reaction.pointsToSvg(currentPoints)}
                stroke={theme.colors.text}
                strokeWidth={2}
                fill="none"
              />
            </Svg>

            {children}
          </View>
        </PanComp>
      </SignatureWrap>
    </Fragment>
  );
};
