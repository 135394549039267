import SimpleSchema from "../simple";

const schema = {
  surveyDate: {
    type: Date,
    label: "Erhebungsdatum",
    defaultValue: function () {
      return new Date();
    },
    group: "Nachweis",
    input: "DatePicker",
  },
  bandagedChange: {
    type: Boolean,
    label: "Verbandswechsel",
    optional: true,
    input: "Toggle",
    group: "Nachweis",
  },
  bandagedDuration: {
    type: Number,
    label: "Dauer Verbandwechsel (min)",
    optional: true,
    input: "Number",
    visible: "bandagedChange",
    group: "Nachweis",
  },
  medicalRound: {
    type: Boolean,
    label: "Arztvisite",
    optional: true,
    input: "Toggle",
    group: "Nachweis",
  },
  woundImage: {
    type: Boolean,
    label: "Wundbild",
    optional: true,
    input: "Toggle",
    group: "Nachweis",
  },
  therapyChange: {
    type: Boolean,
    label: "Therapieänderung",
    optional: true,
    input: "Toggle",
    group: "Nachweis",
  },
  inPatient: {
    type: Boolean,
    label: "Stationären Aufenthalt",
    optional: true,
    input: "Toggle",
    group: "Nachweis",
  },
  recipe: {
    type: Boolean,
    label: "Rezept",
    optional: true,
    input: "Toggle",
    group: "Nachweis",
  },
  cashSale: {
    type: Boolean,
    label: "Barverkauf",
    optional: true,
    input: "Toggle",
    group: "Nachweis",
  },
  laser: {
    type: Boolean,
    label: "Lasertherapie",
    optional: true,
    input: "Toggle",
    group: "Nachweis",
  },
  contactedBy: {
    type: Array,
    label: "Kontaktaufnahme",
    optional: true,
    options: [
      { label: "Telefon", value: "phone" },
      { label: "Brief", value: "letter" },
      { label: "Mail", value: "mail" },
      { label: "persönlich", value: "personal" },
      { label: "Hausbesuch", value: "home" },
    ],
    inputOptions: {
      multi: true,
      mode: "pills",
    },
    input: "Select",
    group: "Nachweis",
  },
  "contactedBy.$": { type: String, listed: false },
  note: {
    type: String,
    label: "Anmerkung",
    optional: true,
    group: "Nachweis",
    input: "TextArea",
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  patientId: {
    type: String,
    label: "PatientId",
    visible: "false",
    defaultValue: (props) => {
      return props.patientId;
    },
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  canceled: {
    type: Boolean,
    label: "Canceled",
    optional: true,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Evidences = new Mongo.Collection("evidences");
  Evidences.schema = simpleSchema;
  Evidences.attachSchema(simpleSchema);
  Evidences._ensureIndex({ patientId: 1 });
  Evidences._ensureIndex({ companyId: 1 });
}

export { simpleSchema as EvidenceSchema };

export default schema;
