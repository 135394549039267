import SimpleSchema from "./simple";

const schema = {
  producer: {
    type: String,
    label: "Hersteller",
    max: 50,
    group: "Allgemein",
  },
  category: {
    type: String,
    label: "Kategorie",
    options: [
      { label: "Wundreinigung", value: "Wundreinigung" },
      { label: "Wundfüllung", value: "Wundfüllung" },
      { label: "Kollagene/Hyaloron", value: "Kollagene/Hyaloron" },
      { label: "Wundfüllung + AG", value: "Wundfüllung + AG" },
      { label: "Wundrandschutz", value: "Wundrandschutz" },
      { label: "Wundabdeckung", value: "Wundabdeckung" },
      { label: "Wundgaze", value: "Wundgaze" },
      { label: "Kompression", value: "Kompression" },
      { label: "Medizinische Hautpflege", value: "Medizinische Hautpflege" },
      {
        label: "Abpolsterung Druckentlastung",
        value: "Abpolsterung Druckentlastung",
      },
      { label: "Fixiermaterialen", value: "Fixiermaterialen" },
      { label: "Salben", value: "Salben" },
      { label: "Lokalanästhesie", value: "Lokalanästhesie" },
      { label: "Zusatznahrung", value: "Zusatznahrung" },
      { label: "Sonstige", value: "Sonstige" },
      { label: "Zubehör", value: "Zubehör" },
    ],
    input: "Select",
    group: "Allgemein",
  },
  name: {
    type: String,
    label: "Materialname",
    group: "Allgemein",
  },
  note: {
    type: String,
    label: "Anwendungshinweis",
    max: 1000,
    optional: true,
    input: "TextArea",
    group: "Allgemein",
  },
  materialIds: {
    type: Array,
    label: "MaterialId",
    optional: true,
    input: "MaterialList",
    group: "Varianten",
    defaultValue: function () {
      return [];
    },
    options: {
      hidelabel: true,
      allowSearch: false,
      allowCreate: true,
      allowEdit: true,
      full: false,
      type: "MaterialGroup",
    },
  },
  "materialIds.$": {
    type: String,
    listed: false,
  },
  contactId: {
    type: String,
    label: "Lieferant wählen",
    input: "NewDocList",
    optional: true,
    group: "Lieferant",
    inputOptions: {
      hidelabel: true,
      type: "Contact",
      allowSearch: true,
      allowCreate: true,
      allowEdit: true,
      allowRemove: true,
      full: false,
      type: "Contact",
      multiple: false,
      title: (item) => {
        return `${item.name}, ${item.typ}`;
      },
      desc: (item) => {
        return `Telefon: ${item.phone}, Fax: ${item.fax} - ${item.address}, ${item.zipCode} ${item.residence}`;
      },
    },
  },
  discount: {
    type: Number,
    label: "Rabatt in % falls abweichend vom Lieferanten",
    input: "Number",
    optional: true,
    group: "Lieferant",
    defaultValue: 0,
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  copyId: {
    type: String,
    label: "copyId",
    optional: true,
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  MaterialGroup = new Mongo.Collection("materialgroup");
  MaterialGroup.schema = simpleSchema;
  MaterialGroup.attachSchema(simpleSchema);
  MaterialGroup._ensureIndex({ companyId: 1 });
}

export { simpleSchema as MaterialGroupSchema };

export default schema;
