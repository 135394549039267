import * as React from "react";
import AsyncStorage from "@react-native-community/async-storage";
import { Alert } from "react-native";
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import * as SecureStore from "expo-secure-store";
import * as Linking from "expo-linking";
import {
  Flex,
  Progress,
  Button,
  useTheme,
  Input,
  isWeb,
  Text,
  CodeField,
  Form,
  Avatar,
} from "unikit";

import { Page, ScorePassword } from "../components";
import i18n from "../I18n";
import pkg from "../package.json";
import { useAppContext } from "../AppContext";

const LOGIN_MUTATION = gql`
  mutation login(
    $email: String!
    $password: String!
    $code: String
    $localAuth: Boolean
  ) {
    login(
      email: $email
      password: $password
      code: $code
      localAuth: $localAuth
    )
  }
`;

const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($token: String, $password: String!, $_id: String) {
    resetPassword(token: $token, password: $password, _id: $_id)
  }
`;

const LoginScreen = ({ authRef }) => {
  const { userLoading } = useAppContext();
  const client = useApolloClient();
  const formRef = React.useRef(null);
  const [step, setStep] = React.useState("login");
  const [token, setToken] = React.useState(null);
  const [lastAttempt, setLastAttempt] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();

  React.useEffect(() => {
    if (window && window.location) {
      const url = Linking.parse(window.location.href);
      if (url.queryParams.token) {
        setToken(url.queryParams.token);
        setStep("reset");
      }
      if (url.queryParams.verified) {
        theme.alert({
          type: "success",
          message: "E-Mail erfolgreich verifiziert",
        });
      }
    }
  }, []);

  const buttonLabels = {
    login: "Login",
    code: "Warte auf Code",
    reset: "Passwort speichern",
  };

  const labels = {
    login: "Bitte melden Sie sich an",
    code: "Ihr Code ist auf dem Weg",
    reset: "Passwort zurücksetzen",
  };

  const localAuth = async (email, password) => {
    let result = await authRef.current.authenticateAsync();
    setLoading(true);
    console.log("Scan Result:", result);
    if (result.success === true) {
      if ((email, password)) {
        AsyncStorage.setItem("useLocalAuth", "true");
        SecureStore.setItemAsync(
          "UserLogin",
          JSON.stringify({
            email: email,
            password: password,
          })
        );
      } else {
        SecureStore.getItemAsync("UserLogin").then((val) => {
          console.log("SecureStore", val);
          if (val) {
            const UserLogin = JSON.parse(val);
            loginUser({ ...UserLogin, localAuth: true });
          } else {
            AsyncStorage.setItem("useLocalAuth", "false");
            theme.alert({ type: "error", message: "Abgelaufen" });
            setLoading(false);
          }
        });
      }
    } else {
      setLoading(false);
    }
  };

  const askForLocalAuth = () => {
    Alert.alert(
      "Möchten Sie sich in Zukunft mit FaceId oder Fingerabdruck einloggen?",
      "",
      [
        {
          text: "Ja",
          onPress: () => {
            authRef.current.useAuth(true);
            // this.localAuth(this.state.email, this.state.password);
          },
        },
        { text: "Nein", onPress: () => console.log("Cancel"), style: "cancel" },
      ]
    );
  };

  const resetPassword = ({ password, checkPassword }) => {
    setLoading(true);
    if (password === checkPassword) {
      client
        .mutate({
          mutation: RESET_PASSWORD_MUTATION,
          variables: {
            token: token,
            password: password,
          },
        })
        .then((result) => {
          theme.alert({
            type: "success",
            message: "Passwort gepeichert",
          });
          setLoading(false);
          setStep("login");
          setToken(null);
          if (isWeb === "web") {
            window.location.href = window.location.origin;
          }
        })
        .catch((error) => {
          setLoading(false);
          theme.alert({
            type: "error",
            message: i18n.t(
              `error.${error.message.replace("GraphQL error: ", "")}`
            ),
          });
        });
    } else {
      theme.alert({
        type: "error",
        message: "Passwörter stimmen nicht überein",
      });
      setLoading(false);
    }
  };

  const loginUser = ({ email, password, code, localAuth }) => {
    setLoading(true);
    client
      .mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          email: email ? email.toLowerCase() : undefined,
          password: password,
          code: code,
          localAuth: localAuth,
        },
      })
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          theme.alert({
            type: "error",
            message: i18n.t(
              `error.${result.errors[0].message.replace("GraphQL error: ", "")}`
            ),
          });
          setLoading(false);
        } else {
          console.log("Login", result);
          if (!code && !localAuth) {
            setStep("code");
            setLastAttempt(new Date());
            setLoading(false);
            theme.alert({
              type: "success",
              message: `Ihr Code ist auf dem Weg`,
            });
          } else {
            AsyncStorage.setItem("token", result.data.login).then((x) =>
              AsyncStorage.getItem("token").then((val) => {
                console.log(val, client);
                client.resetStore().then(() => {
                  console.log("reset store");
                });
              })
            );
            AsyncStorage.setItem("userEmail", email);
            if (
              authRef.current &&
              !authRef.current.active &&
              authRef.current.ready
            ) {
              askForLocalAuth();
            }
          }
        }
      })
      .catch((error) => {
        theme.alert({
          type: "error",
          message: i18n.t(
            `error.${error.message.replace("GraphQL error: ", "")}`
          ),
        });
        setLoading(false);
      });
  };

  return (
    <Page
      style={{ backgroundColor: "primary" }}
      background={require("../assets/images/bg.jpg")}
      scrollable
      keyboardAware
      style={{ flex: 1, width: "100%" }}
      bounces={false}
      contentContainerStyle={{
        flex: 1,
      }}
    >
      <Flex flexCenter flex={1}>
        <Flex flexCenter w="100%" px={30} maxWidth={600}>
          <>
            <Avatar
              size={90}
              shadow={5}
              source={require("../assets/images/icon.png")}
              roundness={25}
              mb={20}
            />
            {userLoading ? (
              <Flex flexCenter w="100%">
                <Progress
                  progressColor="#FFF"
                  size={60}
                  trackWidth={5}
                  progressWidth={5}
                  loading
                />
              </Flex>
            ) : (
              <>
                <Text color="#FFF" mb={15}>
                  {labels[step]}
                </Text>
                <Form
                  defaultDoc={{
                    email: "",
                    password: "",
                  }}
                  button={false}
                  ref={formRef}
                  onChange={(doc) => {
                    if (doc.code && doc.code.length === 6) {
                      formRef.current.submit();
                    }
                  }}
                  onSubmit={(doc) => {
                    const { email, password, checkPassword, code } = doc;
                    if (step === "reset") {
                      resetPassword({
                        password,
                        checkPassword,
                      });
                    } else if (step === "code" && code?.length === 6) {
                      loginUser({
                        email,
                        password,
                        code,
                      });
                    } else if (step === "login") {
                      if (!email || (email && email.length === 0)) {
                        theme.alert({
                          type: "error",
                          message: "Bitte geben Sie eine valide E-Mail an",
                        });
                      } else {
                        loginUser({
                          email,
                          password,
                        });
                      }
                    }
                  }}
                >
                  {step === "login" ? (
                    <>
                      <Input
                        type="text"
                        placeholder="E-Mail"
                        label="E-Mail"
                        labelProps={{ color: "#FFF" }}
                        field="email"
                        keyboardType="email-address"
                        textContentType="emailAddress"
                        autoCapitalize="none"
                      />
                      <Input
                        type="password"
                        placeholder="Passwort"
                        label="Passwort"
                        labelProps={{ color: "#FFF" }}
                        field="password"
                        mt={10}
                      />
                    </>
                  ) : null}
                  {step === "code" ? (
                    <>
                      <CodeField field="code" autoFocus />
                    </>
                  ) : null}
                  {step === "reset" ? (
                    <>
                      <Input
                        type="password"
                        placeholder="Passwort"
                        label="Passwort"
                        field="password"
                      />
                      <Input
                        type="password"
                        placeholder="Passwort widerholen"
                        label="Passwort widerholen"
                        field="checkPassword"
                        mt={10}
                      />
                    </>
                  ) : null}
                </Form>
                <Button
                  w="100%"
                  size={50}
                  mt={20}
                  onPress={() => {
                    if (formRef.current) formRef.current.submit();
                  }}
                  loading={loading}
                >
                  {buttonLabels[step]}
                </Button>
                {authRef.current &&
                authRef.current.active &&
                authRef.current.ready ? (
                  <>
                    <Button
                      w="100%"
                      size={50}
                      style={{ marginTop: 20 }}
                      onPress={() => {
                        localAuth();
                      }}
                      loading={loading}
                    >
                      Login mit FaceId
                    </Button>
                    <Button
                      bg="surface"
                      color="primary"
                      w="100%"
                      size={50}
                      style={{ marginTop: 10 }}
                      onPress={() => {
                        authRef.current.useAuth(false);
                        if (!isWeb) {
                          SecureStore.deleteItemAsync("UserLogin");
                        }
                      }}
                    >
                      Account wechseln
                    </Button>
                  </>
                ) : null}
              </>
            )}
          </>
        </Flex>
      </Flex>
      <Flex flexCenter r={0} b={0} l={0} h={50} px={15} absolute>
        <Text color="#FFF" font="label">{`Version: ${pkg.version}`}</Text>
      </Flex>
    </Page>
  );
};

export default LoginScreen;
