import PatientSchema from "./patient";
import TherapysSchema from "./therapy";
import SettingsSchema from "./settings";
import MaterialsSchema from "./materials";
import MaterialsGroupSchema from "./materialGroup";
import ContactsSchema from "./contacts";
import AppointmentsSchema from "./appointments";
import EvidencesSchema from "./documents/evidence";
import AdvicesSchema from "./documents/advice";
import AnamnesenSchema from "./documents/anamnese";
import OrdersSchema from "./documents/order";
import DeliveryNotesSchema from "./documents/deliveryNote";
import DoctorsLettersSchema from "./documents/doctorsLetter";
import OtherDocumentsSchema from "./documents/documents";
import WoundImagesSchema from "./documents/woundImages";
import ActionsSchema from "./actions";
import SharesSchema from "./shares";
import UserSchema from "./user";
import SimpleSchema from "./simple";

export {
  SimpleSchema,
  PatientSchema,
  TherapysSchema,
  SettingsSchema,
  MaterialsSchema,
  MaterialsGroupSchema,
  ContactsSchema,
  AppointmentsSchema,
  EvidencesSchema,
  AdvicesSchema,
  AnamnesenSchema,
  DeliveryNotesSchema,
  DoctorsLettersSchema,
  OtherDocumentsSchema,
  WoundImagesSchema,
  ActionsSchema,
  SharesSchema,
  UserSchema,
  OrdersSchema,
};

const SchemaByType = {
  Patient: PatientSchema,
  Therapy: TherapysSchema,
  Settings: SettingsSchema,
  Contact: ContactsSchema,
  Material: MaterialsSchema,
  Appointment: AppointmentsSchema,
  Evidence: EvidencesSchema,
  Anamnese: AnamnesenSchema,
  WoundImage: WoundImagesSchema,
  Document: OtherDocumentsSchema,
  Advice: AdvicesSchema,
  MaterialGroup: MaterialsGroupSchema,
  DoctorsLetter: DoctorsLettersSchema,
  User: UserSchema,
  DeliveryNote: DeliveryNotesSchema,
  Action: ActionsSchema,
  Share: SharesSchema,
  Order: OrdersSchema,
};

const blacklisted = ["imageMeasure", "docDiff", "services", "twoFactorCode"];

const getSchemaByType = (typename) => {
  let typeDef = `
    _id
    __typename
    `;
  Object.keys(SchemaByType[typename]).map((key) => {
    if (
      key.indexOf("$") === -1 &&
      key.indexOf(".") === -1 &&
      blacklisted.indexOf(key) === -1
    ) {
      typeDef += ` ${key}`;
    }
  });
  return {
    schema: SchemaByType[typename],
    typeDef,
  };
};

export default getSchemaByType;
