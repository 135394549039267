import * as React from "react";
import { Icon, Button, Text } from "unikit";
import dayjs from "dayjs";

import { Page, ListItem, ListView, Form } from "../../components";

import { useAppContext } from "../../AppContext";

export default function Screen({ navigation }) {
  const { user, call } = useAppContext();
  const [warehouse, setWarehouse] = React.useState("warehouse");
  const [doc, setDoc] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const warehouseReports = user.stats?.warehouseReports || {};

  const team = [user, ...(user.team || [])];

  return (
    <Page title="Warenbestand" scrollView>
      <Text font="h5" px={15} pt={10} bold>
        Wareneinkaufstatistiken generieren
      </Text>
      <Form
        loading={loading}
        buttonText="PDF generieren"
        buttonProps={{
          w: "100%",
          mt: 10,
          renderLeft: <Icon name="printer" color="#FFF" size={22} mr={7} />,
        }}
        navigation={navigation}
        defaultDoc={{
          from: dayjs().startOf("year").toDate(),
          to: dayjs().toDate(),
        }}
        onChange={(doc) => {
          setDoc({ ...doc });
        }}
        onSave={(doc, setDoc) => {
          setLoading(true);
          call({
            name: "analyzePurchasing",
            config: { ...doc },
          })
            .then((result) => {
              console.log({ result });
              setLoading(false);
              navigation.navigate("PDF", {
                url: result.data.callMeteor.result.Location,
              });
            })
            .catch((error) => {
              console.log({ error });
            });
        }}
        schema={[
          {
            key: "contactId",
            label: "Lieferant",
            input: "NewDocList",
            optional: false,
            group: "Lieferant",
            type: "Contact",
            allowSearch: true,
            full: false,
            multiple: false,
            title: (item) => {
              return `${item.name}, ${item.typ}`;
            },
            desc: (item) => {
              return `Telefon: ${item.phone}, Fax: ${item.fax} - ${item.address}, ${item.zipCode} ${item.residence}`;
            },
          },
          {
            key: "from",
            label: "Von",
            input: "Date",
            group: "Zeitraum wählen",
            optional: false,
          },
          {
            key: "to",
            label: "Bis",
            input: "Date",
            group: "Zeitraum wählen",
            optional: false,
          },
        ]}
      />
      {/* <Text p={15}>Lager</Text> */}
      {/* <ScrollView style={{ paddingHorizontal: 10 }} horizontal>
        <Touchable
          opacity={warehouse === "warehouse" ? 1 : 0.5}
          onPress={() => setWarehouse("warehouse")}
          mr={10}
        >
          <Avatar
            source={{
              uri:
                "https://images.unsplash.com/photo-1592085198739-ffcad7f36b54?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
            }}
            size={75}
            char={`ZL`}
          />
        </Touchable>
        <Flex mr={15} ml={5} w={1} h={75} bg="text" opacity={0.2} />
        {team.map((user) => {
          return (
            <Touchable
              opacity={warehouse === user._id ? 1 : 0.5}
              onPress={() => setWarehouse(user._id)}
              mr={10}
            >
              <Avatar
                size={75}
                source={user.image ? { uri: user.image.url } : undefined}
                char={`${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`}
                bg={user.color}
              />
            </Touchable>
          );
        })}
      </ScrollView> */}
      {/* <Text p={15}>Zeitraum wählen</Text> */}
      {/* <Group>
        <Input type="date" value={from} onChange={(d) => setFrom(d)} />
        <Input type="date" value={to} onChange={(d) => setTo(d)} />
      </Group> */}
      <Text font="h5" px={15} pt={15} pb={2} bold>
        Bestand Zentrallager monatlich
      </Text>
      <Text font="p" px={15} pb={15}>
        Automatisch generiert
      </Text>
      <Button
        mx={15}
        renderLeft={<Icon name="printer" color="#FFF" size={22} mr={7} />}
        onPress={() => {
          call({
            name: "analyzeWarehouse",
            config: { userId: user._id },
          })
            .then((result) => {
              console.log({ result });
              navigation.navigate("PDF", {
                url: result.data.callMeteor.result.Location,
              });
            })
            .catch((error) => {
              console.log({ error });
            });
        }}
      >
        PDF aktueller Bestand
      </Button>
      <ListView
        groupBy="year"
        data={Object.values(warehouseReports)}
        renderItem={({ item, index }) => {
          return (
            <ListItem
              title={`${item.month || "Report"}`}
              onPress={() => {
                navigation.navigate("PDF", {
                  url: item.Location,
                });
              }}
              actions={[
                {
                  icon: "fileText",
                  label: "PDF anzeigen",
                  onPress: () => {
                    navigation.navigate("PDF", {
                      url: item.Location,
                    });
                  },
                },
              ]}
            />
          );
        }}
      />
    </Page>
  );
}
