import React, { useState, Fragment, useEffect } from "react";
import { Dimensions, TouchableOpacity, Platform } from "react-native";
import { Flex, Button, Tabs, Image } from "unikit";
import Svg, { Circle, Line, Polygon } from "react-native-svg";

import { WoundImageUtil } from "../../helper";
import useForceUpdate from "./useForceUpdate";

export default ({ value, inputKey, uploadFile, onChange, multiple, doc }) => {
  const forceUpdate = useForceUpdate();
  const [loaded, setLoaded] = useState(false);
  const [state, setState] = useState({
    mode: "referenceLengthPath",
    viewBox: "0 0 100 100",
    width: 0,
    height: 0,
  });
  const { width, height } = Dimensions.get("window");

  const getSurface = (data) => {
    var points = "";
    data.map(function (data) {
      points += data.posX * 100 + " " + data.posY * 100 + " ";
    });
    return points;
  };

  const getStrokeWidth = () => {
    if (width > 1025) {
      return 0.25;
    } else {
      return 0.5;
    }
  };

  const getRadius = () => {
    if (width > 1025) {
      return 0.5;
    } else {
      return 0.75;
    }
  };

  const updateMeasurements = (nextProps) => {
    doc = nextProps && nextProps.doc ? nextProps.doc : doc;
    if (doc.referenceLength && doc.referenceLengthPath.length === 2) {
      var relRefLength = WoundImageUtil.calculateRelativeDistance(
        doc.referenceLengthPath[0],
        doc.referenceLengthPath[1]
      );

      if (doc.lengthPath.length === 2) {
        var relLength = WoundImageUtil.calculateRelativeDistance(
          doc.lengthPath[0],
          doc.lengthPath[1]
        );
        var absLength = parseFloat(
          WoundImageUtil.calculateAbsoluteDistance(
            relLength,
            relRefLength,
            doc.referenceLength
          )
        ).toFixed(2);
        if (absLength != doc.length) {
          onChange(absLength, "length");
        }
      }

      if (doc.widthPath.length === 2) {
        var relWidth = WoundImageUtil.calculateRelativeDistance(
          doc.widthPath[0],
          doc.widthPath[1]
        );
        var absWidth = parseFloat(
          WoundImageUtil.calculateAbsoluteDistance(
            relWidth,
            relRefLength,
            doc.referenceLength
          )
        ).toFixed(2);
        if (absWidth !== doc.width) {
          onChange(absWidth, "width");
        }
      }

      if (doc.surfacePath.length > 2) {
        var absSurface = parseFloat(
          WoundImageUtil.calculateArea(
            doc.surfacePath,
            relRefLength,
            doc.referenceLength
          )
        ).toFixed(2);
        if (absSurface !== doc.surface) {
          onChange(absSurface, "surface");
        }
      }
    }
  };

  const getPoint = (e) => {
    let { locationX, layerX, locationY, layerY } = e.nativeEvent;
    var obj = {
      posX: locationX || layerX,
      posY: locationY || layerY,
    };
    obj = WoundImageUtil.makeRelativeToScope(obj, state.width, state.height);
    if (
      state.mode === "referenceLengthPath" &&
      doc.referenceLengthPath &&
      doc.referenceLengthPath.length < 2
    ) {
      onChange(doc.referenceLengthPath.concat(obj), "referenceLengthPath");
      if (doc.referenceLengthPath.length === 2) {
        setState({ ...state, mode: "lengthPath" });
      }
    }
    if (state.mode === "lengthPath" && doc.lengthPath.length < 2) {
      onChange(doc.lengthPath.concat(obj), "lengthPath");
      if (doc.lengthPath.length === 2) {
        setState({ ...state, mode: "widthPath" });
      }
    }
    if (state.mode === "widthPath" && doc.widthPath.length < 2) {
      onChange(doc.widthPath.concat(obj), "widthPath");
      if (doc.widthPath.length === 2) {
        setState({ ...state, mode: "surfacePath" });
      }
    }
    if (state.mode === "surfacePath") {
      onChange(doc.surfacePath.concat(obj), "surfacePath");
    }

    forceUpdate();

    setTimeout(() => {
      updateMeasurements();
      forceUpdate();
    }, 1000);
  };

  const calculateViewBox = () => {
    var asp =
      state.width && state.height
        ? Math.round((state.height / state.width) * 100)
        : 100;
    var viewBox = "0 0 " + 100 + " " + asp;
    return viewBox;
  };

  const renderPath = (path, color) => {
    if (!path) return null;
    return (
      <Fragment>
        {path.map((data) => {
          return (
            <Circle
              key={Math.random()}
              cx={data.posX * 100}
              cy={data.posY * 100}
              r={getRadius()}
              fill={color}
            />
          );
        })}
        {path.length > 1 ? (
          <Line
            key={Math.random()}
            x1={path[0].posX * 100}
            y1={path[0].posY * 100}
            x2={path[1].posX * 100}
            y2={path[1].posY * 100}
            stroke={color}
            strokeWidth={getStrokeWidth()}
          />
        ) : null}
      </Fragment>
    );
  };

  useEffect(() => {
    updateMeasurements();
  }, [doc.referenceLength]);

  return (
    <Flex w="100%" p={15} mb={7.5} align="center" bg="background">
      <Flex w="100%" style={{ maxWidth: 800 }}>
        <Tabs
          indicatorSize={3}
          value={state.mode}
          options={[
            { label: "Referenzlänge", value: "referenceLengthPath" },
            { label: "Wundlänge", value: "lengthPath" },
            { label: "Wundbreite", value: "widthPath" },
            { label: "Wundfläche", value: "surfacePath" },
          ]}
          onChange={(value) => setState({ ...state, mode: value })}
          width="100%"
        />

        <Flex
          key={
            doc.image
              ? `${doc.image.url}-${loaded ? "true" : "false"}`
              : "image"
          }
          as={TouchableOpacity}
          activeOpacity={1}
          position="relative"
          width="100%"
          onPress={getPoint}
          onLayout={({ nativeEvent }) => {
            setState({
              ...state,
              width: nativeEvent.layout.width,
              height: nativeEvent.layout.height,
            });
          }}
        >
          {doc.image ? (
            <Image source={{ uri: doc.image.url }} width="100%" />
          ) : null}

          <Svg
            height={"100%"}
            width="100%"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 2000,
            }}
            viewBox={calculateViewBox()}
            pointerEvents="none"
          >
            {renderPath(doc.referenceLengthPath, "rgb(96, 125, 139)")}
            {renderPath(doc.lengthPath, "rgb(33, 150, 243)")}
            {renderPath(doc.widthPath, "rgb(76, 175, 80)")}

            {doc.surfacePath && (
              <Polygon
                key={Math.random()}
                points={getSurface(doc.surfacePath)}
                fill={"rgba(255, 152, 0, 0.2)"}
                stroke={"rgb(255, 152, 0)"}
                strokeWidth={getStrokeWidth()}
              />
            )}
            {doc.surfacePath &&
              doc.surfacePath.map((data) => {
                return (
                  <Circle
                    key={Math.random()}
                    cx={data.posX * 100}
                    cy={data.posY * 100}
                    r={getRadius()}
                    fill={"rgb(255, 152, 0)"}
                  />
                );
              })}
          </Svg>
        </Flex>
        <Flex w="100%" p={15} row>
          <Button
            onPress={() => {
              onChange([], state.mode);
              forceUpdate();
            }}
          >
            Messung verwerfen
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
