import SimpleSchema from "../simple";

const schema = {
  surveyDate: {
    type: Date,
    label: "Erhebungsdatum",
    defaultValue: function () {
      return new Date();
    },
    input: "DatePicker",
    group: "Allgemeine Erhebungsdaten",
  },
  name: {
    type: String,
    label: "Dokumentenname",
    max: 100,
    group: "Allgemeine Erhebungsdaten",
    defaultValue: (props, state) => {
      if (props && props.count === 0) {
        return "Anamnese";
      } else if (props && props.count) {
        return props.count + 1 + ". Erhebungsanalyse";
      }
    },
  },
  firstTherapy: {
    type: Boolean,
    label: "Erstbehandlung",
    optional: true,
    input: "Toggle",
    group: "Allgemeine Erhebungsdaten",
  },
  age: {
    type: Date,
    label: "Alter der Wunde",
    defaultValue: function () {
      return new Date();
    },
    input: "TimeAgo",
    group: "Allgemeine Erhebungsdaten",
  },
  rezidiv: {
    type: String,
    label: "Rezidiv",
    optional: true,
    input: "Select",
    options: [
      { label: "Nicht rezidiv", value: "nicht_rezidiv" },
      { label: "Erste", value: "erste" },
      { label: "Zweite", value: "zweite" },
      { label: "Dritte", value: "dritte" },
      { label: "Vierte", value: "vierte" },
      { label: "Fünfte", value: "fünfte" },
      { label: "Sechste", value: "sechste" },
      { label: "Siebte", value: "siebte" },
      { label: "Achte", value: "achte" },
      { label: "Neunte", value: "neunte" },
      { label: "Zehnte oder häufiger", value: "zehnte" },
    ],
    group: "Allgemeine Erhebungsdaten",
  },
  traditionalTherapy: {
    type: Boolean,
    label: "Bisherige Therapie",
    optional: true,
    input: "Toggle",
    group: "Bisherige Therapieoptionen",
  },
  traditionalTherapyNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    max: 1000,
    input: "Textarea",
    group: "Bisherige Therapieoptionen",
    visible: "traditionalTherapy",
  },
  systemicTherapy: {
    type: Boolean,
    label: "Systemische Therapie",
    optional: true,
    input: "Toggle",
    group: "Bisherige Therapieoptionen",
  },
  systemicTherapyNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    max: 1000,
    input: "Textarea",
    group: "Bisherige Therapieoptionen",
    visible: "systemicTherapy",
  },
  localTherapy: {
    type: Boolean,
    label: "Lokale Therapie",
    optional: true,
    input: "Toggle",
    group: "Bisherige Therapieoptionen",
  },
  localTherapyNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    input: "Textarea",
    group: "Bisherige Therapieoptionen",
    visible: "localTherapy",
  },
  pavk: {
    type: Boolean,
    label: "PAVK",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
  },
  insuffizienz: {
    type: Boolean,
    label: "chron. Venöse Insuffizienz",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
  },
  heartDiseases: {
    type: Boolean,
    label: "Herzerkrankungen",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
  },
  diabetes: {
    type: Boolean,
    label: "Diabetes mellitus",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
  },
  diabetesHBA1C: {
    type: Boolean,
    label: "HBA1C",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "diabetes",
    subKey: "diabetesHBA1CValue",
  },
  diabetesHBA1CValue: {
    type: String,
    label: "HBA1C Wert",
    input: "Text",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "diabetesHBA1C",
    listedPDF: false,
  },
  diabetesTIPTHERM: {
    type: Boolean,
    label: "Tip Therm",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "diabetes",
    subKey: "diabetesTIPTHERMValue",
  },
  diabetesTIPTHERMValue: {
    type: String,
    label: "Tip Therm Wert",
    input: "Text",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "diabetesTIPTHERM",
    listedPDF: false,
  },
  diabetesMONOFILAMENT: {
    type: Boolean,
    label: "Monofilament",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "diabetes",
    subKey: "diabetesMONOFILAMENTValue",
  },
  diabetesMONOFILAMENTValue: {
    type: String,
    label: "Monofilament Wert",
    input: "Text",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "diabetesMONOFILAMENT",
    listedPDF: false,
  },
  diabetesSTIMMGABEL: {
    type: Boolean,
    label: "Stimmgabel",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "diabetes",
    subKey: "diabetesSTIMMGABELValue",
  },
  diabetesSTIMMGABELValue: {
    type: String,
    label: "Stimmgabel Wert",
    input: "Text",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "diabetesSTIMMGABEL",
    listedPDF: false,
  },
  diabetesNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    max: 1000,
    input: "Textarea",
    group: "Vorliegende Diagnose",
    visible: "diabetes",
  },
  gicht: {
    type: Boolean,
    label: "Gicht",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
  },
  infectiveDiseases: {
    type: Boolean,
    label: "infektiöse Erkrankung",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
  },
  hepatitis: {
    type: Boolean,
    label: "Hepatitis",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
  },
  hepatitisNote: {
    type: String,
    label: "Anmerkung",
    max: 200,
    optional: true,
    input: "Textarea",
    group: "Vorliegende Diagnose",
    visible: "hepatitis",
  },
  hiv: {
    type: Boolean,
    label: "HIV",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
  },
  allergy: {
    type: Boolean,
    label: "Allergie",
    group: "Vorliegende Diagnose",
    input: "Toggle",
    defaultValue: false,
    optional: true,
  },
  allergyNote: {
    type: String,
    label: "Gegen?",
    max: 200,
    optional: true,
    input: "Textarea",
    group: "Vorliegende Diagnose",
    visible: "allergy",
  },
  otherDiagnostics: {
    type: Boolean,
    label: "Andere Diagnosen",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
  },
  otherDiagnosticsNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    input: "Textarea",
    group: "Vorliegende Diagnose",
    visible: "otherDiagnostics",
  },
  doctorsReport: {
    type: Boolean,
    label: "Arztbericht liegt vor",
    input: "Toggle",
    optional: true,
    group: "Vorliegende Diagnose",
  },
  doctorsReportDate: {
    type: Date,
    label: "Arztbericht Datum",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "doctorsReport",
    input: "DatePicker",
  },
  doctorsReportNote: {
    type: String,
    label: "Arztbericht Inhalt",
    optional: true,
    input: "Textarea",
    group: "Vorliegende Diagnose",
    visible: "doctorsReport",
  },
  histology: {
    type: Boolean,
    label: "Histologie liegt vor",
    optional: true,
    group: "Vorliegende Diagnose",
    input: "Toggle",
  },
  histologyDate: {
    type: Date,
    label: "Histologie Datum",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "histology",
    input: "DatePicker",
  },
  histologyNote: {
    type: String,
    label: "Histologie Inhalt",
    optional: true,
    input: "Textarea",
    group: "Vorliegende Diagnose",
    visible: "histology",
  },
  swab: {
    type: Boolean,
    label: "Abstrich liegt vor",
    optional: true,
    group: "Vorliegende Diagnose",
    input: "Toggle",
  },
  swabDate: {
    type: Date,
    label: "Abstrich Datum",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "swab",
    input: "DatePicker",
  },
  swabNote: {
    type: String,
    label: "Abstrich Inhalt",
    optional: true,
    input: "Textarea",
    group: "Vorliegende Diagnose",
    visible: "swab",
  },
  swabMRSA: {
    type: Boolean,
    label: "MRSA",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "swab",
    input: "Toggle",
  },
  swabESBL: {
    type: Boolean,
    label: "ESBL",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "swab",
    input: "Toggle",
  },
  vascularDiagnostics: {
    type: Boolean,
    label: "Gefäßdiagn. liegt vor",
    optional: true,
    group: "Vorliegende Diagnose",
    input: "Toggle",
  },
  vascularDiagnosticsDate: {
    type: Date,
    label: "Gefäßdiagn. Datum",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "vascularDiagnostics",
    input: "DatePicker",
  },
  vascularDiagnosticsNote: {
    type: String,
    label: "Gefäßdiagn. Inhalt",
    optional: true,
    input: "Textarea",
    group: "Vorliegende Diagnose",
    visible: "vascularDiagnostics",
  },
  moreDiagnostics: {
    type: Boolean,
    label: "Weitere Diagn. liegt vor",
    optional: true,
    group: "Vorliegende Diagnose",
    input: "Toggle",
  },
  moreDiagnosticsDate: {
    type: Date,
    label: "Weitere Diagn. Datum",
    optional: true,
    group: "Vorliegende Diagnose",
    visible: "moreDiagnostics",
    input: "DatePicker",
  },
  moreDiagnosticsNote: {
    type: String,
    label: "Weitere Diagn. Inhalt",
    optional: true,
    input: "Textarea",
    group: "Vorliegende Diagnose",
    visible: "moreDiagnostics",
  },
  size: {
    type: Number,
    label: "Größe(cm)",
    optional: true,
    input: "Number",
    group: "Körpergröße und -gewicht",
  },
  weight: {
    type: Number,
    label: "Gewicht(kg)",
    optional: true,
    input: "Number",
    group: "Körpergröße und -gewicht",
  },
  BMI: {
    type: String,
    label: "BMI",
    optional: true,
    options: [
      { label: "< 20 Untergewicht", value: "untergewicht" },
      { label: "20-25 Normalgewicht", value: "normalgewicht" },
      { label: "26-29 leichtes Übergewicht", value: "leichtes_übergewicht" },
      { label: "30-38 Adipositas", value: "adipositas" },
      { label: "> 38 schwere Adipositas", value: "schwere_adipositas" },
    ],
    group: "Körpergröße und -gewicht",
    input: "Select",
  },
  smoker: {
    type: Boolean,
    label: "Raucher?",
    optional: true,
    group: "Körpergröße und -gewicht",
    input: "Toggle",
  },
  smokeCount: {
    type: Number,
    label: "Anzahl pro Tag",
    optional: true,
    input: "Number",
    group: "Körpergröße und -gewicht",
    visible: "smoker",
  },
  otherDrugs: {
    type: Boolean,
    label: "Alkohol oder andere Drogen?",
    optional: true,
    group: "Körpergröße und -gewicht",
    input: "Toggle",
  },
  otherDrugsNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    max: 1000,
    input: "Textarea",
    group: "Körpergröße und -gewicht",
  },
  edema: {
    type: Boolean,
    label: "Ödem",
    optional: true,
    group: "Beine",
    input: "Toggle",
  },
  scopeLeft: {
    type: String,
    label: "Umfang links(cm)",
    optional: true,
    group: "Beine",
  },
  scopeRight: {
    type: String,
    label: "Umfang rechts(cm)",
    optional: true,
    group: "Beine",
  },
  compressionTherapy: {
    type: Boolean,
    label: "Kompressionstherapie",
    optional: true,
    group: "Kompressionstherapie",
    input: "Toggle",
  },
  compressionStockings: {
    type: Boolean,
    label: "Kompressionsstrümpfe",
    optional: true,
    group: "Kompressionstherapie",
    input: "Toggle",
  },
  compressionBandage: {
    type: Boolean,
    label: "Kompressionsverband",
    optional: true,
    group: "Kompressionstherapie",
    input: "Toggle",
  },
  compressionTherapyNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    max: 1000,
    input: "Textarea",
    group: "Kompressionstherapie",
  },
  position: {
    type: Boolean,
    label: "Lagerung",
    optional: true,
    group: "Lagerung",
    input: "Toggle",
  },
  positionType: {
    type: String,
    label: "Art der Lagerung",
    optional: true,
    group: "Lagerung",
    visible: "position",
  },
  frequency: {
    type: String,
    label: "Frequenz",
    max: 100,
    optional: true,
    group: "Lagerung",
    visible: "position",
  },
  positionProblems: {
    type: Boolean,
    label: "Probleme bei der Lagerung",
    optional: true,
    group: "Lagerung",
    input: "Toggle",
  },
  positionProblemsNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    group: "Lagerung",
    visible: "positionProblems",
  },
  dietState: {
    type: String,
    label: "Ernährungsstatus",
    optional: true,
    options: [
      { label: "Gut", value: "normal" },
      { label: "Kachexie", value: "kachexie" },
      { label: "Adipositas", value: "adipositas" },
    ],
    input: "Select",
    group: "Ernährung",
  },
  dietNote: {
    type: String,
    label: "Bemerkung",
    optional: true,
    group: "Ernährung",
  },
  weightLoss: {
    type: String,
    label: "Gewichtsverlust",
    optional: true,
    options: [
      { label: "Kein Gewichtsverlust", value: "kein_gewichtsverlust" },
      { label: "0 - 3kg", value: "0-3" },
      { label: "3 - 6kg", value: "3-6" },
      { label: "6 - 10kg", value: "6-10" },
      { label: "> 10kg", value: ">10" },
    ],
    input: "Select",
    group: "Ernährung",
  },
  appetite: {
    type: String,
    label: "Appetit",
    optional: true,
    options: [
      { label: "Guter Appetit", value: "gut" },
      { label: "Leidlicher Appetit", value: "leidlich" },
      { label: "Kein Appetit", value: "kein" },
    ],
    input: "Select",
    group: "Ernährung",
  },
  ingestion: {
    type: String,
    label: "Nahrungsaufnahme",
    optional: true,
    options: [
      { label: "Problemlos", value: "problemlos" },
      {
        label: "Probleme durch Übelkeit und/oder Schluckschwierigkeiten",
        value: "probleme",
      },
      { label: "Keine orale Nahrungsaufnahme möglich", value: "keine" },
    ],
    input: "Select",
    group: "Ernährung",
  },
  additionalDiet: {
    type: Boolean,
    label: "Zusatzernährung",
    optional: true,
    group: "Durchgeführte Maßnahmen",
    input: "Toggle",
  },
  infusion: {
    type: Boolean,
    label: "Infusion",
    optional: true,
    group: "Durchgeführte Maßnahmen",
    input: "Toggle",
  },
  additionalMeasures: {
    type: String,
    label: "Weitere Maßnahmen",
    optional: true,
    group: "Durchgeführte Maßnahmen",
  },
  lifeAndTreatment: {
    type: Object,
    blackbox: true,
    label: "Lebensqualität",
    optional: true,
    input: "LifeAndTreatment",
    group: "Lebensqualität",
    inputOptions: {
      hidelabel: true,
    },
    options: [
      { label: "Psychologische Stimmung", value: "phsychologicalMood" },
      { label: "Schlafqualität", value: "sleepQuality" },
      { label: "Soziale Kontake", value: "socialContacts" },
      { label: "Körperliche Belastbarkeit", value: "professionalResilience" },
    ],
  },
  lifeAndTreatmentNote: {
    type: String,
    label: "Bemerkung",
    optional: true,
    group: "Lebensqualität",
  },
  mobilityState: {
    type: String,
    label: "Mobilitätsstatus",
    optional: true,
    options: [
      { label: "Voll mobil", value: "mobil" },
      { label: "Mit Hilfsmittel", value: "hilfsmittel" },
      { label: "Immobil", value: "immobil" },
    ],
    input: "Select",
    group: "Mobilität",
  },
  toolsType: {
    type: String,
    label: "Art der Hilfsmittel",
    optional: true,
    group: "Mobilität",
  },
  mobilityNote: {
    type: String,
    label: "Bemerkung",
    optional: true,
    group: "Mobilität",
  },
  incontinence: {
    type: Boolean,
    label: "Inkontinenz",
    optional: true,
    input: "Toggle",
    group: "Inkontinenz",
  },
  incontinenceTools: {
    type: Array,
    label: "Hilfsmittel",
    optional: true,
    options: [
      { label: "Keine", value: "keine" },
      { label: "Schutzhose", value: "schutzhose" },
      { label: "Katheter", value: "katheter" },
      { label: "Windelhose", value: "windelhose" },
      { label: "Einlage", value: "einlage" },
    ],
    inputOptions: {
      multi: true,
      mode: "pills",
    },
    input: "Select",
    group: "Inkontinenz",
  },
  incontinenceNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    max: 1000,
    group: "Inkontinenz",
    input: "TextArea",
  },
  "incontinenceTools.$": { type: String, listed: false },
  stressFactor: {
    type: String,
    label: "Stressfaktor",
    optional: true,
    options: [
      { label: "Kein Stress", value: "no" },
      { label: "Leichter Stress - Infektzeichen", value: "ease" },
      { label: "Leichter Stress - Kl. chirug. Eingr.", value: "easeSecound" },
      {
        label: "Mäßiger Stress - Schwere Infektion/chronische Erkrankung",
        value: "moderate",
      },
      { label: "Starker Stress - Systemische Infektion", value: "strong" },
    ],
    input: "Select",
    group: "Stress",
    defaultValue: function () {
      return "no";
    },
  },
  stressFactorNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    max: 1000,
    group: "Stress",
  },
  cutFactor: {
    type: Boolean,
    label: "Schmerzen",
    optional: true,
    group: "Schmerz",
    input: "Toggle",
  },
  cutFactorSmarting: {
    type: Boolean,
    label: "Brennend",
    optional: true,
    group: "Schmerz",
    input: "Toggle",
    visible: "cutFactor",
  },
  cutFactorStabbing: {
    type: Boolean,
    label: "Stechend",
    optional: true,
    group: "Schmerz",
    input: "Toggle",
    visible: "cutFactor",
  },
  cutFactorPiercing: {
    type: Boolean,
    label: "Bohrend",
    optional: true,
    group: "Schmerz",
    input: "Toggle",
    visible: "cutFactor",
  },
  cutFactorHauling: {
    type: Boolean,
    label: "Ziehend",
    optional: true,
    group: "Schmerz",
    input: "Toggle",
    visible: "cutFactor",
  },
  cutFactorTapping: {
    type: Boolean,
    label: "Klopfend",
    optional: true,
    group: "Schmerz",
    input: "Toggle",
    visible: "cutFactor",
  },
  cutFactorNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    max: 1000,
    group: "Schmerz",
  },
  cutMeasuresLocal: {
    type: Boolean,
    label: "Lokale Schmerzmind.",
    optional: true,
    input: "Toggle",
    group: "Schmerz",
  },
  cutMeasuresSystem: {
    type: Boolean,
    label: "System. Schmerzther.",
    optional: true,
    input: "Toggle",
    group: "Schmerz",
  },
  cutMeasuresNote: {
    type: String,
    label: "Maßnahmen",
    optional: true,
    max: 1000,
    group: "Schmerz",
  },
  cutIntensity: {
    type: String,
    label: "Schmerzskala",
    optional: true,
    options: [
      { label: "1", value: "one" },
      { label: "2", value: "two" },
      { label: "3", value: "three" },
      { label: "4", value: "four" },
      { label: "5", value: "five" },
      { label: "6", value: "six" },
      { label: "7", value: "seven" },
      { label: "8", value: "eight" },
      { label: "9", value: "nine" },
      { label: "10", value: "ten" },
    ],
    input: "CutIntensity",
    group: "Subjektive Schmerzintensität",
    inputOptions: {
      hidelabel: true,
    },
  },
  localisation: {
    type: Object,
    optional: true,
    label: "Lokalisation der Wunde",
    group: "Lokalisation der Wunde",
    input: "Localisation",
    inputOptions: {
      hidelabel: true,
    },
  },
  "localisation.posX": {
    type: Number,
    label: "Position X",
    optional: true,
    group: "Lokalisation der Wunde",
    listed: false,
  },
  "localisation.posY": {
    type: Number,
    label: "Position Y",
    optional: true,
    group: "Lokalisation der Wunde",
    listed: false,
  },
  woundPocket: {
    type: Boolean,
    label: "Wundtasche",
    optional: true,
    input: "Toggle",
    group: "Lokalisation der Wunde",
  },
  undermining: {
    type: Boolean,
    label: "Unterminierung",
    optional: true,
    input: "Toggle",
    group: "Lokalisation der Wunde",
  },
  localisationNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    max: 1000,
    group: "Lokalisation der Wunde",
  },
  woundStatusData: {
    type: Object,
    options: [
      {
        label: "Schwarz",
        sublabel: "(Nekrose)",
        image: "/images/wundstatus1.png",
        name: "statusBlack",
        options: [
          { label: "Trocken", name: "statusBlackDry" },
          { label: "Feucht", name: "statusBlackDamp" },
          { label: "Nass", name: "statusBlackWet" },
          { label: "Fest", name: "statusBlackSolid" },
          { label: "Locker", name: "statusBlackLoose" },
        ],
      },
      {
        label: "Schwarz/Gelb",
        sublabel: "(Nekrose + Fibrinbelag)",
        image: "/images/wundstatus2.png",
        name: "statusBlackYellow",
        options: [
          { label: "Trocken", name: "statusBlackYellowDry" },
          { label: "Feucht", name: "statusBlackYellowDamp" },
          { label: "Nass", name: "statusBlackYellowWet" },
        ],
      },
      {
        label: "Schwarz/Gelb/Rot",
        sublabel: "(Nekrose + Fibrinbelag + Granulation)",
        image: "/images/wundstatus3.png",
        name: "statusBlackYellowRed",
        options: [
          { label: "Trocken", name: "statusBlackYellowRedDry" },
          { label: "Feucht", name: "statusBlackYellowRedDamp" },
          { label: "Nass", name: "statusBlackYellowRedWet" },
        ],
      },
      {
        label: "Gelb",
        sublabel: "(Fibrinbelag)",
        image: "/images/wundstatus4.png",
        name: "statusYellow",
        options: [
          { label: "Trocken", name: "statusYellowDry" },
          { label: "Feucht", name: "statusYellowDamp" },
          { label: "Nass", name: "statusYellowWet" },
        ],
      },
      {
        label: "Rot/Gelb",
        sublabel: "(Granulation + Fibrinbelag)",
        image: "/images/wundstatus5.png",
        name: "statusRedYellow",
        options: [
          { label: "Trocken", name: "statusRedYellowDry" },
          { label: "Feucht", name: "statusRedYellowDamp" },
          { label: "Nass", name: "statusRedYellowWet" },
        ],
      },
      {
        label: "Rot",
        sublabel: "(Granulation)",
        image: "/images/wundstatus6.png",
        name: "statusRed",
        options: [
          { label: "Trocken", name: "statusRedDry" },
          { label: "Feucht", name: "statusRedDamp" },
          { label: "Nass", name: "statusRedWet" },
        ],
      },
      {
        label: "Rot/Rosa",
        sublabel: "(Granulation + Epithelisation)",
        image: "/images/wundstatus7.png",
        name: "statusRedPink",
        options: [
          { label: "Trocken", name: "statusRedPinkDry" },
          { label: "Feucht", name: "statusRedPinkDamp" },
          { label: "Nass", name: "statusRedPinkWet" },
        ],
      },
      {
        label: "Rosarot",
        sublabel: "(Epithelisation)",
        image: "/images/wundstatus8.png",
        name: "statusPink",
        options: [
          { label: "Trocken", name: "statusPinkDry" },
          { label: "Normaler Hautzustand", name: "statusPinkNormal" },
          { label: "Dünn", name: "statusPinkThin" },
          { label: "Ekzematös", name: "statusPinkEkzematoes" },
        ],
      },
    ],
    label: "Wundstatus/Wundgrund oder -fläche",
    optional: true,
    blackbox: true,
    group: "Wundstatus/Wundgrund oder -fläche",
    input: "WoundStatus",
    inputOptions: {
      hidelabel: true,
    },
  },
  nekrose: {
    type: Number,
    label: "Nekrose",
    optional: true,
    max: 100,
    min: 0,
    input: "Range",
    defaultValue: function () {
      return 0;
    },
    inputOptions: {
      w: "100%",
      progressColor: "#000",
    },
    group: "Wundgrund/Wundfläche",
  },
  fibrin: {
    type: Number,
    label: "Fibrin",
    optional: true,
    max: 100,
    min: 0,
    input: "Range",
    defaultValue: function () {
      return 0;
    },
    inputOptions: {
      w: "100%",
      progressColor: "#ECBD00",
    },
    group: "Wundgrund/Wundfläche",
  },
  granulation: {
    type: Number,
    label: "Granulation",
    optional: true,
    max: 100,
    min: 0,
    input: "Range",
    defaultValue: function () {
      return 0;
    },
    group: "Wundgrund/Wundfläche",
  },
  epithelision: {
    type: Number,
    label: "Epithelisation",
    optional: true,
    max: 100,
    min: 0,
    input: "Range",
    defaultValue: function () {
      return 0;
    },
    group: "Wundgrund/Wundfläche",
  },
  woundGroundNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    input: "TextArea",
    group: "Wundgrund/Wundfläche",
  },
  colonization: {
    type: Boolean,
    label: "Kolonisation",
    optional: true,
    group: "Mikrobieller Wundstatus",
    input: "Toggle",
  },
  critiqueColonization: {
    type: Boolean,
    label: "Kritische Kolonisation",
    optional: true,
    group: "Mikrobieller Wundstatus",
    input: "Toggle",
  },
  infection: {
    type: Boolean,
    label: "Infektion",
    optional: true,
    group: "Mikrobieller Wundstatus",
    input: "Toggle",
  },
  localLimited: {
    type: Boolean,
    label: "Lokal begrenzt",
    optional: true,
    group: "Mikrobieller Wundstatus",
    input: "Toggle",
    visible: "infection",
  },
  invasive: {
    type: Boolean,
    label: "Invasiv",
    optional: true,
    group: "Mikrobieller Wundstatus",
    input: "Toggle",
    visible: "infection",
  },
  inaccurate: {
    type: Boolean,
    label: "Nicht einschätzbar",
    optional: true,
    group: "Mikrobieller Wundstatus",
    input: "Toggle",
  },
  woundGraduationData: {
    type: Object,
    label: "AVLON Skala nach Gerhard Kammerlander",
    optional: true,
    blackbox: true,
    options: [
      {
        label: "Grad Ia:",
        sublabel:
          "Rötung/Endzündung an der Epidermis sichtbar aber ohne sichtbaren Gewebedefekt",
        name: "gradIa",
        options: [
          { label: "Arterielle Perfusionsstörung", name: "gradIaA" },
          { label: "Venöse Zirkulationsstörung", name: "gradIaV" },
          { label: "Lymphangiöse Abflussstörung", name: "gradIaL" },
          { label: "Osteo Arthopathie", name: "gradIaO" },
          { label: "Neuropathie", name: "gradIaN" },
        ],
      },
      {
        label: "Grad Ib:",
        sublabel:
          "Gewebedefekt innerhalb der Epidermis bzw. bis an die Dermis reichend.",
        name: "gradIb",
        options: [
          { label: "Arterielle Perfusionsstörung", name: "gradIbA" },
          { label: "Venöse Zirkulationsstörung", name: "gradIbV" },
          { label: "Lymphangiöse Abflussstörung", name: "gradIbL" },
          { label: "Osteo Arthopathie", name: "gradIbO" },
          { label: "Neuropathie", name: "gradIbN" },
        ],
      },
      {
        label: "Grad II:",
        sublabel:
          "Gewebedefekt innerhalb der Dermis bzw. bis an die Subcutis reichend.",
        name: "gradII",
        options: [
          { label: "Arterielle Perfusionsstörung", name: "gradIIA" },
          { label: "Venöse Zirkulationsstörung", name: "gradIIV" },
          { label: "Lymphangiöse Abflussstörung", name: "gradIIL" },
          { label: "Osteo Arthopathie", name: "gradIIO" },
          { label: "Neuropathie", name: "gradIIN" },
        ],
      },
      {
        label: "Grad III:",
        sublabel:
          "Gewebedefekt innerhalb der Subcutis bzw. bis an die Faszie reichend.",
        name: "gradIII",
        options: [
          { label: "Arterielle Perfusionsstörung", name: "gradIIIA" },
          { label: "Venöse Zirkulationsstörung", name: "gradIIIV" },
          { label: "Lymphangiöse Abflussstörung", name: "gradIIIL" },
          { label: "Osteo Arthopathie", name: "gradIIIO" },
          { label: "Neuropathie", name: "gradIIIN" },
        ],
      },
      {
        label: "Grad IV:",
        sublabel:
          "Gewebedefekt innerhalb oder bis in den Fazien, Sehnen, Muskelbereich",
        name: "gradIV",
        options: [
          { label: "Arterielle Perfusionsstörung", name: "gradIVA" },
          { label: "Venöse Zirkulationsstörung", name: "gradIVV" },
          { label: "Lymphangiöse Abflussstörung", name: "gradIVL" },
          { label: "Osteo Arthopathie", name: "gradIVO" },
          { label: "Neuropathie", name: "gradIVN" },
        ],
      },
      {
        label: "Grad V:",
        sublabel:
          "Gewebedefekt innerhalb oder bis in den/die Knochen, Gelenksbereich, Körperhöhle.",
        name: "gradV",
        options: [
          { label: "Arterielle Perfusionsstörung", name: "gradVA" },
          { label: "Venöse Zirkulationsstörung", name: "gradVV" },
          { label: "Lymphangiöse Abflussstörung", name: "gradVL" },
          { label: "Osteo Arthopathie", name: "gradVO" },
          { label: "Neuropathie", name: "gradVN" },
        ],
      },
    ],
    input: "WoundGraduation",
    group: "AVLON Skala nach Gerhard Kammerlander",
    inputOptions: {
      hidelabel: true,
    },
  },
  diabeticFoot: {
    type: Boolean,
    label: "Graduierung Diabetisches Fußsyndrom (DFS) nach Wagner/Amstrong",
    optional: true,
    input: "Toggle",
    group: "Graduierung Diabetisches Fußsyndrom (DFS) nach Wagner/Amstrong",
  },
  diabeticFootData: {
    type: Object,
    label: "Graduierung Diabetisches Fußsyndrom (DFS) nach Wagner/Amstrong",
    optional: true,
    blackbox: true,
    group: "Graduierung Diabetisches Fußsyndrom (DFS) nach Wagner/Amstrong",
    visible: "diabeticFoot",
    options: [
      {
        label: "Grad 0:",
        sublabel: "Prä- oder postulzerativer Fuß.",
        name: "diabeticFootgrad0",
        options: [
          { label: "Mit Infektion", name: "diabeticFootgrad0a" },
          { label: "Mit Ischämie", name: "diabeticFootgrad0b" },
          { label: "Mit Ischämie u. Infektion", name: "diabeticFootgrad0c" },
        ],
      },
      {
        label: "Grad I:",
        sublabel: "Defekt der Epidermis bis an die Dermis",
        name: "diabeticFootgradI",
        options: [
          { label: "Mit Infektion", name: "diabeticFootgradIa" },
          { label: "Mit Ischämie", name: "diabeticFootgradIb" },
          { label: "Mit Ischämie u. Infektion", name: "diabeticFootgradIc" },
        ],
      },
      {
        label: "Grad II:",
        sublabel: "Tiefes Ulkus bis zur Gelenkkapsel, zu Sehnen oder Knochen.",
        name: "diabeticFootgradII",
        options: [
          { label: "Mit Infektion", name: "diabeticFootgradIIa" },
          { label: "Mit Ischämie", name: "diabeticFootgradIIb" },
          { label: "Mit Ischämie u. Infektion", name: "diabeticFootgradIIc" },
        ],
      },
      {
        label: "Grad III:",
        sublabel:
          "Läsion mit Abszedierung, Osteomyelitis, Infektion der Gelenkkapsel.",
        name: "diabeticFootgradIII",
        options: [
          { label: "Mit Infektion", name: "diabeticFootgradIIIa" },
          { label: "Mit Ischämie", name: "diabeticFootgradIIIb" },
          { label: "Mit Ischämie u. Infektion", name: "diabeticFootgradIIIc" },
        ],
      },
      {
        label: "Grad IV:",
        sublabel: "Begrenzte Vorfuss- oder Fersennekrose.",
        name: "diabeticFootgradIV",
        options: [
          { label: "Mit Infektion", name: "diabeticFootgradIVa" },
          { label: "Mit Ischämie", name: "diabeticFootgradIVb" },
          { label: "Mit Ischämie u. Infektion", name: "diabeticFootgradIVc" },
        ],
      },
      {
        label: "Grad V:",
        sublabel: "Nekrose des gesamten Fusses.",
        name: "diabeticFootgradV",
        options: [
          { label: "Mit Infektion", name: "diabeticFootgradVa" },
          { label: "Mit Ischämie", name: "diabeticFootgradVb" },
          { label: "Mit Ischämie u. Infektion", name: "diabeticFootgradVc" },
        ],
      },
    ],
    input: "DiabeticFoot",
  },
  dekubitus: {
    type: Boolean,
    label: "Dekubitus Graduierung nach EPUAP",
    optional: true,
    input: "Toggle",
    group: "Dekubitus Graduierung nach EPUAP",
  },
  dekubitusData: {
    type: Object,
    label: "Dekubitus Graduierung nach EPUAP",
    optional: true,
    blackbox: true,
    group: "Dekubitus Graduierung nach EPUAP",
    visible: "dekubitus",
    options: [
      {
        label: "Kategorie I:",
        sublabel: "Persistierende, nicht wegdrückbare Rötung der Haut.",
        desc: "Nicht wegdrückbare, umschriebene Rötung bei intakter Haut, gewöhnlich über einem knöchernen Vorsprung. Bei dunkel pigmentierter Haut ist ein Abblassen möglicherweise nicht sichtbar, die Farbe kann sich aber von der umgebenden Haut unterscheiden. Der Bereich kann schmerzempfindlich, verhärtet, weich, wärmer oder kälter sein als das umgebende Gewebe. Diese Symptome können auf eine (Dekubitus-) Gefährdung hinweisen.",
        name: "dekubitusgrad1",
      },
      {
        label: "Kategorie II:",
        sublabel: "Teilverlust der Haut.",
        desc: "Teilzerstörung der Haut (bis in die Dermis/ Lederhaut), die als flaches, offenes Ulcus mit einem rot bis rosafarbenen Wundbett ohne Beläge in Erscheinung tritt. Kann sich auch als intakte oder offene/rupturierte, serumgefüllte Blase darstellen. Manifestiert sich als glänzendes oder trockenes, flaches Ulcus ohne nekrotisches Gewebe oder Bluterguss*. Diese Kategorie sollte nicht benutzt werden um Skin Tears (Gewebezerreißungen), verbands- oder pflasterbedingteHautschädigungen, feuchtigkeitsbedingte Läsionen, Mazerationen oder Abschürfungen zu beschreiben.",
        name: "dekubitusgrad2",
      },
      {
        label: "Kategorie III:",
        sublabel:
          "Ulzeration aller Hautschichten mit Einbeziehung der Subcutis bis zur Faszie.",
        desc: "Zerstörung aller Hautschichten. Subkutanes Fett kann sichtbar sein, jedoch keine Knochen, Muskeln oder Sehnen. Es kann ein Belag vorliegen, der jedoch nicht die Tiefe der Gewebsschädigung verschleiert. Es können Tunnel oder Unterminierungen vorliegen. Die Tiefe des Dekubitus der Kategorie/Stufe/Grad III variiert je nach anatomischer Lokalisation. Der Nasenrücken, das Ohr, der Hinterkopf und das Gehörknöchelchen haben kein subkutanes Gewebe, daher können Kategorie III Wunden dort auch sehr oberflächlich sein. Im Gegensatz dazu können an besonders adipösen Körperstellen extrem tiefe Kategorie III Wunden auftreten. Knochen und Sehnen sind nicht sichtbar oder tastbar.",
        name: "dekubitusgrad3",
      },
      {
        label: "Kategorie IV:",
        sublabel:
          "Offene Wunde mit Beteiligung des Muskel- und Knochengewebes. Muskel oder Knochengewebe liegt frei.",
        desc: "Totaler Gewebsverlust mit freiliegenden Knochen, Sehnen oder Muskeln. Belag und Schorf können vorliegen. Tunnel oder Unterminierungen liegen oft vor. Die Tiefe des Kategorie IV Dekubitus hängt von der anatomischen Lokalisation ab. Der Nasenrücken, das Ohr, der Hinterkopf und der Knochenvorsprung am Fußknöchel haben kein subkutanes Gewebe, daher können Wunden dort auch sehr oberflächlich sein. Kategorie IV Wunden können sich in Muskeln oder unterstützende Strukturen ausbreiten (Fascien, Sehnen oder Gelenkkapseln) und können dabei leicht Osteomyelitis oder Ostitis verursachen. Knochen und Sehnen sind sichtbar oder tastbar.",
        name: "dekubitusgrad4",
      },
      {
        label: "Kategorie V:",
        sublabel: "Nicht klassifizierbar.",
        desc: "Vollständiger Haut- oder Gewebeverlust, bei der die tatsächliche Tiefe der Wunde von Kruste/Schorf im Wundbett verdeckt ist.",
        name: "dekubitusgrad5",
      },
      {
        label: "Kategorie VI:",
        sublabel: "Vermutete tiefe Gewebeschädigung.",
        desc: "Violetter oder rötlichbrauner, umschriebener Bereich verfärbter, intakter Haut oder blutge- füllte Blase aufgrund einer Schädigung des darunterliegenden Weichgewebes durch Druck und/oder Scherkräfte.",
        name: "dekubitusgrad6",
      },
    ],
    input: "Dekubitus",
  },
  normalSurrounding: {
    type: Boolean,
    label: "Normal",
    optional: true,
    group: "Wundumgebung",
    input: "Toggle",
  },
  macerationSurroundingGrad: {
    type: String,
    label: "Mazeration",
    optional: true,
    options: [
      { label: "Keine Mazeration", value: "none" },
      { label: "Leichte Mazeration", value: "ease" },
      { label: "Mäßige Mazeration", value: "moderate" },
      { label: "Starke Mazeration", value: "strong" },
    ],
    input: "Select",
    group: "Wundumgebung",
  },
  rednessSurroundingGrad: {
    type: String,
    label: "Rötung Inflammation",
    optional: true,
    options: [
      { label: "Keine Rötung", value: "none" },
      { label: "Leichte Rötung", value: "ease" },
      { label: "Mäßige Rötung", value: "moderate" },
      { label: "Starke Rötung", value: "strong" },
    ],
    input: "Select",
    group: "Wundumgebung",
  },
  xeroticSurroundingGrad: {
    type: String,
    label: "Hautzustand Trocken",
    optional: true,
    options: [
      { label: "Nicht Trocken", value: "none" },
      { label: "Leicht (Trocken)", value: "ease" },
      { label: "Mäßig (Schuppig)", value: "moderate" },
      { label: "Stark (Keratose)", value: "strong" },
    ],
    input: "Select",
    group: "Wundumgebung",
  },
  lividSurroundingGrad: {
    type: String,
    label: "Livide",
    optional: true,
    options: [
      { label: "Nicht Livide", value: "none" },
      { label: "Leicht Livide", value: "ease" },
      { label: "Mäßig Livide", value: "moderate" },
      { label: "Stark Livide", value: "strong" },
    ],
    input: "Select",
    group: "Wundumgebung",
  },
  atrophSurroundingGrad: {
    type: String,
    label: "Atroph",
    optional: true,
    options: [
      { label: "Nicht Atroph", value: "none" },
      { label: "Leicht Atroph", value: "ease" },
      { label: "Mäßig Atroph", value: "moderate" },
      { label: "Stark Atroph", value: "strong" },
    ],
    input: "Select",
    group: "Wundumgebung",
  },
  infectionSurroundingGrad: {
    type: String,
    label: "Infektion",
    optional: true,
    options: [
      { label: "Keine Infektion", value: "none" },
      { label: "Leichte Infektion", value: "ease" },
      { label: "Mäßige Infektion", value: "moderate" },
      { label: "Starke Infektion", value: "strong" },
    ],
    input: "Select",
    group: "Wundumgebung",
  },
  mycosisSurroundingGrad: {
    type: String,
    label: "Mykose",
    optional: true,
    options: [
      { label: "Keine Mykose", value: "none" },
      { label: "Leichte Mykose", value: "ease" },
      { label: "Mäßige Mykose", value: "moderate" },
      { label: "Starke Mykose", value: "strong" },
    ],
    input: "Select",
    group: "Wundumgebung",
  },
  edematousSurroundingGrad: {
    type: String,
    label: "Ödematös",
    optional: true,
    options: [
      { label: "Nicht Ödematös", value: "none" },
      { label: "Leicht Ödematös", value: "ease" },
      { label: "Mäßig Ödematös", value: "moderate" },
      { label: "Stark Ödematös", value: "strong" },
    ],
    input: "Select",
    group: "Wundumgebung",
  },
  pruritusSurroundingGrad: {
    type: String,
    label: "Juckreiz",
    optional: true,
    options: [
      { label: "Kein Juckreiz", value: "none" },
      { label: "Leichter Juckreiz", value: "ease" },
      { label: "Mäßiger Juckreiz", value: "moderate" },
      { label: "Starker Juckreiz", value: "strong" },
    ],
    input: "Select",
    group: "Wundumgebung",
  },
  otherSurrounding: {
    type: Boolean,
    label: "Andere",
    optional: true,
    input: "Toggle",
    group: "Wundumgebung",
  },
  otherSurroundingName: {
    type: String,
    label: "Art",
    optional: true,
    visible: "otherSurrounding",
    group: "Wundumgebung",
  },
  otherSurroundingGrad: {
    type: String,
    label: "Grad",
    optional: true,
    options: [
      { label: "Keine", value: "none" },
      { label: "Leicht", value: "ease" },
      { label: "Mäßig", value: "moderate" },
      { label: "Stark", value: "strong" },
    ],
    input: "Select",
    visible: "otherSurrounding",
    group: "Wundumgebung",
  },
  normalDermicState: {
    type: Boolean,
    label: "Normal",
    optional: true,
    input: "Toggle",
    group: "Hautzustand allgemein",
  },
  macerationDermicStateGrad: {
    type: String,
    label: "Mazeration",
    optional: true,
    options: [
      { label: "Keine Mazeration", value: "none" },
      { label: "Leichte Mazeration", value: "ease" },
      { label: "Mäßige Mazeration", value: "moderate" },
      { label: "Starke Mazeration", value: "strong" },
    ],
    input: "Select",
    group: "Hautzustand allgemein",
  },
  rednessDermicStateGrad: {
    type: String,
    label: "Rötung Inflammation",
    optional: true,
    options: [
      { label: "Keine Rötung", value: "none" },
      { label: "Leichte Rötung", value: "ease" },
      { label: "Mäßige Rötung", value: "moderate" },
      { label: "Starke Rötung", value: "strong" },
    ],
    input: "Select",
    group: "Hautzustand allgemein",
  },
  xeroticDermicStateGrad: {
    type: String,
    label: "Hautzustand Trocken",
    optional: true,
    options: [
      { label: "Nicht Trocken", value: "none" },
      { label: "Leicht (Trocken)", value: "ease" },
      { label: "Mäßig (Schuppig)", value: "moderate" },
      { label: "Stark (Keratose)", value: "strong" },
    ],
    input: "Select",
    group: "Hautzustand allgemein",
  },
  pruritusDermicStateGrad: {
    type: String,
    label: "Juckreiz",
    optional: true,
    options: [
      { label: "Kein Juckreiz", value: "none" },
      { label: "Leichter Juckreiz", value: "ease" },
      { label: "Mäßiger Juckreiz", value: "moderate" },
      { label: "Starker Juckreiz", value: "strong" },
    ],
    input: "Select",
    group: "Hautzustand allgemein",
  },
  woundSmellPre: {
    type: Boolean,
    label: "Zeitpunkt: Vor VW",
    optional: true,
    input: "Toggle",
    group: "Wundgeruch",
  },
  woundSmellPreIntensity: {
    type: String,
    label: "Intensität",
    optional: true,
    options: [
      { label: "Kein Geruch", value: "none" },
      { label: "Leichter Geruch", value: "ease" },
      { label: "Mäßiger Geruch", value: "moderate" },
      { label: "Starker Geruch", value: "strong" },
      { label: "Sehr starker Geruch", value: "intensive" },
    ],
    input: "Select",
    group: "Wundgeruch",
    visible: "woundSmellPre",
  },
  woundSmellPreType: {
    type: String,
    label: "Art",
    optional: true,
    group: "Wundgeruch",
    visible: "woundSmellPre",
  },
  woundSmellDuring: {
    type: Boolean,
    label: "Zeitpunkt: Bei VW",
    optional: true,
    input: "Toggle",
    group: "Wundgeruch",
  },
  woundSmellDuringIntensity: {
    type: String,
    label: "Intensität",
    optional: true,
    options: [
      { label: "Kein Geruch", value: "none" },
      { label: "Leichter Geruch", value: "ease" },
      { label: "Mäßiger Geruch", value: "moderate" },
      { label: "Starker Geruch", value: "strong" },
      { label: "Sehr starker Geruch", value: "intensive" },
    ],
    input: "Select",
    visible: "woundSmellDuring",
    group: "Wundgeruch",
  },
  woundSmellDuringType: {
    type: String,
    label: "Art",
    optional: true,
    visible: "woundSmellDuring",
    group: "Wundgeruch",
  },
  woundSmellAfter: {
    type: Boolean,
    label: "Zeitpunkt: Nach VW",
    optional: true,
    input: "Toggle",
    group: "Wundgeruch",
  },
  woundSmellAfterIntensity: {
    type: String,
    label: "Intensität",
    optional: true,
    options: [
      { label: "Kein Geruch", value: "none" },
      { label: "Leichter Geruch", value: "ease" },
      { label: "Mäßiger Geruch", value: "moderate" },
      { label: "Starker Geruch", value: "strong" },
      { label: "Sehr starker Geruch", value: "intensive" },
    ],
    input: "Select",
    group: "Wundgeruch",
    visible: "woundSmellAfter",
  },
  woundSmellAfterType: {
    type: String,
    label: "Art",
    optional: true,
    group: "Wundgeruch",
    visible: "woundSmellAfter",
  },
  exudationIntensity: {
    type: String,
    label: "Intensität",
    optional: true,
    options: [
      { label: "Trocken", value: "xerotic" },
      { label: "Leicht", value: "ease" },
      { label: "Mäßig", value: "moderate" },
      { label: "Stark", value: "strong" },
      { label: "Sehr stark", value: "intensive" },
    ],
    input: "Select",
    group: "Exsudation",
  },
  exudationType: {
    type: String,
    label: "Art",
    optional: true,
    options: [
      { label: "Klar", value: "no" },
      { label: "Serös/trüb", value: "ease" },
      { label: "Blutig", value: "moderate" },
      { label: "Blutig/eitrig", value: "strong" },
      { label: "Eitrig/putride", value: "intensive" },
    ],
    input: "Select",
    group: "Exsudation",
  },
  exudationNote: {
    type: String,
    label: "Anmerkung",
    optional: true,
    input: "Textarea",
    group: "Exsudation",
  },
  normalEdging: {
    type: Boolean,
    label: "Normal",
    optional: true,
    group: "Wundrand",
    input: "Toggle",
  },
  macerationEdgingGrad: {
    type: String,
    label: "Mazeration",
    optional: true,
    options: [
      { label: "Keine Mazeration", value: "none" },
      { label: "Leichte Mazeration", value: "ease" },
      { label: "Mäßige Mazeration", value: "moderate" },
      { label: "Starke Mazeration", value: "strong" },
    ],
    input: "Select",
    group: "Wundrand",
  },
  rednessEdgingGrad: {
    type: String,
    label: "Rötung Inflammation",
    optional: true,
    options: [
      { label: "Keine Rötung", value: "none" },
      { label: "Leichte Rötung", value: "ease" },
      { label: "Mäßige Rötung", value: "moderate" },
      { label: "Starke Rötung", value: "strong" },
    ],
    input: "Select",
    group: "Wundrand",
  },
  xeroticEdgingGrad: {
    type: String,
    label: "Hautzustand Trocken",
    optional: true,
    options: [
      { label: "Nicht Trocken", value: "none" },
      { label: "Leicht (Trocken)", value: "ease" },
      { label: "Mäßig (Schuppig)", value: "moderate" },
      { label: "Stark (Keratose)", value: "strong" },
    ],
    input: "Select",
    group: "Wundrand",
  },
  lividEdgingGrad: {
    type: String,
    label: "Livide",
    optional: true,
    options: [
      { label: "Nicht Livide", value: "none" },
      { label: "Leicht Livide", value: "ease" },
      { label: "Mäßig Livide", value: "moderate" },
      { label: "Stark Livide", value: "strong" },
    ],
    input: "Select",
    group: "Wundrand",
  },
  atrophEdgingGrad: {
    type: String,
    label: "Atroph",
    optional: true,
    options: [
      { label: "Nicht Atroph", value: "none" },
      { label: "Leicht Atroph", value: "ease" },
      { label: "Mäßig Atroph", value: "moderate" },
      { label: "Stark Atroph", value: "strong" },
    ],
    input: "Select",
    group: "Wundrand",
  },
  edematousEdgingGrad: {
    type: String,
    label: "Ödematös",
    optional: true,
    options: [
      { label: "Nicht Ödematös", value: "none" },
      { label: "Leicht Ödematös", value: "ease" },
      { label: "Mäßig Ödematös", value: "moderate" },
      { label: "Stark Ödematös", value: "strong" },
    ],
    input: "Select",
    group: "Wundrand",
  },
  otherEdging: {
    type: Boolean,
    label: "Andere",
    optional: true,
    input: "Toggle",
    group: "Wundrand",
  },
  otherEdgingName: {
    type: String,
    label: "Art",
    max: 100,
    optional: true,
    group: "Wundrand",
    visible: "otherEdging",
  },
  otherEdgingGrad: {
    type: String,
    label: "Grad",
    optional: true,
    options: [
      { label: "Keine", value: "none" },
      { label: "Leicht", value: "ease" },
      { label: "Mäßig", value: "moderate" },
      { label: "Stark", value: "strong" },
    ],
    input: "Select",
    group: "Wundrand",
    visible: "otherEdging",
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  patientId: {
    type: String,
    label: "PatientId",
    visible: false,
    defaultValue: (props) => {
      return props.patientId;
    },
  },
  therapyId: {
    type: String,
    label: "TherapyId",
    visible: false,
    defaultValue: (props) => {
      return props.therapyId;
    },
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  canceled: {
    type: Boolean,
    label: "Canceled",
    optional: true,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Anamnesen = new Mongo.Collection("anamnesen");
  Anamnesen.schema = simpleSchema;
  Anamnesen.attachSchema(simpleSchema);
  Anamnesen._ensureIndex({ therapyId: 1 });
  Anamnesen._ensureIndex({ patientId: 1 });
  Anamnesen._ensureIndex({ companyId: 1 });
}

export { simpleSchema as AnamneseSchema };

export default schema;
