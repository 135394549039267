import SimpleSchema from "./simple";

const schema = {
  patient: {
    type: String,
    label: "Patient/Grund",
    input: "Autocomplete",
    group: "Allgemein",
    optional: true,
  },
  patientId: {
    type: String,
    label: "PatientId",
    optional: true,
    listed: false,
  },
  groupId: {
    type: String,
    label: "Mitarbeiter",
    optional: true,
    listed: true,
    input: "UserSelect",
    group: "Allgemein",
    placeholder: "Bitte wählen",
  },
  datum: {
    type: Date,
    label: "Datum",
    defaultValue: function () {
      return new Date();
    },
    input: "DateTimePicker",
    group: "Allgemein",
  },
  time: {
    type: Number,
    label: "Dauer in Minuten",
    defaultValue: function () {
      return 30;
    },
    min: 0,
    max: 1500,
    ticks: 15,
    input: "Number",
    group: "Allgemein",
  },
  daily: {
    type: Boolean,
    label: "Ganztägig",
    defaultValue: function () {
      return false;
    },
    optional: true,
    input: "Toggle",
    group: "Allgemein",
  },
  reason: {
    type: String,
    label: "Grund",
    optional: true,
    group: "Allgemein",
  },
  done: {
    type: Boolean,
    label: "Erledigt",
    defaultValue: function () {
      return false;
    },
    optional: true,
    input: "Toggle",
    group: "Allgemein",
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    listed: false,
    optional: true,
  },
  createdBy: {
    type: String,
    autoValue: function () {
      if (this.isInsert && this.userId) {
        return this.userId;
      } else if (this.isUpsert && this.userId) {
        return this.userId;
      }
    },
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Appointments = new Mongo.Collection("appointments");
  Appointments.schema = simpleSchema;
  Appointments.attachSchema(simpleSchema);
  Appointments._ensureIndex({ companyId: 1 });
}

export { simpleSchema as AppointmentSchema };

export default schema;
