import React, { useState } from "react";
import { TouchableOpacity, Platform, ImageBackground } from "react-native";
import {
  useTheme,
  Flex,
  Avatar,
  Button,
  styled,
  Text,
  isAndroid,
} from "unikit";

import Icon from "./Icon";

const List = styled(Flex)({
  width: "100%",
  px: 10,
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: 1,
});

const Card = styled(Flex)({
  width: 300,
  height: "auto",
  backgroundColor: "surface",
  borderRadius: 10,
});

const Pills = styled(Flex)({
  position: "absolute",
  right: -7.5,
  top: -7.5,
  height: "auto",
});

const CardContent = styled.View({
  position: "relative",
  width: "100%",
  padding: 25,
});

const Bg = styled(ImageBackground)({
  position: "absolute",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  overflow: "hidden",
});

const ListItem = React.memo(
  ({
    title,
    desc,
    subdesc,
    users,
    onPress,
    rightAction,
    height = 55,
    backgroundColor,
    card,
    style,
    wrapperStyle,
    leftAction,
    icon,
    iconColor,
    iconPill,
    textColor,
    as,
    pills,
    image,
    actions,
    titleSize,
    noMargin = false,
    disabled = false,
    renderLeft = null,
    ...rest
  }) => {
    const theme = useTheme();
    const [showActions, setShowActions] = useState(false);
    if ((!leftAction && icon) || image) {
      leftAction = (
        <Flex
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 120,
          }}
        >
          {image ? <Bg source={image} /> : null}
          {icon ? (
            <Flex
              bg={
                iconColor
                  ? `${iconColor}:setAlpha:0.05`
                  : "primary:setAlpha:0.05"
              }
              style={{
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 35,
                width: 70,
                height: 70,
              }}
              relative
            >
              <Icon name={icon} size={card ? 26 : 20} color={iconColor} />
              {iconPill ? (
                <Flex
                  t={0}
                  r={0}
                  px={10}
                  bg={iconColor || "primary"}
                  h={20}
                  borderRadius={15}
                  flexCenter
                  absolute
                >
                  <Text font="label">{iconPill}</Text>
                </Flex>
              ) : null}
            </Flex>
          ) : null}
        </Flex>
      );
    }
    var imagePreview;
    if (image) {
      imagePreview = (
        <ImageBackground
          style={{
            width: 70,
            height: 55,
            backgroundColor: "#CCC",
            marginRight: 12,
          }}
          source={image}
          resizeMode="contain"
        />
      );
    }
    if (card) {
      return (
        <Card
          as={as || TouchableOpacity}
          bg={backgroundColor || "surface"}
          onPress={onPress || null}
          activeOpacity={onPress ? 0.8 : 1}
          opacity={disabled ? 0.5 : 1}
          style={style}
          shadow={Platform.OS === "android" ? 1 : 3}
          shadowColor="rgba(0,0,0,0.04)"
          mx={noMargin ? 0 : 7.5}
          shadow={2}
        >
          {leftAction}
          <Pills>
            {pills &&
              pills.map((pill, index) => (
                <Button
                  key={`pill-${index}`}
                  bg={pill.color}
                  ml={8}
                  my={1}
                  size={22}
                  labelProps={{
                    style: { fontSize: 10 },
                  }}
                  rounded
                >
                  {pill.label}
                </Button>
              ))}
          </Pills>
          <CardContent
            borderTopWidth={leftAction ? 1 : 0}
            borderTopColor="background"
          >
            <Flex row content="space-between" p={15}>
              <Flex style={{ flex: 1, maxWidth: 260 }}>
                <Text color="text" numberOfLines={1}>
                  {title}
                </Text>

                {desc && (
                  <Text
                    numberOfLines={1}
                    font="label"
                    color="text"
                    opacity={0.7}
                    mt={2}
                  >
                    {desc}
                  </Text>
                )}
                {subdesc && (
                  <Text
                    numberOfLines={1}
                    font="label"
                    color="text"
                    opacity={0.7}
                    mt={2}
                  >
                    {subdesc}
                  </Text>
                )}
              </Flex>

              {actions && actions.length > 1 ? (
                <>
                  <Button
                    ml={25}
                    onPress={() =>
                      theme.alert({
                        type: "surface",
                        position: "bottom",
                        actionSheet: true,
                        backdrop: true,
                        actions,
                      })
                    }
                    size={30}
                    light
                    rounded
                  >
                    <Icon name="moreVertical" size={20} focused />
                  </Button>
                </>
              ) : null}
              {actions && actions.length === 1 ? (
                <>
                  <Button
                    ml={25}
                    onPress={() => actions[0].onPress()}
                    size={44}
                    light
                    rounded
                  >
                    <Icon
                      name={actions[0].icon || "moreVertical"}
                      size={20}
                      focused
                    />
                  </Button>
                </>
              ) : null}
            </Flex>
          </CardContent>
        </Card>
      );
    }
    return (
      <List
        bg={backgroundColor || "surface"}
        as={as || TouchableOpacity}
        h={height + (subdesc ? 25 : 0)}
        alignItems="center"
        borderBottomWidth={1}
        borderBottomColor="background"
        style={style}
        onPress={onPress || null}
        activeOpacity={onPress ? 0.8 : 1}
        {...rest}
      >
        <Flex w="100%" row alignItems="center" justifyContent="space-between">
          <Flex style={{ flex: 1 }} row>
            {imagePreview ? imagePreview : null}
            {users ? (
              <Flex row mr={10}>
                {users.map((user, index) =>
                  user ? (
                    <Avatar
                      key={`avatar-${index}`}
                      size={37}
                      fontSize={14}
                      borderWidth={2}
                      borderColor="surface"
                      marginLeft={index > 0 ? -10 : 0}
                      bg={user.color}
                      source={user.image ? { uri: user.image.url } : undefined}
                      char={`${user.firstName ? user.firstName.charAt(0) : ""}${
                        user.lastName ? user.lastName.charAt(0) : ""
                      }`}
                    />
                  ) : null
                )}
              </Flex>
            ) : null}

            {renderLeft}

            <Flex style={{ width: "80%" }} justifyContent="center">
              <Flex w="100%" justifyContent="flex-start" row>
                <Text color="text" numberOfLines={1}>
                  {title}
                </Text>
                {pills &&
                  pills.map((pill, index) => (
                    <Button
                      key={`pill-${index}`}
                      bg={pill.color}
                      light
                      style={{ borderRadius: 30, marginLeft: 8 }}
                      size={18}
                      labelProps={{
                        style: { fontSize: 10 },
                      }}
                    >
                      {pill.label}
                    </Button>
                  ))}
              </Flex>
              {desc && (
                <Text
                  numberOfLines={1}
                  font="label"
                  mt={isAndroid ? 0 : 2}
                  opacity={0.7}
                  color="text"
                >
                  {desc}
                </Text>
              )}
              {subdesc && (
                <Text
                  numberOfLines={1}
                  font="label"
                  color="text"
                  opacity={0.7}
                  mt={2}
                >
                  {subdesc}
                </Text>
              )}
            </Flex>
          </Flex>

          {actions ? (
            <Button
              onPress={() =>
                theme.alert({
                  type: "surface",
                  bg: "surface:setAlpha:0.8",
                  color: "primary",
                  position: "bottom",
                  actionSheet: true,
                  backdrop: true,
                  actions,
                })
              }
              size={30}
              rounded
              light
            >
              <Icon name="moreVertical" size={20} focused />
            </Button>
          ) : null}

          {rightAction ? rightAction : null}
        </Flex>
      </List>
    );
  }
);

ListItem.defaultProps = {
  iconColor: "grey",
};

export default ListItem;
