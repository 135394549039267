import React, { useState, useEffect } from "react";
import { Alert, Platform, StyleSheet, Text } from "react-native";
import {
  Flex,
  showAlert,
  isAndroid,
  Overlay,
  Button,
  Input,
  Icon,
} from "unikit";
import * as Permissions from "expo-permissions";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import Prompt from "react-native-prompt-crossplatform";
import { BarCodeScanner } from "expo-barcode-scanner";

import { useAppContext } from "../../AppContext";

const SEARCH_MUTATION = gql`
  mutation getMaterial($ean: String!) {
    getMaterial(ean: $ean)
  }
`;

const BarCode = ({ value, onChange, doc, navigation }) => {
  const { changeDoc } = useAppContext();
  const [prompt, showPrompt] = useState(false);
  const [promptValue, setPromptValue] = useState(1);
  const [item, setItem] = useState(undefined);
  const [scanned, setScanned] = useState(false);
  const [hasCameraPermission, setCameraPermission] = useState(false);
  const [getMaterial] = useMutation(SEARCH_MUTATION);
  value = doc.materials || [];

  async function getPermissionsAsync() {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    setCameraPermission(status === "granted");
  }

  useEffect(() => {
    getPermissionsAsync();
  }, []);

  if (hasCameraPermission !== true && Platform.OS !== "web") {
    return <Text>Waiting for Camera Permission</Text>;
  }

  const setValueWithCount = (count) => {
    if (count !== null) {
      const newValue = value || [];
      const found = newValue.find((v) => v._id === item._id);
      if (found) {
        found.count = count;
      } else {
        newValue.push(
          Object.assign({}, item, {
            count: parseInt(count),
            id: item._id,
          })
        );
      }

      onChange(newValue, "materials");
      setPromptValue(1);
      setScanned(false);
    }
  };

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    //alert(`Bar code with type ${type} and data ${data} has been scanned!`);
    getMaterial({
      variables: {
        ean: data,
      },
    })
      .then((result) => {
        const item = result.data?.getMaterial;
        if (item) {
          setItem(item);
          showPrompt(true);
        } else {
          showAlert({
            message: `Material mit Barcode ${data} nicht gefunden, wollen Sie ein Material verknüpfen?`,
            confirm: true,
            onClose: () => {
              setScanned(false);
            },
            onConfirm: ({ close }) => {
              close();
              navigation.push("Search", {
                type: "materialSingle",
                filter: {},
                onSelect: (item, goBack) => {
                  const EANNumber = item.EANNumber
                    ? [...item.EANNumber, data]
                    : [data];
                  changeDoc({
                    id: item._id,
                    doc: { EANNumber },
                    type: "Material",
                    mode: "update",
                  });
                  setItem(item);
                  goBack();
                  setScanned(false);
                },
              });
            },
          });
        }
      })
      .catch((error) => {
        console.log({ error });
        setScanned(false);
      });
  };
  return (
    <>
      <Flex
        relative
        w="100%"
        borderRadius={15}
        overflow="hidden"
        h={isAndroid ? 300 : 150}
      >
        <BarCodeScanner
          onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
          style={StyleSheet.absoluteFillObject}
        />
        <Flex absolute l="10%" w="80%" h={1} bg="error" b="50%" />
      </Flex>
      <Overlay visible={prompt} onClose={() => showPrompt(false)}>
        <Text textAlign="center" mb={15}>
          Wie viele möchten Sie hinzufügen?
        </Text>
        <Flex row>
          <Button
            mr={5}
            size={60}
            opacity={promptValue < 2 ? 0.5 : 1}
            onPress={() => {
              let v = promptValue || 0;
              if (v > 1) {
                setPromptValue(v - 1);
              }
            }}
          >
            <Icon name="minus" color="#FFF" size={24} />
          </Button>
          <Input
            type="text"
            onChange={(v) =>
              v.length > 0 ? setPromptValue(parseInt(v)) : setPromptValue("")
            }
            value={promptValue}
            keyboardType="number-pad"
            flex={1}
            w="auto"
            textAlign="center"
            font="h4"
          />
          <Button
            ml={5}
            size={60}
            onPress={() => {
              let v = promptValue || 0;
              setPromptValue(v + 1);
            }}
          >
            <Icon name="plus" color="#FFF" size={24} />
          </Button>
        </Flex>
        <Button
          mt={10}
          onPress={() => {
            setValueWithCount(promptValue);
            showPrompt(false);
          }}
        >
          Hinzufügen
        </Button>
      </Overlay>
    </>
  );
};

export default BarCode;
