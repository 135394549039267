import React from "react";
import { Text, styled, Image } from "unikit";

const Content = styled.View({
  width: "100%",
  height: 200,
  alignItems: "center",
  justifyContent: "center",
});

export default ({ title = "", image, style }) => {
  return (
    <Content style={style}>
      {image ? <Image source={image} width={200} /> : null}
      <Text color="text" level={5}>
        {title}
      </Text>
    </Content>
  );
};
