import SimpleSchema from "./simple";

const schema = {
  documents: {
    type: Array,
    label: "documents",
    blackbox: true,
  },
  "documents.$": {
    type: Object,
    blackbox: true,
  },
  shareUser: {
    type: String,
    label: "Share UserId",
  },
  shareUserEmail: {
    type: String,
    label: "Share UserEmail",
  },
  shareCompanyId: {
    type: String,
    label: "Share CompanyId",
  },
  patientId: {
    type: String,
    label: "patientId",
  },
  patientName: {
    type: String,
    label: "patientName",
    optional: true,
  },
  shareEnd: {
    type: Date,
    label: "Auto delete",
    optional: true,
    listed: false,
  },
  note: {
    type: String,
    label: "Bemerkung",
    max: 1000,
    optional: true,
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Shares = new Mongo.Collection("shares");
  Shares.schema = simpleSchema;
  Shares.attachSchema(simpleSchema);
  Shares._ensureIndex({ companyId: 1 });
}

export { simpleSchema as ShareSchema };

export default schema;
