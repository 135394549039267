import * as React from "react";
import { TouchableOpacity, Text } from "react-native";
import { styled } from "unikit";

import Icon from "./Icon";

const Share = styled.View({
  position: "absolute",
  bottom: 15,
  right: "50%",
  marginRight: -35,
  width: 70,
  height: 50,
  borderRadius: 25,
  backgroundColor: "primary",
  alignItems: "center",
  justifyContent: "center",
});

const ShareIndicator = styled.View({
  position: "absolute",
  top: -3,
  right: -3,
  width: 24,
  height: 24,
  borderRadius: 12,
  backgroundColor: "success",
  alignItems: "center",
  justifyContent: "center",
});

export default ({ sharing, navigate }) => {
  return (
    <Share
      as={TouchableOpacity}
      onPress={() => {
        navigate("Sharing", {
          title: `Dokumente von ${sharing.patientName} teilen`,
        });
      }}
      shadow={5}
    >
      <ShareIndicator shadow={5}>
        <Text style={{ color: "#FFF", fontSize: 12 }}>
          {sharing.documents.length}
        </Text>
      </ShareIndicator>
      <Icon name="share" size={24} color="#FFF" />
    </Share>
  );
};
