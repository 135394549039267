// @flow
import * as React from "react";
import { ScrollView } from "react-native";
import { Group, Button, Flex } from "unikit";
import Pdf from "@mikecousins/react-pdf";

import PdfPreview from "./PdfPreview";

export default function PDF({ source }) {
  return (
    <Flex flex={1}>
      <Group style={{ borderRadius: 0 }} gap={0}>
        <Button
          onPress={() => {
            window.open(source.uri, "_blank");
          }}
          style={{ borderRadius: 0 }}
        >
          Download
        </Button>
      </Group>
      <ScrollView
        style={{ flex: 1, padding: 30 }}
        contentContainerStyle={{
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Flex w="100%" maxWidth={900}>
          <PdfPreview source={source} />
        </Flex>
      </ScrollView>
    </Flex>
  );
}

// class PdfReader extends Component {
//   state = { page: 1, pages: null };

//   onDocumentComplete = (pages) => {
//     this.setState({ page: 1, pages });
//   };

//   renderPages(numPages) {
//     const { source } = this.props;
//     var pages = [];
//     for (var i = 0; i < numPages; i++) {
//       if (i + 1 > 1) {
//         pages.push(
//           <div
//             style={{
//               width: "100%",
//               marginTop: 25,
//               display: "inline-block",
//               maxWidth: 912,
//               display: "flex",
//               justifyContent: "center",
//             }}
//           >
//             <PDF file={source.uri} page={i + 1} />
//           </div>
//         );
//       }
//     }
//     return pages;
//   }

//   render() {
//     const { source } = this.props;

//     return (
//       <View style={{ flex: 1 }}>
//         <Group style={{ borderRadius: 0 }} gap={0}>
//           <Button
//             onPress={() => {
//               window.open(source.uri, "_blank");
//             }}
//             style={{ borderRadius: 0 }}
//           >
//             Download
//           </Button>
//           {/* <Button
//             onPress={() => {
//               Print.printAsync({ uri: source.uri });
//             }}
//             style={{ borderRadius: 0 }}
//           >
//             Print
//           </Button> */}
//         </Group>
//         <ScrollView
//           style={{ flex: 1, padding: 30 }}
//           contentContainerStyle={{
//             alignContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <div
//             style={{
//               width: "100%",
//               display: "inline-block",
//               maxWidth: 912,
//               display: "flex",
//               justifyContent: "center",
//             }}
//           >
//             <canvas ref={canvasRef} />
//           </div>
//           {this.state.pages && this.renderPages(this.state.pages)}
//         </ScrollView>
//       </View>
//     );
//   }
// }

// export default PdfReader;
