import React from "react";
import { View, Text, Image, ScrollView, Dimensions } from "react-native";
import { Button, styled, useTheme, Flex } from "unikit";
import HTML from "react-native-render-html";

const Scroller = styled(ScrollView)({
  borderWidth: 3,
  borderColor: "input",
  borderStyle: "dashed",
  width: "100%",
  height: Dimensions.get("window").height / 2.5,
  mb: 10,
  p: 15,
});

export default ({ value, inputKey, user, navigation, onChange }) => {
  const theme = useTheme();

  const textRenderer = (
    htmlAttribs,
    children,
    convertedCSSStyles,
    passProps
  ) => {
    if (convertedCSSStyles) delete convertedCSSStyles["fontFamily"];
    console.log({ convertedCSSStyles });
    return <Text style={{ convertedCSSStyles }}>{children}</Text>;
  };

  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        paddingBottom: 15,
        paddingTop: 5,
        paddingHorizontal: 15,
      }}
    >
      {inputKey === "informedConsentImage" ? (
        <Scroller contentContainerStyle={{ paddingBottom: 50 }}>
          <HTML
            html={user.informedConsentText}
            ignoredStyles={["font-family"]}
            tagsStyles={{
              p: { color: theme.colors.text },
              h1: { color: theme.colors.text },
              h2: { color: theme.colors.text },
              h3: { color: theme.colors.text },
              h4: { color: theme.colors.text },
              h5: { color: theme.colors.text },
              ul: { color: theme.colors.text },
              li: { color: theme.colors.text },
              span: { color: theme.colors.text },
            }}
          />
        </Scroller>
      ) : null}

      {value ? (
        <Flex
          w="100%"
          maxWidth={700}
          h={200}
          mt={10}
          borderWidth={3}
          borderColor="input"
          borderStyle="dashed"
          relative
        >
          <Image
            source={{ uri: value }}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              resizeMode: "contain",
            }}
            pointerEvents="none"
          />
        </Flex>
      ) : null}

      <Button
        mt={10}
        onPress={() =>
          navigation.push("Signature", {
            onSave: (sign) => {
              onChange(sign);
            },
          })
        }
      >
        Unterschrift hinzufügen
      </Button>
    </View>
  );
};
